import React from "react";
import "../css/Login.css";
import img1 from "../images/Greenfield.png";
import { useState } from "react";
import { TextField } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useContext } from "react";
import LoginContext from "../../../context/Login/LoginContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { LoginFunction } = useContext(LoginContext);
  const [showPassword, setShowPassword] = useState(false);
  const [inputfield, setinputfield] = useState({ username: "", password: "" });
  const inputfieldChange = (e) => {
    setinputfield({ ...inputfield, [e.target.name]: e.target.value });
  };
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const loginBtnclk = (e) => {
    e.preventDefault();

    if (inputfield.username <= 0) {
      setErrorMsg({
        status: true,
        msg: "Please enter username",
        position: "name",
      });
    } else if (inputfield.password <= 0) {
      setErrorMsg({
        status: true,
        msg: "Please enter password",
        position: "password",
      });
    } else {
      const FinalData = {
        user_name: inputfield.username,
        password: inputfield.password,
      };
      if (!localStorage.getItem("Authorization")) {
        LoginFunction(FinalData);
      }
    }
  };

  const Navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("Authorization")) {
      Navigate("/admin/DashBoard");
    } // eslint-disable-next-line
  }, []);
  return (
    <>
      <div id="container" className="overflow-hidden">
        {" "}
      </div>
      <div id="loginform" className="overflow-hidden">
        <form>
          <img src={img1} alt="#" loading="lazy" />
          <div id="inputfields">
            <h1 id="login">Login</h1>
            <div className="username h-full w-full relative">
              <TextField
                id="outlined-basic"
                label="Username"
                name="username"
                variant="outlined"
                className="w-full !outline-black"
                value={inputfield.username}
                onChange={inputfieldChange}
              />
              <PersonIcon className=" absolute top-4 right-3 cursor-pointer" />
            </div>
            {errorMsg.position === "name" ? (
              <p className="text-red-500 w-[96%] text-xs ml-3 -mt-4">
                **{errorMsg.msg}**
              </p>
            ) : (
              ""
            )}

            <div className="username h-full w-full relative">
              <TextField
                type={showPassword ? `text` : `password`}
                id="outlined-basic_Pass"
                name="password"
                value={inputfield.password}
                onChange={inputfieldChange}
                label="Password"
                variant="outlined"
                className="w-full !outline-black"
              />
              {showPassword ? (
                <VisibilityIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className=" absolute top-4 right-3 cursor-pointer"
                />
              ) : (
                <VisibilityOffIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className=" absolute top-4 right-3 cursor-pointer"
                />
              )}
            </div>
            {errorMsg.position === "password" ? (
              <p className="text-red-500 w-[96%] text-xs ml-3 -mt-4">
                **{errorMsg.msg}**
              </p>
            ) : (
              ""
            )}
            <div id="log" onClick={loginBtnclk}>
              <button className="h-full w-full flex justify-center items-center">
                Signin
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
