import React, { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const NoticeIdSlug = () => {
  const params = useParams();
  const { onenoticedata, onenoticePublicApi } = useContext(PublicApiContext);

  useEffect(() => {
    onenoticePublicApi(params.id);
    // eslint-disable-next-line
  }, []);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <div className="w-[95%] m-auto lg:overflow-hidden">
      {onenoticedata.map((e) => {
        return (
          <div
            map={e.id}
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            className="h-full w-[100%] flex flex-col items-center justify-around text-xl font-bold !overflow-y-hidden"
            key={e.id}
          >
            <div className="container min-h-[350px] min-w-[300px]  overflow-hidden h-full w-[100%] md:w-[90%] lg:w-[50%] flex flex-col items-center justify-around mt-4 mb-4">
              {e.image ? (
                <div className="imageBox w-[100%] object-cover">
                  <img
                    src={e.image}
                    className=" w-full h-full !lg:min-w-[350px] max-h-[350px] object-cover"
                    alt=""
                    loading="lazy"
                  />
                </div>
              ) : (
                <></>
              )}
              <div
                id="slug"
                className={`contentBox w-[100%] h-full mt-4 !text-center`}
              >
                <h3 className="text-center mb-2">{e.notice_name}</h3>
                <p className={`text-base mb-5 font-normal text-center `}>
                  <span
                    className="AboutGNCCONTENT"
                    dangerouslySetInnerHTML={{
                      __html: e.notice_post,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NoticeIdSlug;
