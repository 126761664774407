import React from "react";
import "../../CSS/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper";
import { useContext } from "react";
import { useEffect } from "react";
import mouse from "../../Images/scroll-down-mouse.gif";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const HeroSection = () => {
  const { getCarouselPublicApi, carouselData } = useContext(PublicApiContext);
  const data = carouselData;

  useEffect(() => {
    getCarouselPublicApi();
    // eslint-disable-next-line
  }, []);
  const scrollClk = () => {
    window.scrollTo(0, 800);
  };
  const FilterData = data.filter((e) => e.title.toLowerCase() !== "about");
  return (
    <div data-aos="zoom-out-up" className="h-[80vh] bg-black relative">
      <Swiper
        className="HomeSwiper"
        loop={true}
        autoplay={{
          delay: 2500,
        }}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {FilterData.length > 1
          ? FilterData.map((e) => {
              return (
                <SwiperSlide key={e.id}>
                  <img
                    src={e.banner_image}
                    className="d-block w-100 opacity-[.40] hover:scale-105 duration-700"
                    alt="..."
                    loading="lazy"
                  />
                  <div className="lg:carousel-caption lg:w-[600px] w-[300px] d-block absolute lg:bottom-[180px] bottom-[40%] text-white lg:left-[10%]">
                    <h5 className="text-[25px] lg:text-[30px] mb-2">
                      {e.title}
                    </h5>
                    <p className="text-[18px] lg:text-xl mb-2">"{e.slogan}"</p>
                    <p className="text-[20px] lg:text-[25px]">{e.name}</p>
                  </div>
                </SwiperSlide>
              );
            })
          : []}
      </Swiper>
      <div
        data-aos="fade-up"
        className="w-full flex bottom-[15%] absolute z-50 duration-500"
      >
        <img
          src={mouse}
          alt=""
          className="h-[50px] m-auto cursor-pointer hover:scale-105 z-50"
          onClick={scrollClk}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default HeroSection;
