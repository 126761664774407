import React, { useEffect } from "react";
import { useRef } from "react";
import { ImCross } from "react-icons/im";
import { Link } from "react-router-dom";

const Ads = (props) => {
  const { data, setInt } = props;
  const brnref = useRef();
  const [showModal, setShowModal] = React.useState(false);
  useEffect(() => {
    brnref.current.click();
  }, []);

  const closeClk = () => {
    setShowModal(false);
    setInt((p) => p + 1);
  };

  return (
    <>
      <button
        className="bg-pink-500 hidden text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
        ref={brnref}
      >
        Open regular modal
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex mystyle overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none" data-aos="fade-down">
            <div className={`relative w-auto my-6 mx-auto max-w-3xl !px-2`}>
              {/*content*/}
              <div
                className={`border-0 rounded shadow-lg relative flex flex-col w-full outline-none focus:outline-none`}
              >
                <ImCross
                  className="text-xl absolute text-slate-50 right-4 -top-10 cursor-pointer lg:-right-10"
                  onClick={closeClk}
                />
                <Link
                  to={"/registration"}
                  className="relative flex-auto rounded-sm overflow-hidden"
                >
                  <img
                    src={data.banner_image}
                    className="w-full h-full max-h-[60vh] lg:max-h-[75vh] min-w-[200px] lg:min-w-[300px] min-h-[40vh] object-contain "
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 !bg-[#000000] lastSty"></div>
        </>
      ) : null
      }
    </>
  );
};

export default Ads;
