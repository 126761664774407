import React, { useRef } from "react";
import { useEffect } from "react";
import { ImCross } from "react-icons/im";

const PreRegistation = (props) => {
  const Btnref = useRef();
  const { setModalOpen, FilteredData } = props;
  const data = FilteredData[0];
  useEffect(() => {
    Btnref.current.click();
  }, []);
  const streamCHeck = (e) => {
    if (e === 1) return "BCA";
    else if (e === 2) return "BBM";
    else if (e === 3) return "BBS";
    else if (e === 1) return "BA/BSW";
    else return "MBS";
  };
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary hidden"
        ref={Btnref}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      ></button>
      <div
        className="modal fade mt-[50px] modal-lg"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog relative">
          <div className="modal-content p-5 relative flex items-center justify-center">
            <ImCross
              className="text-gray-500 absolute right-5 top-4 cursor-pointer hover:text-gray-800"
              data-bs-dismiss="modal"
              onClick={() => setModalOpen(false)}
            />
            <h1 className="text-start font-semibold text-xl mb-3 !text-[#5c5c5c]">
              {data.name.slice(0, 1).toUpperCase() + data.name.slice(1)}{" "}
              Details:
            </h1>
            <div className="w-full h-[1px] bg-slate-200 mb-3"></div>
            <div className="mt-1 flex gap-3 flex-col !text-sm">
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Name:{" "}
                </span>{" "}
                <span> {data.name}</span>
              </div>
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Date Of Birth:{" "}
                </span>{" "}
                <span> {data.date_of_birth}</span>
              </div>
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Mobile Number:{" "}
                </span>{" "}
                <span> {data.mobile_num}</span>
              </div>
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Stream Name:{" "}
                </span>{" "}
                <span> {streamCHeck(data.stream_name)}</span>
              </div>
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Email:{" "}
                </span>{" "}
                <span> {data.email}</span>
              </div>
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Address:{" "}
                </span>{" "}
                <span> {data.address}</span>
              </div>
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Father Name:{" "}
                </span>{" "}
                <span> {data.father_name === "" || data.father_name === null ? "-" : data.father_name}</span>
              </div>
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Father Occupation:{" "}
                </span>{" "}
                <span>
                  {data.father_occupation === "" || data.father_occupation === null ? "-" : data.father_occupation}
                </span>
              </div>
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Mother Name:{" "}
                </span>{" "}
                <span>{data.mother_name === "" || data.mother_name === null ? "-" : data.mother_name}</span>
              </div>
              <div className="flex gap-1 border-b border-slate-200 !text-[#5c5c5c] w-full px-3">
                <span className="font-semibold tracking-wide w-[200px]">
                  Mother Occupation:{" "}
                </span>{" "}
                <span>
                  {data.mother_occupation === "" || data.mother_name === null ? "-" : data.mother_occupation}
                </span>
              </div>
            </div>
            <div className="w-full h-[1px] bg-slate-200 mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreRegistation;
