import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";

const EachVideos = (props) => {
    const { imageClk, url } = props;
    const a = document.querySelectorAll('.VIdeo');
    if (!imageClk) {
        if (a !== null) {
            a.forEach((e) => e.pause())
        }
    }

    return (
        <>  
            <Swiper
                pagination={{
                    type: "progressbar",
                }}
                modules={[Navigation]}
                className="myImageSwiper"
            >
                <SwiperSlide className='bg-black'>
                    <video controls id='vID1' className='VIdeo absolute top-0 bottom-0 right-0 left-0 h-full w-full object-contain lg:opacity-80 select-none'>
                        <source src={url} type="video/mp4" className=' absolute top-0 bottom-0 right-0 left-0 h-full w-full object-contain lg:opacity-80 select-none' />
                    </video>
                </SwiperSlide>
            </Swiper>

        </>
    )
}

export default EachVideos