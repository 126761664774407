import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const ABoutHero = () => {
  const { getCarouselPublicApi, carouselData } = useContext(PublicApiContext);

  const bannerdata = carouselData;
  useEffect(() => {
    getCarouselPublicApi();
    // eslint-disable-next-line
  }, []);
  const herodata = bannerdata
    ? bannerdata.filter((e) => e.title.toLowerCase() === "about")
    : [];
  return (
    <>
      {herodata[0] ? (
        <div
          data-aos="zoom-in-up"
          className="AboutHero flex items-center justify-center h-[75vh] w-full relative bg-black"
        >
          <img
            src={`${herodata[0].banner_image}`}
            alt="Banner Img"
            className=" h-[75vh] opacity-40 object-cover w-full z-0 absolute top-0 bottom-0 right-0 left-0"
            loading="lazy"
          />
          <h3 className="text-3xl mb-20 z-10 w-[40%] text-center capitalize text-white">
            {herodata[0].slogan}
          </h3>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ABoutHero;
