import React, { useEffect } from 'react'
import ABoutHero from './ABoutHero'
// import UseImagePopup from '../../../Hookes/UseImagePopup'
import '../../CSS/About.css'
import AboutSectionCatds from './AboutSectionCatds'
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "GNC-About us";
  }, []); 

  return (
    <div id='AboutSection'>
      {/* <UseImagePopup /> */}
      <ABoutHero />
      <div id="FlotedContent" className='flex flex-col items-center justify-center overflow-x-hidden'>
        <div className="saperatedDiv w-[95%]">
          <AboutSectionCatds />
          {/* <AboutSectionCatds /> */}
        </div>
      </div>
    </div>
  )
}

export default About
