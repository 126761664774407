import DashboardContext from "./DashboardContext";
import Domain from "../Domain";
import { useState } from "react";
import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const DashboardState = (props) => {
  const DomainUrl = Domain();
  const [DashBoarddata, setDashBoardData] = useState([]);
  const [renderData, setRenderData] = useState(false);

  let formData = new FormData();

  const getDashboardData = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}api/v1/dashboard/dashboard`,
        { headers }
      );
      const data = await response.data;
      setDashBoardData(data);
    } catch (e) {}
  };

  const postdashboard = async (data, file) => {
    formData.append("logo", file);
    formData.append("name", data.Name);
    formData.append("mobile_no", data.mobNumber);
    formData.append("address", data.Address);
    formData.append("email", data.Email);
    formData.append("phone_no_1", data.phNumber);
    formData.append("slogan", data.Slogan);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}api/v1/dashboard/dashboard`,
        formData,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">DashBoard successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
        localStorage.setItem("logo", res.data.logo);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.name) {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.address) {
        toast.error(
          <div className="text-[14px]">{e.response.data.address[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.email) {
        toast.error(
          <div className="text-[14px]">{e.response.data.email[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.mobile_no) {
        toast.error(
          <div className="text-[14px]">{e.response.data.mobile_no[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.phone_no_1) {
        toast.error(
          <div className="text-[14px]">{e.response.data.phone_no_1[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.slogan) {
        toast.error(
          <div className="text-[14px]">{e.response.data.slogan[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  //   for delete dashboard
  const deleteDashboard = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}api/v1/dashboard/dashboard/${id}`,
        { headers }
      );
      if (response.status) {
        setRenderData(!renderData);
        toast.warning(
          <div className="deleteToast text-[15px]">
            DashBoard successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      toast.error(<div className="text-[14px]">An error occured!</div>, {
        icon: <BiErrorCircle className="text-xl " />,
      });
    }
  };

  //for update dashboard
  const UpdateDashboard = async (data, id) => {
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}api/v1/dashboard/dashboard/${id}`,
        data,
        { headers }
      );
      if (res.status) {
        setRenderData(!renderData);
        toast.success(
          <div className="deleteToast text-[15px]">
            DashBoard successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      //   toast.error("Server Error");
      if (e.response.data.name) {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.address) {
        toast.error(
          <div className="text-[14px]">{e.response.data.address[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.email) {
        toast.error(
          <div className="text-[14px]">{e.response.data.email[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.mobile_no) {
        toast.error(
          <div className="text-[14px]">{e.response.data.mobile_no[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.phone_no_1) {
        toast.error(
          <div className="text-[14px]">{e.response.data.phone_no_1[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      if (e.response.data.slogan) {
        toast.error(
          <div className="text-[14px]">{e.response.data.slogan[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };
  return (
    <DashboardContext.Provider
      value={{
        DashBoarddata,
        postdashboard,
        renderData,
        UpdateDashboard,
        deleteDashboard,
        getDashboardData,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardState;
