import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import React, { useState, useEffect } from "react";
import "../../CSS/Slider.css";
import { useContext } from "react";
import { Link } from "react-router-dom";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const Slider = () => {
  const [index, setIndex] = useState(0);
  const { getblogPublicApi, blogdata, subCategoryPublicApi, subCategorydata } =
    useContext(PublicApiContext);
  const data = blogdata;
  const SubCategoryData = subCategorydata;

  useEffect(() => {
    getblogPublicApi();
    subCategoryPublicApi();
    // eslint-disable-next-line
  }, []);
  const catid =
    SubCategoryData.length > 1
      ? SubCategoryData.filter(
          (e) =>
            e.category_name.toLowerCase() === "home" &&
            e.category_key_name.toLowerCase() === "slug-2"
        )
      : [];
  const FinalData = catid[0]
    ? data.filter((e) => e.category_key === catid[0].id)
    : [];

  const people = FinalData.length > 3 ? FinalData.slice(0, 3) : FinalData;

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <>
      <section
        data-aos="fade-right"
        className="section xl:ml-[200px] mb-[30px]"
        id="slider"
      >
        <div
          id="sliderContent"
          className="flex h-[20px] ml-[4%] w-[75px] relative md:ml-[12%]"
        >
          <div
            className="bg-[#C4C3C3] rounded-full p-[7px] absolute right-0 cursor-pointer top-[140px] z-10 lg:top-[100px]"
            onClick={() => setIndex((p) => p + 1)}
          >
            <FiChevronRight className="text-white font-bold text-base" />
          </div>
          <div
            className="bg-[#C4C3C3] rounded-full p-[7px] absolute left-0 cursor-pointer top-[140px] z-10 lg:top-[100px]"
            onClick={() => setIndex((p) => p - 1)}
          >
            <FiChevronLeft className="text-white font-bold text-base" />
          </div>
        </div>
        <div className="section-center">
          {people.map((item, indexPeople) => {
            const { id, image, author_name, blog_title } = item;
            let position = "nextSlide";
            if (indexPeople === index) {
              position = "activeSlide";
            }
            if (
              indexPeople === index - 1 ||
              (index === 0 && indexPeople === people.length - 1)
            ) {
              position = "lastSlide";
            }
            return (
              <article
                className={`${position} !h-[100%] pt-10 rounded-xl  bg-[#e7e7e7] text-black flex w-full relative`}
                key={id}
                id={image}
              >
                <div className=" contentsd lg:h-[90%] h-[100%] max-h-[920px] md:max-h-[960px] lg:max-h-[1000px] shadowCSS xl:pt-10 lg:-mt-10 rounded-2xl bg-[#ffffff40] text-black flex relative w-full lg:w-[90%] mx-auto flex-col xl:flex-row">
                  <div className="leftBox flex flex-col xl:w-[50%] w-[100%] lg:h-full lg:mt-[70px] mt-[95px]">
                    <div className="sliderThing relative lg:w-[90%] w-full mx-auto mb-[5px] h-[11px] flex justify-between items-center">
                      <div
                        className={`slide1 cursor-pointer h-full w-[32%] rounded-lg bg-[#D9D9D9] ${
                          index === 0 || index === 3 ? `activecls` : ``
                        }`}
                        onClick={() => setIndex(0)}
                      ></div>
                      <div
                        className={`slide2 cursor-pointer h-full w-[32%] rounded-lg bg-[#D9D9D9] ${
                          index === 1 ? `activecls` : ``
                        }`}
                        onClick={() => setIndex(1)}
                      ></div>
                      <div
                        className={`slide3 cursor-pointer h-full w-[32%] rounded-lg bg-[#D9D9D9] ${
                          index === 2 ? `activecls` : ``
                        }`}
                        onClick={() => setIndex(2)}
                      ></div>
                    </div>
                    <img
                      src={image}
                      alt={author_name}
                      className="w-[100%] lg:w-[90%] m-auto lg:h-[560px] lg:max-h-[600px] min-h-[300px] max-h-[200px] object-cover"
                      loading="lazy"
                    />
                  </div>
                  <div className="absolute top-[15px] lg:-right-10 right-0 bg-white shadowCSS px-4 rounded-md">
                    <h3 className="text-base md:p-2 p-1 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
                      {author_name}
                    </h3>
                  </div>
                  <div className="rigthiContent xl:w-[50%] w-[100%] !pb-3 h-full xl:mt-[90px] mt-[30px]">
                    <p className="text-gray-600 text-justify font-semibold text-xl md:text-xl md:pl-4 mb-3 pl-3 lg:pl-[8px] titleExtraStyle">
                      {blog_title}
                    </p>
                    <Link
                      to={`/blogs/filter/${item.id}`}
                      className="text-black !text-justify  pl-0 md:pl-4 extraLineCSSForQuote"
                    >
                      <span
                        className="AboutGNCCONTENT disLimSlider"
                        dangerouslySetInnerHTML={{ __html: item.blog_post }}
                      />
                    </Link>
                  </div>
                </div>
              </article>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Slider;
