import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useEffect } from "react";
import { useContext } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
export default function GncLife() {
  const { galleryPublicApi, subGallerydata, subGalleryPublicApi } =
    useContext(PublicApiContext);

  const subGalleryData = subGallerydata;
  useEffect(() => {
    galleryPublicApi();
    subGalleryPublicApi();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="h-[80vh] w-full bg-[#000000b3] py-4 flex flex-col items-center justify-center gap-4">
        <div className="px-[1px] text-base py-[1px] bg-gradient-to-r from-[#B1C269] to-[#3B9E5E]">
          <div className="button px-5 text-base py-1 text-white z-30 bg-[#343333]">
            GNC Life
          </div>
        </div>
        <div className="imageContainer h-[450px] lg:w-[75%] w-[85%] bg-[#000000b3] relative flex items-center justify-center">
          <img
            src="https://cdn.pixabay.com/photo/2022/12/02/14/13/desert-7630943_960_720.jpg"
            alt=""
            className="w-1/2 opacity-10 h-full absolute left-0 object-cover"
            loading="lazy"
          />
          <img
            src="https://cdn.pixabay.com/photo/2022/11/30/13/16/tel-aviv-7626789_960_720.jpg"
            alt=""
            className="w-1/2 opacity-10 h-full absolute right-0 object-cover"
            loading="lazy"
          />
          <Swiper
            // slidesPerView={3}
            spaceBetween={30}
            slidesPerGroup={3}
            loop={true}
            autoplay={{
              delay: 2500,
            }}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              1053: {
                width: 1053,
                slidesPerView: 3,
              },
              640: {
                width: 640,
                slidesPerView: 1,
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <div className="h-[75vh] w-full bg-[#000000b3] flex flex-col items-center justify-center gap-4">
              {subGalleryData
                .slice(
                  subGalleryData.length > 9 ? subGalleryData.length - 9 : 0,
                  subGalleryData.length > 9 ? subGalleryData.length : 9
                )
                .map((e) => {
                  return (
                    <SwiperSlide key={e.id}>
                      <div
                        data-aos="zoom-in-up"
                        className="EachImage h-[330px] w-[360px] z-10"
                        key={e.id}
                      >
                        <img
                          src={e.sub_gallery_image}
                          alt=""
                          className="select-none h-full w-full object-cover"
                          // loading="lazy"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}
