import { Button, TextField } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import GalleryContext from "../../../context/Gallery/GalleryContext";
import "../css/Post.css";

const GalleryModal = (props) => {
  const { AddBtnCk, setAddBtnClk, editData, btndata } = props;
  const { name } = btndata;
  const {
    galleryData,
    getGallery,
    postgallery,
    render,
    postSubgallery,
    // updategalleryData,
  } = useContext(GalleryContext);
  const [btnDesable, setBtnDesxable] = useState(false);
  const [data, setData] = useState({
    title: name === "update" ? editData[0].title_name : "",
    image: "",
  });
  const [image, setimage] = useState(null);
  const [Album, setAlbum] = useState({
    name: name === "update" ? editData[0].title_name : "",
    id: name === "update" ? editData[0].id : "",
  });
  const [errorMsg, setErrorMsg] = useState({
    status: true,
    msg: "",
    position: "",
  });
  const [imgErrorMsg, setimgErrorMsg] = useState({
    status: true,
    msg: "",
    position: "",
  });

  const imageDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // for Modal show or hide
  const ref = useRef();
  useEffect(() => {
    getGallery();
    if (AddBtnCk) {
      ref.current.click();
    } // eslint-disable-next-line
  }, [render]);

  const imageImageChange = (e) => {
    setimage(e.target.files[0]);
    for (let i = 0; i < e.target.files.length; i++) {
      let a = e.target.files[i].name.split(".").pop().toLowerCase();
      if (a !== "png" && a !== "jpg" && a !== "jpeg") {
        setimgErrorMsg({
          status: true,
          msg: "Image extension can only be in png and jpg",
          position: "img",
        });
      } else {
        setimgErrorMsg({ status: false, msg: "", position: "" });
      }
    }
  };

  const MultyimageImageChange = (e) => {
    setimage(e.target.files);
    for (let i = 0; i < e.target.files.length; i++) {
      let a = e.target.files[i].name.split(".").pop().toLowerCase();
      if (a !== "png" && a !== "jpg" && a !== "jpeg") {
        setimgErrorMsg({
          status: true,
          msg: "Image extension can only be in png and jpg",
          position: "img",
        });
      } else {
        setimgErrorMsg({ status: false, msg: "", position: "" });
      }
    }
  };
  const dropdownClk = (name, id) => {
    setAlbum({ name, id });
  };
  const AddBtnClk = (e) => {
    e.preventDefault();
    if (Album.name === "") {
      setErrorMsg({
        status: true,
        msg: "Please select Album",
        position: "image",
      });
    } else {
      if (Album.name === "None") {
        if (data.title.length <= 2) {
          setErrorMsg({
            status: true,
            msg: "Please Enter Album Name",
            position: "title",
          });
        } else if (image === null) {
          setErrorMsg({
            status: true,
            msg: "Please choose Image",
            position: "file",
          });
        } else {
          setErrorMsg({ status: false, msg: "", position: "" });
          if (imgErrorMsg.status === false) {
            if (Album.id === -1) {
              const finalData = { ...data, image: image };
              postgallery(finalData);
              setBtnDesxable(true);
              ref.current.click();
              setAddBtnClk(false);
            } else {
              const finalData = { ...data, image: image, gallery_id: Album.id };
              postSubgallery(finalData);
              setBtnDesxable(true);
              ref.current.click();
              setAddBtnClk(false);
            }
          }
        }
      } else {
        if (image === null) {
          setErrorMsg({
            status: true,
            msg: "Please choose Image",
            position: "file",
          });
        } else {
          setErrorMsg({ status: false, msg: "", position: "" });
          if (imgErrorMsg.status === false) {
            if (Album.id === -1) {
              const finalData = { ...data, image: image };
              postgallery(finalData);
              setBtnDesxable(true);
              ref.current.click();
              setAddBtnClk(false);
            } else {
              const finalData = { ...data, image: image, gallery_id: Album.id };
              postSubgallery(finalData);
              setBtnDesxable(true);
              ref.current.click();
              setAddBtnClk(false);
            }
          }
        }
      }
    }
  };
  const btnRef = useRef();
  return (
    <div>
      <button
        type="button"
        ref={ref}
        className="btn d-none btn-primary text-black"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade modal-lg mt-[150px] "
        id="exampleModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex items-center justify-center">
          <form className="modal-content w-[650px]">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[30px] absolute top-5 text-xl tracking-wider font-semibold"
                id="exampleModalLabel"
              >
                Upload Image
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setAddBtnClk(false)}
              />
            </div>
            <div className="modal-body flex justify-center items-center flex-col gap-3 text-black">
              <div className="dropdown dropdownbtn galleryDropdown">
                <div
                  className="btn btn-secondary dropdownbtn relative galleryMidalDropdown !pt-[10px]"
                  id="dropdownMenuButton1"
                  ref={btnRef}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {Album.name === "" ? "--Select Image--" : Album.name}
                  <AiOutlineCaretDown className="absolute right-3 top-5" />
                </div>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li
                    className="category"
                    value={"-1"}
                    name="None"
                    onClick={() => dropdownClk("None", -1)}
                  >
                    None
                  </li>
                  {/* )} */}
                  {name !== "update" &&
                    galleryData.map((e) => {
                      return (
                        <li
                          className="category"
                          value={e.id}
                          name="None"
                          key={e.id}
                          onClick={() => dropdownClk(e.title_name, e.id)}
                        >
                          {e.title_name}
                        </li>
                      );
                    })}
                </ul>
              </div>
              {errorMsg.position === "image" ? (
                <p className="text-red-500 text-xs w-[95%] ml-8 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {Album.id === -1 || Album.id === "" ? (
                <TextField
                  id="outlined-basic"
                  className="w-[90%] m-auto"
                  value={data.title}
                  onChange={imageDataChange}
                  name="title"
                  label="Album Name*"
                  variant="outlined"
                />
              ) : (
                ""
              )}
              {errorMsg.position === "title" ? (
                <p className="text-red-500 text-xs w-[95%] ml-8 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {Album.id !== -1 ? (
                <input
                  className="form-control form-control-lg text w-[90%] rounded-1 shadow-sm"
                  name="image"
                  multiple
                  onChange={MultyimageImageChange}
                  id="formFileLg"
                  type="file"
                  style={{ fontSize: "16px", paddingTop: "10px" }}
                />
              ) : (
                <input
                  className="form-control form-control-lg text w-[90%] rounded-1 shadow-sm"
                  name="image"
                  onChange={imageImageChange}
                  id="formFileLg"
                  type="file"
                  style={{ fontSize: "16px", paddingTop: "10px" }}
                />
              )}
              {errorMsg.position === "file" ? (
                <p className="text-red-500 text-xs w-[95%] ml-8 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {imgErrorMsg.position === "img" ? (
                <p className="text-red-500 text-xs w-[95%] ml-8 -mt-4">
                  **{imgErrorMsg.msg}**
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer pr-[50px]">
              {!btnDesable ? (
                <Button
                  type="submit"
                  variant="contained"
                  onClick={AddBtnClk}
                  size="small"
                  className="mb-2 mt-1 !capitalize"
                >
                  Upload
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  className="mb-2 mt-1 !capitalize !cursor-default"
                >
                  Upload
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GalleryModal;
