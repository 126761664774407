import React from "react";
import { useEffect } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { RiSendPlaneFill } from "react-icons/ri";
import { MdEmail, MdLocationPin, MdOutlineCall } from "react-icons/md";
import { Link } from "react-router-dom";
import "../Components/CSS/Footer.css";
import { useContext } from "react";
import PublicApiContext from "../../context/PublicAPI/PublicApiContext";

const Footer = () => {
    let iconStyles = { color: "green", fontSize: "19px" };
    const { footerPublicApi, footerdata } = useContext(PublicApiContext);
    const data = footerdata
    useEffect(() => {
        footerPublicApi();
        //eslint-disable-next-line
    }, []);

    const date = new Date();

    return (
        <div id="footer" className="bg-[#000000B2]">
            <footer className="text-center text-base">
                <section>
                    <div className=" text-center text-md-start pt-5 pb-3">
                        <div className="flex mt-3 justify-between flex-col md:flex-row gap-3 md:gap-0">
                            <div className="col-md-3 col-lg-4 md:pl-10 col-xl-3 mx-auto mb-4">
                                <h6 className=" fw-bold mb-4">
                                    <p className="lg:h3 text-sm md:text-base">
                                        {data[0] ? data[0].slogan : ""}
                                    </p>
                                </h6>
                                <p className="h6 text-sm md:text-base">
                                    {data[0] ? data[0].name : ""}
                                </p>
                            </div>

                            <div className="footerHov col-md-2 col-lg-2 text-sm md:text-base col-xl-2 mx-auto">
                                <h6 className=" fw-bold md:mb-4 mb-2 text-sm md:text-base">
                                    Quick Links
                                </h6>
                                <p>
                                    <Link
                                        to="/notes"
                                        className="text-reset hover:underline flex gap-1"
                                    >
                                        {" "}
                                        <AiOutlineRight
                                            className="!text-xs mt-[4px]"
                                            id="icons"
                                            style={iconStyles}
                                        />{" "}
                                        Notes
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to="/notices"
                                        className="text-reset hover:underline flex gap-1"
                                    >
                                        {" "}
                                        <AiOutlineRight
                                            className="!text-xs mt-[4px]"
                                            id="icons"
                                            style={iconStyles}
                                        />{" "}
                                        Notices
                                    </Link>
                                </p>
                                {/* <h1 class="bg-gradient-to-r from-[#B1C269] to-[#31C269] inline-block text-transparent bg-clip-text">hello world</h1> */}
                                <p>
                                    <Link
                                        to="/registration"
                                        className="text-reset hover:underline flex gap-1"
                                    >
                                        {" "}
                                        <AiOutlineRight
                                            className="!text-xs mt-[6px]"
                                            style={iconStyles}
                                        />
                                        Registration
                                    </Link>
                                </p>
                            </div>

                            <div className="footerHov col-md-2 col-lg-2 text-sm md:text-base col-xl-2 mx-auto">
                                <h6 className="fw-bold mb-2 md:mb-4">Academics</h6>
                                <p>
                                    <Link
                                        to="/academics/post-graduate/mbs-(tu)"
                                        className="text-reset flex hover:underline gap-1"
                                    >
                                        <AiOutlineRight
                                            className="!text-xs mt-[5px]"
                                            style={iconStyles}
                                        />{" "}
                                        MBS
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to="/academics/graduate/bbs-(tu)"
                                        className="text-reset flex hover:underline gap-1"
                                    >
                                        <AiOutlineRight
                                            className="!text-xs mt-[5px]"
                                            style={iconStyles}
                                        />{" "}
                                        BBS
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to="/academics/graduate/bbm-(tu)"
                                        className="text-reset flex hover:underline gap-1"
                                    >
                                        <AiOutlineRight
                                            className="!text-xs mt-[5px]"
                                            style={iconStyles}
                                        />{" "}
                                        BBM
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to="/academics/graduate/bca-(tu)"
                                        className="text-reset flex hover:underline gap-1"
                                    >
                                        <AiOutlineRight
                                            className="!text-xs mt-[5px]"
                                            style={iconStyles}
                                        />{" "}
                                        BCA
                                    </Link>
                                </p>
                                <p>
                                    <Link
                                        to="/academics/graduate/bsw-(tu)"
                                        className="text-reset flex hover:underline gap-1"
                                    >
                                        <AiOutlineRight
                                            className="!text-xs mt-[5px]"
                                            style={iconStyles}
                                        />{" "}
                                        BA/BSW
                                    </Link>
                                </p>
                            </div>

                            <div className="col-md-4 col-lg-3 mb-2 md:mb-4 flex md:items-start md:gap-3 text-sm md:text-base flex-col items-center">
                                <h6 className="fw-bold ">Contact Info</h6>

                                <a
                                    className="hover:text-slate-300"
                                    href="https://www.google.com/maps/place/Greenfield+National+College/@27.7044563,85.2832174,15z/data=!4m5!3m4!1s0x0:0x56d62869f1ee2301!8m2!3d27.7044563!4d85.2832174"
                                    target="blank"
                                >
                                    <p className="flex gap-1 cursor-pointer">
                                        <MdLocationPin
                                            className="mt-[3px] text-base"
                                            style={iconStyles}
                                        />{" "}
                                        {data[0] ? data[0].address : ""}
                                    </p>
                                </a>
                                {/* <p className='flex gap-1 cursor-pointer'><MdLocationPin className='mt-[5px] text-[22px]' /> Bafal, Chetana Marg, Kathmandu, Nepal</p>  */}
                                <p className="flex gap-1 cursor-pointer">
                                    {" "}
                                    <MdOutlineCall
                                        className="text-[19px] mt-[2px]"
                                        style={iconStyles}
                                    />{" "}
                                    {data[0] ? data[0].phone_no_1 : ""} {data[0] ? "," : ""}{" "}
                                    {data[0] ? data[0].mobile_no : ""}
                                </p>

                                <a
                                    className="hover:text-slate-300"
                                    href="https://mail.google.com/mail/u/0/#inbox?compose=new"
                                    target="blank"
                                >
                                    <p className="flex gap-1 cursor-pointer">
                                        <MdEmail className="mt-[3px]" style={iconStyles} />{" "}
                                        {data[0] ? data[0].email : ""}{" "}
                                    </p>
                                </a>
                                <a
                                    className="hover:text-slate-300"
                                    href="https://www.google.com/maps/place/Greenfield+National+College/@27.7044563,85.2832174,15z/data=!4m5!3m4!1s0x0:0x56d62869f1ee2301!8m2!3d27.7044563!4d85.2832174"
                                    target="blank"
                                >
                                    <p className="flex gap-1 cursor-pointer">
                                        <RiSendPlaneFill className="mt-[3px]" style={iconStyles} />{" "}
                                        Google map
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center p-4 footerst text-sm md:text-base">
                    © Copyright {date.getFullYear()} Greenfield National College. All Rights Reserved.{" "}
                    <br />
                    Powered by:{" "}
                    <a href="https://iotech.com.np/" className="text-white">
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
