import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import LoginContext from "./LoginContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Domain from "../Domain";
import { useState } from "react";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";

const LoginState = (props) => {
  const DomainUrl = Domain();
  const Navigate = useNavigate();
  const LoginFunction = async (data) => {
    try {
      const res = await axios.post(`${DomainUrl}api/v1/user/login/`, data);
      const response = res.data;
      const tokens = response.tokens;
      if (tokens) {
        localStorage.setItem("Authorization", tokens.access);
        Navigate("/admin/dashboard");
        toast.success(
          <div className="text-[15px]">Login successfull</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.warning(
          <div className="text-[14px]"> {e.response.data.detail}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else {
        // toast.error("An error occured!");
        toast.error(
          <>
            <div id="ErrorforTost">Error!</div>Invalid username or password
          </>
        );
      }
    }
  };

  // search value
  const [searchValue, setSearchValaue] = useState('');

  return (
    <>
      <LoginContext.Provider value={{ LoginFunction,searchValue, setSearchValaue }}>
        {props.children}
      </LoginContext.Provider>
    </>
  );
};

export default LoginState;
