import React from "react";
import BodyDivCards from "./BodyDivCards";

const BodyDiv = () => {
  return (
    <div className="saperatedDiv">
      <BodyDivCards />
    </div>
  );
};

export default BodyDiv;
