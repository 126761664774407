import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import "../../../Components/CSS/Footer.css";
import { useState } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const Motive = () => {
  const { getblogPublicApi, blogdata, subCategoryPublicApi, subCategorydata } =
    useContext(PublicApiContext);
  const data = blogdata;
  const SubCategoryData = subCategorydata;

  useEffect(() => {
    getblogPublicApi();
    subCategoryPublicApi();
    // eslint-disable-next-line
  }, []);

  const subCatId =
    SubCategoryData.length > 1
      ? SubCategoryData.filter(
          (e) =>
            e.category_name.toLowerCase() === "home" &&
            e.category_key_name.toLowerCase() === "slug-4"
        )
      : [];
  const FinalData = subCatId[0]
    ? data.filter((e) => e.category_key === subCatId[0].id)
    : [];
  const [initialnum, setInitialNum] = useState(0);
  return (
    <div className="min-h-[110vh] h-[100%] flex items-center shadowCSS justify-center relative">
      <div
        data-aos="zoom-in-down"
        className=" contentsd shadowCSS lg:h-[580px] pt-10 rounded-md bg-[#ffffff40] text-black flex relative lg:w-[75%] w-[98%] mx-auto lg:p-4 flex-col lg:flex-row p-2"
      >
        <div className="rigthiContent lg:w-[50%] w-[100%] h-full mt-[20px] lg:mt-20 mb-[15px]">
          <p className="tracking-wider flex md:!gap-4 gap-1 lg:ml-[50px] mb-2 lg:mb-0">
            {FinalData.map((e, ind) => {
              return (
                <span
                  className={`btn1 lg:px-5 md:px-3 px-2 py-1 font-bold shadowCSS rounded cursor-pointer select-none text-[#0d662c] scale-90 md:scale-100 ${
                    ind === initialnum ? "bg-[#000000B2] text-white" : ""
                  }`}
                  key={e.id}
                  onClick={() => setInitialNum(ind)}
                >
                  {e.blog_title}
                </span>
              );
            })}
          </p>
          <p className="text-black text-justify text-base extraLineCSSForQuote mb-2 lg:mb-0 lg:p-[50px] lg:pt-[30px] pt-[15px]">
            {FinalData[initialnum] &&
            FinalData[initialnum].blog_post.length > 400 ? (
              <>
                {/* <Link
                  to={`/api/v1/public/blog/get-blog?id=${
                    FinalData[initialnum] && FinalData[initialnum].id
                  }`}
                  className="text-black !text-justify pl-0 md:pl-4 extraLineCSSForQuote"
                > */}
                <Link
                  to={`/blogs/filter/${FinalData[0] && FinalData[0].id}`}
                  className="text-black !text-justify pl-0 md:pl-4 extraLineCSSForQuote"
                >
                  <span
                    className="AboutGNCCONTENT mb-2 md:mb-0"
                    dangerouslySetInnerHTML={{
                      __html: FinalData[0]
                        ? FinalData[initialnum].blog_post.slice(0, 700)
                        : "",
                    }}
                  />
                </Link>
              </>
            ) : (
              <span
                className="AboutGNCCONTENT mb-2 md:mb-0"
                dangerouslySetInnerHTML={{
                  __html: FinalData[0] ? FinalData[initialnum].blog_post : "",
                }}
              />
            )}
          </p>
        </div>
        <div
          data-aos="fade-left"
          className="leftBox flex flex-col lg:w-[40%] w-[100%] lg:min-h-[400px] min-h-[200px] h-full lg:mt-[100px]"
        >
          <img
            src={FinalData[0] ? FinalData[initialnum].image : ""}
            alt="img"
            className="w-[100%] lg:ml-10 -mt-2 lg:min-h-[420px] min-h-[200px] h-[300px] lg:h-[550px] object-cover"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default Motive;
