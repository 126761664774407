import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import Cards from "./Cards";
import AOS from "aos";
import "aos/dist/aos.css";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
AOS.init();

const Teachers = () => {
  const { teacherPublicApi, teacherdata } = useContext(PublicApiContext);
  const data = teacherdata;
  useEffect(() => {
    teacherPublicApi();
    document.title = "GNC-Teachers";
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const getTeamsName = (id) => {
  //   if (id === 1) {
  //     return "Board of Directors (BOD)";
  //   } else if (id === 2) {
  //     return "Executive Committee";
  //   } else if (id === 3) {
  //     return "Chairperson";
  //   } else if (id === 4) {
  //     return "Principal";
  //   } else if (id === 5) {
  //     return "Administration";
  //   } else if (id === 6) {
  //     return "Consulling and Well Being";
  //   } else if (id === 7) {
  //     return "Research";
  //   } else {
  //     return "Library";
  //   }
  // };


  const BodData = data.filter((e) => e.teams === 1);
  const ECData = data.filter((e) => e.teams === 2);
  const CPData = data.filter((e) => e.teams === 3);
  const PData = data.filter((e) => e.teams === 4);
  const AdData = data.filter((e) => e.teams === 5);
  const CWBData = data.filter((e) => e.teams === 6);
  const RchData = data.filter((e) => e.teams === 7);
  const LibData = data.filter((e) => e.teams === 8);

  return (
    <>
      {BodData.length > 0 && (
        <div className="pt-5 flex flex-wrap justify-center md:justify-start gap-4 lg:pl-3 max-w-[1250px] m-auto">
          {/* for BOD  */}
          <h3 className="block w-full lg:ml-0 ml-3 font-semibold tracking-wider text-slate-600 text-lg underline">
            Board of Directors (BOD):
          </h3>
          {BodData.map((e) => {
            return <Cards data={e} key={e.id} />;
          })}
          <div className="w-full h-[1px] bg-slate-400"></div>
        </div>
      )}

      {ECData.length > 0 && (
        <div className="pt-5 flex flex-wrap justify-center md:justify-start gap-4 lg:pl-3 max-w-[1250px] m-auto">
          {/* for ECData (Executive Committee)*/}
          <h3 className="block w-full lg:ml-0 ml-3 font-semibold tracking-wider text-slate-600 text-lg underline">
            Executive Committee:
          </h3>
          {ECData.map((e) => {
            return <Cards data={e} key={e.id} />;
          })}
          <div className="w-full h-[1px] bg-slate-400"></div>
        </div>
      )}

      {CPData.length > 0 && (
        <div className="pt-5 flex flex-wrap justify-center md:justify-start gap-4 lg:pl-3 max-w-[1250px] m-auto">
          <h3 className="block w-full lg:ml-0 ml-3 font-semibold tracking-wider text-slate-600 text-lg underline">
            Chairperson:
          </h3>
          {/* for CPData (Chairperson)*/}
          {CPData.map((e) => {
            return <Cards data={e} key={e.id} />;
          })}
          <div className="w-full h-[1px] bg-slate-400"></div>
        </div>
      )}
      {PData.length > 0 && (
        <div className="py-5 flex flex-wrap justify-center md:justify-start gap-4 lg:pl-3 max-w-[1250px] m-auto">
          {/* for PData (Principal)*/}
          <h3 className="block w-full lg:ml-0 ml-3 font-semibold tracking-wider text-slate-600 text-lg underline">
            Principal:
          </h3>
          {PData.map((e) => {
            return <Cards data={e} key={e.id} />;
          })}
          <div className="w-full h-[1px] bg-slate-400"></div>
        </div>
      )}
      {AdData.length > 0 && (
        <div className="py-5 flex flex-wrap justify-center md:justify-start gap-4 lg:pl-3 max-w-[1250px] m-auto">
          {/* for AdData (Administration)*/}
          <h3 className="block w-full lg:ml-0 ml-3 font-semibold tracking-wider text-slate-600 text-lg underline">
            Administration:
          </h3>
          {AdData.map((e) => {
            return <Cards data={e} key={e.id} />;
          })}
          <div className="w-full h-[1px] bg-slate-400"></div>
        </div>
      )}
      {CWBData.length > 0 && (
        <div className="py-5 flex flex-wrap justify-center md:justify-start gap-4 lg:pl-3 max-w-[1250px] m-auto">
          {/* for CWBData (Consulling and Well Being)*/}
          <h3 className="block w-full lg:ml-0 ml-3 font-semibold tracking-wider text-slate-600 text-lg underline">
            Consulling and Well Being:
          </h3>
          {CWBData.map((e) => {
            return <Cards data={e} key={e.id} />;
          })}
          <div className="w-full h-[1px] bg-slate-400"></div>
        </div>
      )}
      {RchData.length > 0 && (
        <div className="py-5 flex flex-wrap justify-center md:justify-start gap-4 lg:pl-3 max-w-[1250px] m-auto">
          {/* for RchData (Research)*/}
          <h3 className="block w-full lg:ml-0 ml-3 font-semibold tracking-wider text-slate-600 text-lg underline">
            Research:
          </h3>
          {RchData.map((e) => {
            return <Cards data={e} key={e.id} />;
          })}
          <div className="w-full h-[1px] bg-slate-400"></div>
        </div>
      )}
      {LibData.length > 0 && (
        <div className="py-5 flex flex-wrap justify-center md:justify-start gap-4 lg:pl-3 max-w-[1250px] m-auto">
          {/* for LibData (Library)*/}
          <h3 className="block w-full lg:ml-0 ml-3 font-semibold tracking-wider text-slate-600 text-lg underline">
            Library:
          </h3>
          {LibData.map((e) => {
            return <Cards data={e} key={e.id} />;
          })}
          <div className="w-full h-[1px] bg-slate-400"></div>
        </div>
      )}
    </>
  );
};

export default Teachers;
