import React from "react";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import NoticeContext from "../../../context/notice/NoticeContext";
import NoticeModal from "../Modals/NoticeModal";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";


const Notice = () => {
  const { dataNotice, renderData, getNotice, deleteNotice } =
    useContext(NoticeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [btnNameID, setBtnNameID] = useState({ btnName: "", id: "" });
  const [updateData, setUpdateData] = useState([]);
  useEffect(() => {
    getNotice(); // eslint-disable-next-line
  }, [renderData]);
  const AddBtnClick = () => {
    setModalOpen(true);
    setBtnNameID({ btnName: "addBtn", id: "-1" });
  };
  const editbtnclk = (id) => {
    setModalOpen(true);
    setBtnNameID({ btnName: "editBtn", id });
    const sendData = dataNotice.filter((e) => e.id === id);
    setUpdateData(sendData);
  };

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteBtnClk = (id, name) => {
    setOpen(true);
    setBtnNameID({ id });
  };
  const paginationValue = 10;
  const [pagValue, setPagValue] = useState(1);
  const totalData = dataNotice.length;
  const totalPage = Math.ceil(totalData / paginationValue);

    // search data Logic
    const { searchValue } = useContext(LoginContext);
    const searchData = dataNotice.filter((e) =>
      e.notice_name.toLowerCase().includes(searchValue.toLocaleLowerCase())
    );


  return (
    <>
      <div className="h-full overflow-y-auto mt-5 md:ml-[270px] relative">
        <button
          onClick={() => AddBtnClick("addBtn", -1)}
          className=" addbutton absolute top-5 right-3 rounded-[5px] border px-3 py-2 flex items-center justify-center"
        >
          {" "}
          Add Notice <BsPlusLg className="mt-0 ml-1 text-[11px]" />
        </button>
        <div className="w-[99%]">
          <table
            border="1"
            className="border  mt-[70px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr className="border text-center" style={{ height: "40px" }}>
                <th className="border p-1 text-sm" style={{ width: "100px" }}>
                  S.N
                </th>
                <th className="border p-1 text-sm" style={{ width: "320px" }}>
                  Notice Name
                </th>
                <th className="border p-1 text-sm" style={{ width: "310px" }}>
                  Notice Post
                </th>
                <th className="border p-1 text-sm" style={{ width: "310px" }}>
                  Notice Image
                </th>
                <th className="border p-1 text-sm" style={{ width: "200px" }}>
                  Display Order
                </th>
                <th className="border p-1 text-sm" style={{ width: "200px" }}>
                  Active
                </th>
                <th className="border p-1 text-sm" style={{ width: "200px" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              { searchValue.length<2?
              <>
              {dataNotice
                .slice(
                  (pagValue - 1) * paginationValue,
                  pagValue * paginationValue
                )
                .map((e, index) => {
                  return (
                    <tr
                      key={e.id}
                      className="border text-center border-b-0 h-[40px]"
                    >
                      <td className="border p-1 text-sm w-[5%]">
                        {index + 1 + (pagValue - 1) * paginationValue}
                      </td>
                      <td className="border p-1 text-sm w-[25%]">
                        {e.notice_name}
                      </td>
                      <td className="border p-1 text-sm w-[30%]">
                        {e.notice_post.length > 30 ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: e.notice_post.slice(0, 30) + "...",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: e.notice_post.slice(0, 30),
                              }}
                            />
                          </>
                        )}
                      </td>
                      <td className="border p-1 w-[12%]">
                        <img
                          src={e.image}
                          className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                          alt=""
                          loading="lazy"
                        />{" "}
                      </td>
                      <td className="border p-1 text-sm w-[15%]">
                        {e.display_order}
                      </td>
                      <td className="border p-1 text-sm w-[200px]">
                        {e.active ? (
                          <AiFillCheckCircle
                            className={`text-green-600  cursor-pointer m-auto text-xl`}
                          />
                        ) : (
                          <AiFillCloseCircle
                            className={`text-red-600  cursor-pointer m-auto text-xl`}
                          />
                        )}
                      </td>
                      <td className="flex items-center justify-center my-1">
                        <button className="edit actionIcons ">
                          <MdEdit
                            className="text-xl text-white icons"
                            onClick={() => editbtnclk(e.id)}
                          />
                        </button>
                        <button className="delete actionIcons ">
                          <MdDelete
                            className="text-xl text-white icons"
                            onClick={() => deleteBtnClk(e.id)}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
                </>:
              <>
              {searchData
                .slice(
                  (pagValue - 1) * paginationValue,
                  pagValue * paginationValue
                )
                .map((e, index) => {
                  return (
                    <tr
                      key={e.id}
                      className="border text-center border-b-0 h-[40px]"
                    >
                      <td className="border p-1 text-sm w-[5%]">
                        {index + 1 + (pagValue - 1) * paginationValue}
                      </td>
                      <td className="border p-1 text-sm w-[25%]">
                        {e.notice_name}
                      </td>
                      <td className="border p-1 text-sm w-[30%]">
                        {e.notice_post.length > 30 ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: e.notice_post.slice(0, 30) + "...",
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: e.notice_post.slice(0, 30),
                              }}
                            />
                          </>
                        )}
                      </td>
                      <td className="border p-1 w-[12%]">
                        <img
                          src={e.image}
                          className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                          alt=""
                          loading="lazy"
                        />{" "}
                      </td>
                      <td className="border p-1 text-sm w-[15%]">
                        {e.display_order}
                      </td>
                      <td className="border p-1 text-sm w-[200px]">
                        {e.active ? (
                          <AiFillCheckCircle
                            className={`text-green-600  cursor-pointer m-auto text-xl`}
                          />
                        ) : (
                          <AiFillCloseCircle
                            className={`text-red-600  cursor-pointer m-auto text-xl`}
                          />
                        )}
                      </td>
                      <td className="flex items-center justify-center my-1">
                        <button className="edit actionIcons ">
                          <MdEdit
                            className="text-xl text-white icons"
                            onClick={() => editbtnclk(e.id)}
                          />
                        </button>
                        <button className="delete actionIcons ">
                          <MdDelete
                            className="text-xl text-white icons"
                            onClick={() => deleteBtnClk(e.id)}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
                </>
                }
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btnNameID.id}
              deleteFun={deleteNotice}
            />
          ) : (
            <></>
          )}
          {dataNotice.length > 4 ? (
            <Pagination
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
        {modalOpen ? (
          <NoticeModal
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            updateData={updateData}
            btnNameID={btnNameID}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Notice;
