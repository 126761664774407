import React from "react";
import { ImCross } from "react-icons/im";
import { BsFillBookmarkFill } from "react-icons/bs";

const NoticeModal = (props) => {
  const { OpenRef, ModalData, setShowModal } = props;
  return (
    <>
      <button
        type="button"
        className="btn btn-primary hidden "
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        ref={OpenRef}
      ></button>

      <div
        className="modal fade bg-[#00000090]"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog lg:w-[600px] w-[320px] m-auto !mt-[40px] lg:!mt-5">
          <div className="modal-content !bg-[#fbfbfd]">
            <div className="modal-header border-none">
              <ImCross
                className="text-gray-500 absolute right-2 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setShowModal(false)}
              />
            </div>
            {ModalData[0] ? (
              ModalData[0].image ? (
                <div className="mx-4 mb-3 max-h-[50vh] h-full min-h-[20vh] max-w-full overflow-hidden">
                  <img
                    src={ModalData[0].image}
                    alt=""
                    className="h-full w-full object-center"
                    loading="lazy"
                  />
                </div>
              ) : (
                ""
              )
            ) : (
              <></>
            )}
            <h5
              className="modal-title flex items-center gap-1 text-xl font-bold px-4 tracking-wide mb-1 text-[#5c5c5c]"
              id="exampleModalLabel"
            >
              <BsFillBookmarkFill className="text-sm scale-110"/>
              {ModalData[0] ? ModalData[0].notice_name : ""}
            </h5>

            <div className="flex w-full justify-end mb-1 pr-4">
              <div className="float-right pr-5 text-[#5c5c5c] text-sm">
                <span className="font-semibold text-[#5c5c5c]">Date: </span>
                {ModalData[0] ? ModalData[0].created_date_bs : ""}
              </div>
            </div>
            <div className="modal-body mx-3 text-justify pb-0 lg:text-base text-sm mb-4">
              <span
                className="catd"
                dangerouslySetInnerHTML={{
                  __html: ModalData[0] ? ModalData[0].notice_post : "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeModal;
