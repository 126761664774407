import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import "../../../Components/CSS/Footer.css";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const AboutGNC = () => {
  const { getblogPublicApi, blogdata, subCategoryPublicApi, subCategorydata } =
    useContext(PublicApiContext);
  // const { getSubCategories, SubCategoryData } = useContext(CategoryContext);
  const data = blogdata;
  const SubCategoryData = subCategorydata;

  useEffect(() => {
    getblogPublicApi();
    subCategoryPublicApi();
    // eslint-disable-next-line
  }, []);

  const catid =
    SubCategoryData.length >= 1
      ? SubCategoryData.filter(
          (e) =>
            e.category_name.toLowerCase() === "home" &&
            e.category_key_name.toLowerCase() === "slug-1"
        )
      : [];
  const FinalData = catid[0]
    ? data.filter((e) => e.category_key === catid[0].id)
    : [];

  return (
    <div
      data-aos="fade-left"
      className="maincontainer w-[100%] lg:pl-[40px] lg:w-[70%] lg:m-2 pt-20 h-[800px] md:h-[520px] rounded flex items-center justify-center relative flex-col overflow-hidden"
    >
      <div className="cont w-full h-full min-h-[200px] p-2 overflow-hidden">
        <div className="w-full h-full md:w-[80%] m-auto min-h-[220px] bg-[#ffffff59] shadowCSS rounded-lg relative flex items-center justify-center md:overflow-y-visible">
          <div className="absolute top-[15px] lg:-left-10 -left-2 bg-white shadowCSS px-4 rounded-md">
            <h3 className="text-base md:p-2 p-1 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
              Welcome To GNC
            </h3>
          </div>
          <div className="md:h-[85%] h-[94%] w-full absolute bottom-0">
            <div className="h-full w-full flex justify-around flex-col md:flex-row">
              <p className="para md:w-[50%] w-full text-justify md:text-base p-3 lg:text-base !inline-block ">
                {FinalData[0] && FinalData[0].blog_post.length > 480 ? (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: FinalData[0]
                          ? FinalData[0].blog_post.substring(0, 480) + "... "
                          : "",
                      }}
                    />
                    {/* <Link to={`/api/v1/public/blog/get-blog?id=${FinalData[0] && FinalData[0].id}`} className="inline"> */}
                    <Link
                      to={`/blogs/filter/${FinalData[0] && FinalData[0].id}`}
                      className="inline"
                    >
                      Learn more
                    </Link>
                  </>
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: FinalData[0] ? FinalData[0].blog_post : "",
                    }}
                  />
                )}
              </p>
              <div className="overflow-hidden w-full md:w-[43%] mr-5  h-[50%] md:h-[90%] flex items-center justify-center">
                <img
                  src={FinalData[0] ? FinalData[0].image : ""}
                  alt="img"
                  className="hover:scale-105 duration-500 object-contain w-[80%] h-[70%] mx-auto mb-[4%]"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutGNC;
