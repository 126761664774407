import React from "react";
import { useEffect } from "react";
import Cards from "./Cards";

const Notice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "GNC-Notices"
  }, []);

  return (
    <div className="my-5 flex flex-col items-center justify-center max-w-[1150px] m-auto">
      <Cards />
    </div>
  );
};
export default Notice;
