import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { ImCross } from "react-icons/im";
import FooterContext from "../../../context/footer/FooterContext";

const FooterModal = (props) => {
  const { postSub_Categories, UpdateFooter, data } = useContext(FooterContext);
  const { isAddClk, setIsAddClk, editableData, btnData, setBtnData } = props;
  // final backend push data
  const [inputfield, setInputfield] = useState({
    name: btnData.name === "update" ? editableData[0].name : "",
    slogan: btnData.name === "update" ? editableData[0].slogan : "",
    address: btnData.name === "update" ? editableData[0].address : "",
    email: btnData.name === "update" ? editableData[0].email : "",
    phone_no_1: btnData.name === "update" ? editableData[0].phone_no_1 : "",
    mobile_no: btnData.name === "update" ? editableData[0].mobile_no : "",
  });
  // for mobile and phone number validation
  const [MobileNumber, setMobileNumber] = useState(
    btnData.name === "update" ? editableData[0].mobile_no : ""
  );
  const [PhNumber, setPhNumber] = useState(
    btnData.name === "update" ? editableData[0].phone_no_1 : "01-"
  );
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  //when inputfield value change
  const inputfldChange = (e) => {
    setInputfield({ ...inputfield, [e.target.name]: e.target.value });
  };
  const ref = useRef();
  useEffect(() => {
    if (isAddClk) {
      ref.current.click();
    } // eslint-disable-next-line
  }, []);

  // for inputfield number change
  const inputfldMNumChange = (e) => {
    const Number = e.target.value;
    const result = Number.replace(/[^0-9]/g, "");
    setMobileNumber(result);
  };
  const inputfldPhNumChange = (e) => {
    const Number = e.target.value;
    const result = Number.replace(/[^0-9 && -]/g, "");
    setPhNumber(result);
  };

  // for add btn click
  const addfooterClk = (name) => {
    if (data.length > 1) {
      return;
    }

    if (inputfield.name.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter footer Name",
        position: "name",
      });
    } else if (inputfield.slogan.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter footer Slogan",
        position: "slogan",
      });
    } else if (inputfield.address.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter footer address",
        position: "address",
      });
    } else if (PhNumber === "") {
      setErrorMsg({
        status: true,
        msg: "Please Enter phone number",
        position: "PhNumber",
      });
    } else if (PhNumber.length > 10) {
      setErrorMsg({
        status: true,
        msg: "Number should be less than 7",
        position: "PhNumber",
      });
    } else if (PhNumber.length < 7) {
      setErrorMsg({
        status: true,
        msg: "Number should be atleast 7",
        position: "PhNumber",
      });
    } else if (MobileNumber === "") {
      setErrorMsg({
        status: true,
        msg: "Please Enter Mobile number",
        position: "MobileNumber",
      });
    } else if (MobileNumber.length !== 10) {
      setErrorMsg({
        status: true,
        msg: "Number should be in 10 characters",
        position: "MobileNumber",
      });
    } else if (
      MobileNumber.substring(0, 2) !== "97" &&
      MobileNumber.substring(0, 2) !== "98"
    ) {
      setErrorMsg({
        status: true,
        msg: "Mobile Number Must Start With 97 or 98",
        position: "MobileNumber",
      });
    } else if (MobileNumber.length < 10) {
      setErrorMsg({
        status: true,
        msg: "Number should be atleast 10",
        position: "MobileNumber",
      });
    } else if (inputfield.email.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Email address",
        position: "email",
      });
    } else if (!inputfield.email.includes("@")) {
      setErrorMsg({
        status: true,
        msg: "Please enter valid email",
        position: "email",
      });
    } else {
      setErrorMsg({ status: false, msg: "", position: "" });
      const FinalData = {
        name: inputfield.name,
        slogan: inputfield.slogan,
        address: inputfield.address,
        email: inputfield.email,
        phone_no_1: PhNumber,
        mobile_no: MobileNumber,
      };
      if (name === "update") {
        UpdateFooter(FinalData, btnData.id);
      } else {
        postSub_Categories(FinalData);
      }
      setInputfield({
        name: "",
        slogan: "",
        address: "",
        email: "",
        phone_no_1: "",
        mobile_no: "",
      });
      setMobileNumber("");
      setPhNumber("");
      setIsAddClk(false);
      ref.current.click();
    }
  };

  const btnClose = () => {
    setIsAddClk(false);
    setInputfield({
      name: "",
      slogan: "",
      address: "",
      email: "",
      phone_no_1: "",
      mobile_no: "",
    });
    setMobileNumber("");
    setPhNumber("");
    setBtnData({ id: "", name: "" });
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-primary d-none"
        ref={ref}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      ></button>
      <div
        className="modal fade"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <form className="modal-content m-auto">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[75px] absolute top-4 text-lg text-black font-semibold tracking-wider"
                id="exampleModalLabel"
              >
                Create Footer
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => btnClose(false)}
              />
            </div>
            <div className="modal-body flex justify-center items-center flex-col gap-2">
              <TextField
                id="outlined-basic"
                className="w-[80%] m-auto"
                name="name"
                value={inputfield.name}
                onChange={inputfldChange}
                label="Name*"
                variant="outlined"
              />
              {errorMsg.position === "name" ? (
                <p className="text-red-500 text-xs ml-4 w-[80%] -mt-2">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                className="w-[80%] m-auto"
                name="slogan"
                value={inputfield.slogan}
                onChange={inputfldChange}
                label="Slogan*"
                variant="outlined"
              />
              {errorMsg.position === "slogan" ? (
                <p className="text-red-500 text-xs ml-4 w-[80%] -mt-2">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                className="w-[80%] m-auto"
                name="address"
                value={inputfield.address}
                onChange={inputfldChange}
                label="Address*"
                variant="outlined"
              />
              {errorMsg.position === "address" ? (
                <p className="text-red-500 text-xs ml-4 w-[80%] -mt-2">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                className="w-[80%] m-auto"
                name="phoneNumber"
                value={PhNumber}
                onChange={inputfldPhNumChange}
                label="Phone Number*"
                variant="outlined"
              />
              {errorMsg.position === "PhNumber" ? (
                <p className="text-red-500 text-xs ml-4 w-[80%] -mt-2">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                className="w-[80%] m-auto"
                name="mobileNumber"
                value={MobileNumber}
                onChange={inputfldMNumChange}
                label="Mobile Number*"
                variant="outlined"
              />
              {errorMsg.position === "MobileNumber" ? (
                <p className="text-red-500 text-xs ml-4 w-[80%] -mt-2">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                className="w-[80%] m-auto"
                name="email"
                value={inputfield.email}
                onChange={inputfldChange}
                label="Email*"
                variant="outlined"
              />
              {errorMsg.position === "email" ? (
                <p className="text-red-500 text-xs ml-4 w-[80%] -mt-2">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer flex mr-[80px]">
              <Button
                type="submit"
                variant="contained"
                className="mb-3 mt-1 !capitalize"
                onClick={() => addfooterClk(editableData[0] ? "update" : "add")}
              >
                {editableData[0] ? "Update Footer" : "Create"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FooterModal;
