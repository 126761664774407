import React from "react";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import ClubsContext from "../../../context/Clubs/ClubsContext";
import ClubsModal from "../Modals/Clubs";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const Clubs = () => {
  const { dataClubs, renderData, getClubs, deleteClubs } =
    useContext(ClubsContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [btnNameID, setBtnNameID] = useState({ btnName: "", id: "" });
  const [updateData, setUpdateData] = useState([]);
  useEffect(() => {
    getClubs(); // eslint-disable-next-line
  }, [renderData]);
  const AddBtnClick = () => {
    setModalOpen(true);
    setBtnNameID({ btnName: "addBtn", id: "-1" });
  };
  const editbtnclk = (id) => {
    setModalOpen(true);
    setBtnNameID({ btnName: "editBtn", id });
    const sendData = dataClubs.filter((e) => e.id === id);
    setUpdateData(sendData);
  };

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteBtnClk = (id, name) => {
    setOpen(true);
    setBtnNameID({ id });
  };
  const paginationValue = 10;
  const [pagValue, setPagValue] = useState(1);
  const totalData = dataClubs.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = dataClubs.filter((e) =>
    e.title.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );

  return (
    <>
      <div className="h-full overflow-y-auto mt-5 md:ml-[270px] relative">
        <button
          onClick={() => AddBtnClick("addBtn", -1)}
          className=" addbutton absolute top-5 right-3 rounded-[5px] border px-3 py-2 flex items-center justify-center"
        >
          {" "}
          Add Clubs <BsPlusLg className="mt-0 ml-1 text-[11px]" />
        </button>
        <div className="w-[99%]">
          <table
            border="1"
            className="border  mt-[70px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr className="border text-center" style={{ height: "40px" }}>
                <th className="border p-1 text-sm" style={{ width: "100px" }}>
                  S.N
                </th>
                <th className="border p-1 text-sm !min-w-[300px]" style={{ width: "400px" }}>
                  Clubs Name
                </th>
                <th className="border p-1 text-sm !min-w-[300px]" style={{ width: "400px" }}>
                  Clubs Post
                </th>
                <th className="border p-1 text-sm" style={{ width: "310px" }}>
                  Clubs Image
                </th>
                <th className="border p-1 text-sm" style={{ width: "100px" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {dataClubs
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          key={e.id}
                          className="border text-center border-b-0 h-[40px]"
                        >
                          <td className="border p-1 text-sm w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border p-1 text-sm w-[30%]">
                            {e.title}
                          </td>
                          <td className="border p-1 text-sm w-[40%]">
                            {e.post.length > 30 ? (
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: e.post.slice(0, 40) + "...",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: e.post.slice(0, 30),
                                  }}
                                />
                              </>
                            )}
                          </td>
                          <td className="border p-1 w-[15%]">
                            <img
                              src={e.image}
                              className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                              alt=""
                              loading="lazy"
                            />{" "}
                          </td>
                          <td className="flex items-center justify-center my-1">
                            <button className="edit actionIcons ">
                              <MdEdit
                                className="text-xl text-white icons"
                                onClick={() => editbtnclk(e.id)}
                              />
                            </button>
                            <button className="delete actionIcons ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {searchData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          key={e.id}
                          className="border text-center border-b-0 h-[40px]"
                        >
                          <td className="border p-1 text-sm w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border p-1 text-sm w-[25%]">
                            {e.title}
                          </td>
                          <td className="border p-1 text-sm w-[30%]">
                            {e.post.length > 30 ? (
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: e.post.slice(0, 30) + "...",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: e.post.slice(0, 30),
                                  }}
                                />
                              </>
                            )}
                          </td>
                          <td className="border p-1 w-[12%]">
                            <img
                              src={e.image}
                              className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                              alt=""
                              loading="lazy"
                            />{" "}
                          </td>
                          <td className="border p-1 text-sm w-[15%]">
                            {e.display_order}
                          </td>
                          <td className="border p-1 text-sm w-[200px]">
                            {e.active ? (
                              <AiFillCheckCircle
                                className={`text-green-600  cursor-pointer m-auto text-xl`}
                              />
                            ) : (
                              <AiFillCloseCircle
                                className={`text-red-600  cursor-pointer m-auto text-xl`}
                              />
                            )}
                          </td>
                          <td className="flex items-center justify-center my-1">
                            <button className="edit actionIcons ">
                              <MdEdit
                                className="text-xl text-white icons"
                                onClick={() => editbtnclk(e.id)}
                              />
                            </button>
                            <button className="delete actionIcons ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btnNameID.id}
              deleteFun={deleteClubs}
            />
          ) : (
            <></>
          )}
          {dataClubs.length > 4 ? (
            <Pagination
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
        {modalOpen ? (
          <ClubsModal
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            updateData={updateData}
            btnNameID={btnNameID}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Clubs;
