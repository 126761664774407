import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const HeadDiv = () => {
  const { getblogPublicApi, blogdata, dataLength } =
    useContext(PublicApiContext);
  const location = useLocation().pathname;

  useEffect(() => {
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);
  const makeUrl = (name) => {
    if (name) {
      return name.replace(/\s+/g, "-").toLowerCase();
    }
  };

  const data = blogdata.filter(
    (e) => makeUrl(e.category_name) === location.substring(1).toLowerCase()
  );

  return (
    <div
      className={`${
        dataLength > 1 ? "lg:w-[60%]" : "lg:w-[65%]"
      } w-[95%] md:w-[65%] m-auto min-h-[230px] flex justify-center items-center pb-5 flex-col gap-2 bg-[#ffffff59]  lg:p-10 shadowCSS !my-20 rounded`}
    >
      <h3
        className="text-[#0D662C] text-center text-xl md:text-xl font-semibold"
        style={{ fontFamily: "Abhaya Libre" }}
      >
        {data[0] ? data[0].blog_title : ""}
      </h3>
      <p className="px-1 md:px-5 text-sm md:text-base text-justify w-[100%] lg:w-[70%]">
        <span
          dangerouslySetInnerHTML={{
            __html: data[0] ? data[0].blog_post : "",
          }}
        />
      </p>
    </div>
  );
};

export default HeadDiv;
