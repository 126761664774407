import { Pagination } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import GalleryContext from "../../../context/Gallery/GalleryContext";
import GalleryModal from "../Modals/GalleryModal";
import SubGalleryModal from "../Modals/SubGalleryModal";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";


const Gallery = () => {
  const {
    galleryData,
    getGallery,
    deletegallery,
    render,
    getSubGallery,
    subgalleryrender,
  } = useContext(GalleryContext);
  const [AddBtnCk, setAddBtnClk] = useState(false);
  const [btndata, setBtnData] = useState({ name: "", id: "" });
  const [subGallerymodalOpen, setSubGalleryModalOpen] = useState(false);
  const [FilteredData, setFilteredData] = useState([]);
  const [editData, setEditData] = useState([]);
  // const getSubGallery =
  const editBtnClk = (id) => {
    setAddBtnClk(true);
    setBtnData({ name: "update", id });
    const data = galleryData.filter((e) => e.id === id);
    setEditData(data);
  };

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteClk = (id, name) => {
    setOpen(true);
    setBtnData({ id });
  };

  //for sub gallery modal open
  const subgalleryClk = (id) => {
    const data = galleryData.filter((e) => e.id === id);
    setFilteredData(data);
    setSubGalleryModalOpen(true);
  };
  useEffect(() => {
    getGallery();
    getSubGallery();
    // eslint-disable-next-line
  }, [render, subgalleryrender]);

  const SubCategoryAddBtnCLk = () => {
    setAddBtnClk(true);
    setBtnData({ name: "addBtn", id: -1 });
  };
  const paginationValue = 5;
  const [pagValue, setPagValue] = useState(1);
  const totalData = galleryData.length;
  const totalPage = Math.ceil(totalData / paginationValue);

      // search data Logic
      const { searchValue } = useContext(LoginContext);
      const searchData = galleryData.filter((e) =>
        e.title_name.toLowerCase().includes(searchValue.toLocaleLowerCase())
      );
  

  return (
    <>
      <div className="h-[80vh] overflow-y-auto mt-12 md:ml-[255px]">
        <div id="table" className="w-[95%] pt-[45px] relative m-auto">
          <button
            className=" addbutton absolute top-0 right-0 rounded-[5px] border px-3 py-2 flex items-center justify-center"
            onClick={SubCategoryAddBtnCLk}
          >
            Add Images
            <BsPlusLg className="mt-1 ml-1 text-[11px]" />
          </button>
          <table
            border="1"
            className="border mt-[10px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr
                className="border text-center"
                style={{ height: "50px", fontSize: "14px" }}
              >
                <th className="border p-1 w-[10%]">S.N</th>
                <th className="border p-1 w-[40%]">Title</th>
                <th className="border p-1 w-[30%]">Albums</th>
                <th className="border p-1 w-[30%]">Images</th>
                <th className="border p-1 max-w-[100px]">Action</th>
              </tr>
            </thead>
            <tbody>
              { searchValue.length<2?
              <>
              {galleryData
                .slice(
                  (pagValue - 1) * paginationValue,
                  pagValue * paginationValue
                )
                .map((e, index) => {
                  return (
                    <tr
                      style={{ fontSize: "14px", height: "20px" }}
                      className="border text-center border-b-0"
                      key={e.id}
                    >
                      <td className="border px-1 min-w-[40px] w-[5%]">
                        {index + 1 + (pagValue - 1) * paginationValue}
                      </td>
                      <td className="border px-1 min-w-[100px] w-[25%]">
                        {e.title_name}
                      </td>
                      <td className="border px-1 min-w-[100px] w-[12%]">
                        <img
                          src={e.gallery_image}
                          className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                          alt=""
                          loading="lazy"
                        />
                      </td>
                      <td className="border px-1 min-w-[100px] w-[12%] relative">
                        {e.image_gallery_id.length > 0 ? (
                          <>
                            <div
                              className="absolute top-0 right-0 bottom-0 left-0 m-auto duration-500 bg-[#2b1b1b85] hover:bg-[#2b1b1bc9] cursor-pointer flex items-center justify-center h-[30px] w-[30px] rounded-full"
                              onClick={() => subgalleryClk(e.id)}
                            >
                              <p className="text-white text-sm tracking-wide">
                                {e.image_gallery_id.length}+
                              </p>
                            </div>
                            <img
                              src={e.image_gallery_id[0].sub_gallery_image}
                              className="h-[30px] w-[30px] rounded-full m-auto p-2 object-cover"
                              alt=""
                              loading="lazy"
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className=" p-1 h-[40px] relative m-auto first-letter flex items-center justify-center !w-[100px]">
                      {/* <td className="border flex items-center justify-center p-1 relative m-auto w-[8%] min-w-[100px]"> */}
                        <button
                          className="edit actionIcons  left-5 bottom-2"
                          onClick={() => editBtnClk(e.id)}
                        >
                          <MdEdit className="text-xl text-white icons " />
                        </button>
                        <button className="delete actionIcons  right-5 bottom-2">
                          <MdDelete
                            className="text-xl text-white icons"
                            onClick={() => deleteClk(e.id)}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
                </>:
              <>
              {searchData
                .slice(
                  (pagValue - 1) * paginationValue,
                  pagValue * paginationValue
                )
                .map((e, index) => {
                  return (
                    <tr
                      style={{ fontSize: "14px", height: "20px" }}
                      className="border text-center border-b-0"
                      key={e.id}
                    >
                      <td className="border px-1 min-w-[40px] w-[5%]">
                        {index + 1 + (pagValue - 1) * paginationValue}
                      </td>
                      <td className="border px-1 min-w-[100px] w-[25%]">
                        {e.title_name}
                      </td>
                      <td className="border px-1 min-w-[100px] w-[12%]">
                        <img
                          src={e.gallery_image}
                          className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                          alt=""
                          loading="lazy"
                        />
                      </td>
                      <td className="border px-1 min-w-[100px] w-[12%] relative">
                        {e.image_gallery_id.length > 0 ? (
                          <>
                            <div
                              className="absolute top-0 right-0 bottom-0 left-0 m-auto duration-500 bg-[#2b1b1b85] hover:bg-[#2b1b1bc9] cursor-pointer flex items-center justify-center h-[30px] w-[30px] rounded-full"
                              onClick={() => subgalleryClk(e.id)}
                            >
                              <p className="text-white text-sm tracking-wide">
                                {e.image_gallery_id.length}+
                              </p>
                            </div>
                            <img
                              src={e.image_gallery_id[0].sub_gallery_image}
                              className="h-[30px] w-[30px] rounded-full m-auto p-2 object-cover"
                              alt=""
                              loading="lazy"
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className=" p-1 h-[40px] relative m-auto first-letter flex items-center justify-center !w-[100px]">
                      {/* <td className="border flex items-center justify-center p-1 relative m-auto w-[8%] min-w-[100px]"> */}
                        <button
                          className="edit actionIcons  left-5 bottom-2"
                          onClick={() => editBtnClk(e.id)}
                        >
                          <MdEdit className="text-xl text-white icons " />
                        </button>
                        <button className="delete actionIcons  right-5 bottom-2">
                          <MdDelete
                            className="text-xl text-white icons"
                            onClick={() => deleteClk(e.id)}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
                </>
                }
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btndata.id}
              deleteFun={deletegallery}
              // deleteMsg={deleteMsg}
            />
          ) : (
            <></>
          )}
          {galleryData.length > 4 ? (
            <Pagination
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {AddBtnCk ? (
        <GalleryModal
          AddBtnCk={AddBtnCk}
          setAddBtnClk={setAddBtnClk}
          btndata={btndata}
          editData={editData}
        />
      ) : (
        ""
      )}
      {subGallerymodalOpen ? (
        <SubGalleryModal
          setModalOpen={setSubGalleryModalOpen}
          FilteredData={FilteredData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Gallery;
