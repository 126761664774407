import React, { useContext, useEffect } from "react";
import ".././css/Categories.css";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import Modal from "../Modals/CategoryModal";
import { useState } from "react";
import NewCategoryContext from "../../../context/NewCategory/NewCategoryContext";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const Categories = () => {
  const { getNewCategory, newCatdata, renderData, deleteNewCategory } =
    useContext(NewCategoryContext);
  const [clickAddbtn, setClickAddbtn] = useState(false);
  const [btnNameID, setBtnNameID] = useState({ btnName: "", id: "" });
  const [updateData, setUpdateData] = useState([]);
  const [addBtnClk, setAddBtnClk] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(
    (e) => {
      getNewCategory();
    }, // eslint-disable-next-line
    [renderData]
  );
  const buttonClkFModal = (btnName, id) => {
    setClickAddbtn(!clickAddbtn);
    setBtnNameID({ btnName, id });
    const sendData = newCatdata.filter((e) => e.id === id);
    setUpdateData(sendData);
    setAddBtnClk(true);
    const filterData = newCatdata.filter((e) => e.id === id);
    setFilteredData(filterData);
  };

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteClk = (id, name) => {
    setOpen(true);
    setBtnNameID({ id });
  };

  const paginationValue = 10;
  const [pagValue, setPagValue] = useState(1);
  const totalData = newCatdata.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = newCatdata.filter((e) =>
    e.category_name.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );

  return (
    <>
      <div
        className="h-full overflow-y-auto ml-0 md:ml-[265px] mt-10"
        id="category"
      >
        <div id="table" className="w-[95%] pt-[45px] relative m-auto">
          <button
            onClick={() => buttonClkFModal("addBtn", -1)}
            className=" addbutton absolute top-0 right-0 rounded-[5px] border px-3 py-2 flex items-center justify-center"
          >
            Add Category
            <BsPlusLg className="mt-1 ml-1 text-[11px]" />
          </button>
          <table
            border="1"
            className="border mt-[10px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr
                className="border text-center"
                style={{ height: "50px", fontSize: "14px" }}
              >
                <th className="border p-1">S.N</th>
                <th className="border p-1">Category</th>
                <th className="border p-1">Subcategory</th>
                <th className="border p-1">Display Order</th>
                <th className="border p-1">Active</th>
                <th className="border p-1">Action</th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {newCatdata
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "20px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[40px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[24%]">
                            {e.category_name}
                          </td>
                          <td className="border px-1 min-w-[100px]">
                            {e.category_key.length > 0
                              ? e.category_key.slice(0, 3).map((e) => {
                                  return (
                                    <span
                                      style={{ listStyle: "none" }}
                                      key={e.id}
                                    >
                                      {e.category_key_name},{" "}
                                    </span>
                                  );
                                })
                              : "-"}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[15%]">
                            {e.display_order}
                          </td>
                          <td className="border px-1 min-w-[130px] w-[15%]">
                            {e.active ? (
                              <AiFillCheckCircle
                                className={`text-green-600  cursor-pointer m-auto text-xl`}
                              />
                            ) : (
                              <AiFillCloseCircle
                                className={`text-red-600  cursor-pointer m-auto text-xl`}
                              />
                            )}
                          </td>
                          <td className="flex items-center justify-center p-[2px] h-[40px]">
                            <button
                              className="edit actionIcons "
                              onClick={() => buttonClkFModal("UpdateBtn", e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteClk(e.id, e.category_name)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {searchData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "20px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[40px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[24%]">
                            {e.category_name}
                          </td>
                          <td className="border px-1 min-w-[100px]">
                            {e.category_key.length > 0
                              ? e.category_key.slice(0, 3).map((e) => {
                                  return (
                                    <span
                                      style={{ listStyle: "none" }}
                                      key={e.id}
                                    >
                                      {e.category_key_name},{" "}
                                    </span>
                                  );
                                })
                              : "-"}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[15%]">
                            {e.display_order}
                          </td>
                          <td className="border px-1 min-w-[130px] w-[15%]">
                            {e.active ? (
                              <AiFillCheckCircle
                                className={`text-green-600  cursor-pointer m-auto text-xl`}
                              />
                            ) : (
                              <AiFillCloseCircle
                                className={`text-red-600  cursor-pointer m-auto text-xl`}
                              />
                            )}
                          </td>
                          <td className="flex items-center justify-center p-[2px] h-[40px]">
                            <button
                              className="edit actionIcons "
                              onClick={() => buttonClkFModal("UpdateBtn", e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteClk(e.id, e.category_name)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btnNameID.id}
              deleteFun={deleteNewCategory}
              // deleteMsg={deleteMsg}
            />
          ) : (
            <></>
          )}
          {newCatdata.length > 4 ? (
            <Pagination
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
        {addBtnClk ? (
          <Modal
            addBtnClk={addBtnClk}
            filteredData={filteredData}
            setAddBtnClk={setAddBtnClk}
            btnNameID={btnNameID}
            ModalType="category"
            updateData={updateData[0] ? updateData : ""}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Categories;
