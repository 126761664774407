import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import GalleryCards from "./GalleryCards";
import "../../CSS/EachImage.css";
import AOS from "aos";
import "aos/dist/aos.css";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
AOS.init();

const Gallery = () => {
  const { galleryPublicApi, gallerydata } = useContext(PublicApiContext);

  const galleryData = gallerydata;

  useEffect(() => {
    document.title = "GNC-Gallery";
    window.scrollTo(0, 0);
    galleryPublicApi();
    // eslint-disable-next-line
  }, []);

  const FinalGalAblum = galleryData.filter(
    (e) => e.title_name !== "HomePage Image"
  );

  return (
    <div className="min-h-[50vh] h-full py-5 overflow-hidden gallery max-w-[1100px] m-auto">
      <section className="overflow-hidden text-gray-700">
        <div className=" container px-0 py-2 mx-auto lg:pt-12 lg:px-32 flex items-center justify-center">
          <div className="flex flex-wrap -m-1 md:-m-2 gap-4 items-center justify-center md:items-start md:justify-start">
            {FinalGalAblum.map((e) => {
              return <GalleryCards elements={e} key={e.id} />;
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
