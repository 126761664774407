import React, { useRef } from "react";
import { useEffect } from "react";
import { ImCross } from "react-icons/im";
// import { ImCross } from 'react-icons/im'

const ContactModal = (props) => {
  const Btnref = useRef();
  const { setModalOpen, FilteredData } = props;
  const data = FilteredData[0];
  useEffect(() => {
    Btnref.current.click();
  }, []);
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary hidden"
        ref={Btnref}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      ></button>
      <div
        className="modal fade mt-[50px] modal-xl !w-[100vw]"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog relative">
          <div className="modal-content p-5 relative flex items-center justify-center">
            <ImCross
              className="text-gray-500 absolute right-5 top-4 cursor-pointer hover:text-gray-800"
              data-bs-dismiss="modal"
              onClick={() => setModalOpen(false)}
            />
            <h1 className="text-start font-semibold text-xl mb-3 inline w-[100%] underline">
              {data.name.slice(0, 1).toUpperCase() + data.name.slice(1)}{" "}
              Details:
            </h1>
            {/* <div className="mt-1 flex gap-6 flex-row px-[50px] w-full overflow-hidden flex-wrap"> */}
            <table className="border border-black w-full !text-center">
              <thead>
                <tr className="border border-black">
                  <th className="border border-black">Name</th>
                  <th className="border border-black">Address</th>
                  <th className="border border-black">PhNumber</th>
                  <th className="border border-black">Email</th>
                  <th className="border border-black">Message</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border border-black">
                  <td className="border border-black">{data.name}</td>
                  <td className="border border-black">{data.address}</td>
                  <td className="border border-black">{data.phone_no}</td>
                  <td className="border border-black">{data.email}</td>
                  <td className="border border-black">{data.message}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
