import React from "react";
import "../../CSS/Slider.css";
import { useEffect } from "react";
import { useContext } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const Academics = () => {
  const { getblogPublicApi, blogdata, subCategoryPublicApi, subCategorydata } =
    useContext(PublicApiContext);
  const data = blogdata;
  const SubCategoryData = subCategorydata;

  useEffect(() => {
    getblogPublicApi();
    subCategoryPublicApi();
    // eslint-disable-next-line
  }, []);
  const catid =
    SubCategoryData.length > 1
      ? SubCategoryData.filter(
          (e) =>
            e.category_name.toLowerCase() === "home" &&
            e.category_key_name.toLowerCase() === "slug-3"
        )
      : [];
  const FinalData = catid[0]
    ? data.filter((e) => e.category_key === catid[0].id)
    : [];
  return (
    <div
      data-aos="fade-left"
      id="Academics"
      className="min-h-[90vh] h-full mb-5 w-full flex items-center justify-center"
    >
      <div className="xl:w-[75%] lg:w-[90%] w-[98%] bg-[#ffffff59] shadowCSS mr-0 xl:mr-[200px] h-[90%] rounded-lg relative">
        {/* <div
          id="HeadingNames"
          className="absolute left-0 !w-[200px] !h-[35px] xl:-left-10 text-2xl bg-[#FAFAFA] shadowCSS z-50 px-3 tracking-wider rounded flex items-center justify-center top-8"
        >
          <h3 className="text-sm md:p-3 p-2 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
            Our Academics
          </h3>
        </div> */}
        <div className="absolute top-[15px] lg:-left-10 -left-2 bg-white shadowCSS px-4 rounded-md">
          <h3 className="text-base md:p-2 p-1 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
            Our Academics
          </h3>
        </div>
        <div className="contentsAc w-full flex flex-col xl:flex-row items-center justify-around mt-[100px]">
          <div className="ACLeft lg:w-[80%] xl:w-[50%] h-full xl:-mt-[100px]">
            <p className="text-base px-4 text-justify">
              {FinalData[0] && FinalData[0].blog_post.length > 600 ? (
                // <Link
                //   to={`/api/v1/public/blog/get-blog?id=${
                //     FinalData[0] && FinalData[0].id
                //   }`}
                //   className="text-black !text-justify pl-0 md:pl-4 extraLineCSSForQuote"
                // >
                <Link
                  to={`/blogs/filter/${FinalData[0] && FinalData[0].id}`}
                  className="text-black !text-justify pl-0 md:pl-4 extraLineCSSForQuote"
                >
                  <span
                    className="AboutGNCCONTENT"
                    dangerouslySetInnerHTML={{
                      __html: FinalData[0]
                        ? FinalData[0].blog_post.slice(0, 700) + "..."
                        : "",
                    }}
                  />
                </Link>
              ) : (
                <span
                  className="AboutGNCCONTENT"
                  dangerouslySetInnerHTML={{
                    __html: FinalData[0] ? FinalData[0].blog_post : "",
                  }}
                />
              )}
            </p>
          </div>

          <div className="ACRight ml-10 md:ml-0 w-[300px] md:w-[472px] h-[450px] relative xl:-mt-20">
            <div className="hexagone hover:scale-105 duration-300 cursor-pointer absolute md:top-1 top-2 md:right-[129px] right-[80px] text-[#3e6cb1]">
              <div className="titleAC absolute z-30 text-white right-[24px] font-bold top-[15px] md:top-[25px] w-[70%] flex items-center justify-center text-center">
                (BBM)
              </div>
              <div className="disAC text absolute z-30 text-white right-[15px] w-[80%] flex items-center justify-center text-center m-auto top-[45px] md:top-[50px] text-[13px] md:text-sm">
                {" "}
                Bachelor of Business Management
              </div>
            </div>
            <div className="hexagone hover:scale-105 duration-300 cursor-pointer m-auto absolute top-[90px] md:left-[55px] -left-[50px] text-[#c78d37]">
              <div className="titleAC absolute z-30 text-white right-[24px] font-bold top-[15px] md:top-[25px] w-[70%] flex items-center justify-center text-center">
                (BBS)
              </div>
              <div className="disAC text absolute z-30 text-white right-[15px] w-[80%] flex items-center justify-center text-center m-auto top-[45px] md:top-[50px] text-[13px] md:text-sm">
                Bachelor of Business Studies
              </div>
            </div>
            <div className="hexagone h-[200px] w-[200px] hover:scale-105 duration-300 cursor-pointer m-auto absolute top-[92px] md:right-[32px] -right-[10px] text-[#5f5f5f]">
              <div className="titleAC absolute z-30 text-white right-[24px] font-bold top-[15px] md:top-[25px] w-[70%] flex items-center justify-center text-center">
                (BSW)
              </div>
              <div className="disAC text absolute z-30 text-white right-[14px] w-[80%] flex items-center justify-center text-center m-auto top-[45px] md:top-[50px] text-[13px] md:text-sm">
                Bachelor of Social Work
              </div>
            </div>
            <div className="hexagone hover:scale-105 duration-300 cursor-pointer m-auto absolute md:top-[160px] top-[154px] md:right-[150px] right-[100px] text-[#448796]">
              <div className="titleAC absolute z-30 text-white right-[24px] font-bold to5-[15px] md:top-[20px] w-[70%] flex items-center justify-center text-center">
                (TU)
              </div>
              <div className="disAC absolute z-30 text-white right-[13px] w-[85%] flex items-center justify-center text-center m-auto top-[45px] md:top-[50px] text-[13px] md:text-sm">
                {" "}
                Tribhuwan University <br /> (Affliated)
              </div>
            </div>
            <div className="hexagone hover:scale-105 duration-300 cursor-pointer absolute bottom-[90px] md:bottom-[72px] md:right-[11px] -right-[30px] text-[#74aa4a]">
              <div className="titleAC absolute z-30 text-white right-[24px] font-bold top-[15px] md:top-[25px] w-[70%] flex items-center justify-center text-center">
                (BCA)
              </div>
              <div className="disAC text absolute z-30 text-white right-[13px] w-[85%] flex items-center justify-center text-center m-auto top-[45px] md:top-[50px] text-[13px] md:text-sm">
                Bachelor of Computer Application
              </div>
            </div>
            <div className="hexagone hover:scale-105 duration-300 cursor-pointer absolute bottom-[29px] md:bottom-[4px] md:right-[130px] right-[80px] text-[#a84242]">
              <div className="titleAC absolute z-30 text-white right-[24px] font-bold top-[15px] md:top-[25px] w-[70%] flex items-center justify-center text-center">
                (MBS)
              </div>
              <div className="disAC text absolute z-30 text-white right-[14px] w-[80%] flex items-center justify-center text-center m-auto top-[45px] md:top-[50px] text-[13px] md:text-sm">
                {" "}
                Master In Business Studies
              </div>
            </div>

            <svg
              style={{ visibility: "hidden", position: "absolute" }}
              width="0"
              height="0"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
            >
              <defs>
                <filter id="round">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation="5"
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    mode="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                    result="goo"
                  />
                  <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Academics;
