import PreRegistrationContext from "./PreRegistrationContext";
import React, { useState } from "react";
import axios from "axios";
import Domain from "../Domain";
import { toast } from "react-toastify";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

const PreRegistrationState = (props) => {
  const DomainUrl = Domain();
  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState(false);
  // for PreRegistration get request
  const getPreRegistration = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        // `${DomainUrl}api/v1/pre-registration/pre-registration`,
        `${DomainUrl}api/v1/public/public/pre-registration`,
        { headers }
      );
      const data = await response.data;
      setData(data);
    } catch (e) {}
  };

  const PostRegistration = async (data) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}api/v1/pre-registration/pre-registration`,
        data,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Registrated successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.email) {
        toast.error(
          <div className="text-[14px]">{e.response.data.email[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else if (e.response.data.mobile_num) {
        toast.error(
          <div className="text-[14px]">{e.response.data.mobile_num[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  //   for delete preregistration
  const deletePreRegistration = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        // `${DomainUrl}api/v1/pre-registration/pre-registration/${id}`,
        `${DomainUrl}api/v1/public/public/pre-registration/${id}`,
        { headers }
      );
      if (response.status) {
        setRenderData(!renderData);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Pre Registation successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.email) {
        toast.error(
          <div className="text-[14px]">{e.response.data.email[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else if (e.response.data.mobile_num) {
        toast.error(
          <div className="text-[14px]">{e.response.data.mobile_num[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  return (
    <PreRegistrationContext.Provider
      value={{
        data,
        getPreRegistration,
        deletePreRegistration,
        renderData,
        setData,
        setRenderData,
        PostRegistration,
      }}
    >
      {props.children}
    </PreRegistrationContext.Provider>
  );
};

export default PreRegistrationState;
