import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import "../../CSS/Home.css";
import NoticeModal from "../notices/NoticeModal";

const Notice = () => {
  const { noticePublicApi, noticedata } = useContext(PublicApiContext);
  const dataNotice = noticedata;

  useEffect(() => {
    noticePublicApi();
    // eslint-disable-next-line
  }, []);
  const [datarev, setDatarev] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const filterData = datarev.slice(0, 7);
  const [ModalId, setModalId] = useState(0);

  useEffect(() => {
    let actData = dataNotice.length > 0 ? dataNotice.reverse() : [];
    setDatarev(actData);
  }, [dataNotice]);

  const OpenRef = useRef();
  const cardclick = (id) => {
    setShowModal(true);
    setModalId(id);
    setTimeout(() => {
      setShowModal(false);
    }, 2500);
  };

  useEffect(() => {
    if (showModal) {
      OpenRef.current.click();
    }
  }, [showModal]);
  const ModalData = noticedata.filter((e) => e.id === ModalId);

  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        className="absolute top-[200px] right-[80px] h-screen z-50  hidden lg:block"
      >
        <div className="noticeCatd max-h-[850px] w-[398px] overflowx-hidden scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-slate-400 overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full bg-white	bg-opacity-75 rounded-md shadowCSS pb-3">
          <div className="h-[55px] w-full bg-[#454444]  cursor-pointer text-white flex justify-between pl-10 capitalize items-center tracking-wider text-xl z-10 absolute -ml-5 mt-3 rounded shadow">
            <Link className="font-normal text-[20px] tracking-[1px] hover:text-slate-200">
              Notice
            </Link>
          </div>
          <div className="px-[20px]  bg-transparent bg-opacity-60 mt-[80px]">
            {filterData.map((e) => {
              return (
                <div
                  className="eachContent hover:cursor-pointer duration-300 pt-1 border-b border-[#454444] border-opacity-50 !pb-[20px]"
                  key={e.id}
                >
                  <Link
                    // to={`/notice/?id=${e.id}&section=notice`}
                    onClick={() => {
                      cardclick(e.id);
                    }}
                    className="hover:text-slate-700"
                  >
                    <div className="noticeHead font-[600] text-sm tracking-wide capitalize mb-2">
                      {e.notice_name}:
                    </div>
                    <div className="heading">
                      {e.notice_post.length > 79 ? (
                        <span
                          className="AboutGNCCONTENT !text-sm"
                          dangerouslySetInnerHTML={{
                            __html: e.notice_post.slice(0, 79) + "...",
                          }}
                        />
                      ) : (
                        <span
                          className="AboutGNCCONTENT !text-sm"
                          dangerouslySetInnerHTML={{ __html: e.notice_post }}
                        />
                      )}
                    </div>
                    <div className="date text-sm tracking-wide float-right -mb-2">
                      {" "}
                      Date: {e.created_date_bs}
                    </div>
                  </Link>
                </div>
              );
            })}
            <Link
              className=" mt-2 -mb-2 flex items-center justify-center gap-1 hover:text-slate-600"
              to={"/notices"}
            >
              {" "}
              <AiFillEye className="text-xl" /> View More{" "}
            </Link>
          </div>
        </div>
      </div>
      <NoticeModal
        OpenRef={OpenRef}
        ModalData={ModalData}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default Notice;
