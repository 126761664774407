import React, { useEffect, useState } from "react";
import { useContext } from "react";
import "./Contact.css";
import AOS from "aos";
import "aos/dist/aos.css";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
AOS.init();

const Contact = () => {
  const { PostContact } = useContext(PublicApiContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "GNC-Contact";
  }, []);

  //for error message
  const [error, setError] = useState({
    name: "",
    address: "",
    phnumber: "",
    email: "",
    message: "",
  });

  const [initialContact, setInitialContact] = useState({
    name: "",
    address: "",
    phnumber: "",
    email: "",
    message: "",
  });
  const inpchange = (e) => {
    if (e.target.name === "phnumber") {
      if (e.target.value.length <= 10) {
        setInitialContact({
          ...initialContact,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setInitialContact({ ...initialContact, [e.target.name]: e.target.value });
    }
  };
  const contactHandelSubmit = (e) => {
    e.preventDefault();
    //for error logic
    if (
      initialContact.name.toString().length < 2 ||
      initialContact.name.toString().length > 30 ||
      !isNaN(initialContact.name)
    ) {
      setError({
        name: "Name must be 2-30 character.",
        address: "",
        phnumber: "",
        email: "",
        message: "",
      });
    } else if (
      initialContact.address.toString().length < 3 ||
      initialContact.address.toString().length > 40
    ) {
      setError({
        name: "",
        address: "Address must be 3-40 character.",
        phnumber: "",
        email: "",
        message: "",
      });
    } 
     else if (
      initialContact.phnumber.toString().length < 10
    ) {
      setError({
        name: "",
        address: "",
        phnumber: "Please Enter a Valid phone number",
        email: "",
        message: "",
      });
    } 
     else if (
      initialContact.phnumber.toString().substring(0,2)!=="98" && initialContact.phnumber.toString().substring(0,2)!=="97"
    ) {
      setError({
        name: "",
        address: "",
        phnumber: "Phone Number must start with '97' or '98'",
        email: "",
        message: "",
      });
    } 
    else if (initialContact.email.toString().length < 5) {
      setError({
        name: "",
        address: "",
        phnumber: "",
        email: "Please Enter a Valid Email address",
        message: "",
      });
    } else if (
      initialContact.message.toString().length < 10 ||
      initialContact.message.toString().length > 500
    ) {
      setError({
        name: "",
        address: "",
        phnumber: "",
        email: "",
        message: "Message must be 10-500 character",
      });
    } else {
      setError({ name: "", address: "", phnumber: "", email: "", message: "" });
      const FinalData = {
        ...initialContact,
        phone_no: initialContact.phnumber,
      };
      setInitialContact({
        name: "",
        address: "",
        phnumber: "",
        email: "",
        message: "",
      });
      PostContact(FinalData);
    }
  };
  return (
    <div data-aos="zoom-out" id="Contact">
      <div className="contentSection">
        <div id="ContactForm" className="mt-4 shadowCSS">
          <form action="#" onSubmit={contactHandelSubmit}>
            <div className="Contactsection">
              <h2 className="font-bold mb-2 text-2xl m-auto">Contact</h2>
              <label className="cursor-pointer" htmlFor="name">
                Name:
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter your name.."
                value={initialContact.name}
                onChange={inpchange}
                className="border border-gray-500"
              />
              <p className="Contacterrormsg">{error.name}</p>
            </div>

            <div className="Contactsection">
              <label className="cursor-pointer" htmlFor="address">
                Address:
              </label>
              <input
                type="text"
                name="address"
                id="address"
                placeholder="Enter your Address.."
                value={initialContact.address}
                onChange={inpchange}
                className="border border-gray-500"
              />
              <div className="Contacterrormsg">{error.address}</div>
            </div>

            <div className="Contactsection">
              <label className="cursor-pointer" htmlFor="phnumber">
                Phone Number:
              </label>
              <input
                type="number"
                name="phnumber"
                id="phnumber"
                placeholder="Enter your phone number (980-000-0000).."
                value={initialContact.phnumber}
                onChange={inpchange}
                className="border border-gray-500"
              />
              <div className="Contacterrormsg">{error.phnumber}</div>
            </div>

            <div className="Contactsection">
              <label className="cursor-pointer" htmlFor="email">
                E-mail:
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter Your E-mail.."
                value={initialContact.email}
                onChange={inpchange}
                className="border border-gray-500"
              />
              <div className="Contacterrormsg">{error.email}</div>
            </div>

            <div className="Contactsection">
              <label className="cursor-pointer" htmlFor="message">
                Message:
              </label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Your message.."
                value={initialContact.message}
                onChange={inpchange}
                className="border border-gray-500 resize-none"
              ></textarea>
              <div className="Contacterrormsg">{error.message}</div>
            </div>
            <div className="Contactsection">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
