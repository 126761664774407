import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "@mui/material";
import { useRef } from "react";
import NoticeModal from "./NoticeModal";
import { BsFillBookmarkFill } from "react-icons/bs";
import { useContext } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const Cards = (props) => {
  const { noticePublicApi, noticedata } = useContext(PublicApiContext);
  const dataNotice = noticedata;
  const data = dataNotice;
  useEffect(() => {
    noticePublicApi();
    // eslint-disable-next-line
  }, []);

  const [datarev, setDatarev] = useState([]);
  useEffect(() => {
    let actData = data.length > 1 ? data.reverse() : [];
    setDatarev(actData);
  }, [data]);

  const paginationValue = 12;
  const [pagValue, setPagValue] = useState(1);
  const totalData = datarev.length;
  const totalPage = Math.ceil(totalData / paginationValue);
  const OpenRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [ModalId, setModalId] = useState(0);
  const cardclick = (id) => {
    setShowModal(true);
    setModalId(id);
    setTimeout(() => {
      setShowModal(false);
    }, 2500);
  };
  useEffect(() => {
    if (showModal) {
      OpenRef.current.click();
    }
  }, [showModal]);

  const ModalData = data.filter((e) => e.id === ModalId);

  return (
    <div className="md:p-5 p-1 flex justify-center md:justify-start flex-wrap gap-4 w-[87%]">
      {datarev
        .slice((pagValue - 1) * paginationValue, pagValue * paginationValue)
        .map((data) => {
          return (
            <div
              onClick={() => {
                cardclick(data.id);
              }}
              data-aos="fade-down"
              className="w-[1/4]"
              key={data.id}
            >
              <div className="max-w-sm teacherCard rounded overflow-hidde w-[300px] shadowCSS cursor-pointer hover:scale-105 duration-500">
                <Link>
                  <div className="details bg-[#ffffff59] pb-5 text-black rounded relative">
                    <div className="px-6 py-3 pb-1">
                      <div className="font-bold text-base mb-1 capitalize text-[#5c5c5c] flex gap-1 items-center">
                        <BsFillBookmarkFill className="text-sm scale-105" />
                        {data.notice_name.slice(0, 75)}
                      </div>
                      <p className="text-[#5c5c5c] text-sm">
                        {data.notice_post.length > 33 ? (
                          <span
                            className="catd"
                            dangerouslySetInnerHTML={{
                              __html: data.notice_post.substring(0, 65) + "...",
                            }}
                          />
                        ) : (
                          <span
                            className="catd"
                            dangerouslySetInnerHTML={{
                              __html: data.notice_post,
                            }}
                          />
                        )}
                      </p>
                    </div>
                    <div className="float-right pr-5 text-sm absolute bottom-2 right-2 text-[#5c5c5c]">
                      <span className="">Date</span>:{data.created_date_bs}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      <div className="w-full mt-3 flex justify-end">
        {datarev.length > 4 ? (
          <Pagination
            count={totalPage}
            variant="outlined"
            shape="rounded"
            onChange={(e, value) => setPagValue(value)}
          />
        ) : (
          <></>
        )}
      </div>

      <NoticeModal
        OpenRef={OpenRef}
        ModalData={ModalData}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default Cards;
