import React from "react";
// import { AiFillMail } from "react-icons/ai";
// import { FaGraduationCap } from "react-icons/fa";

const Cards = (props) => {
  const { data } = props;
  return (
    <div>
      <div
        data-aos="fade-down"
        className="max-w-sm lg:w-[280px] w-[260px] teacherCard rounded overflow-hidden shadowCSS"
      >
        <div className="w-full h-[220px] overflow-hidden">
          <img
            className="opacity-80  w-full hover:scale-105 h-full cursor-pointer object-cover duration-200"
            src={data.image}
            alt="Teachers"
            loading="lazy"
          />
        </div>
        <div className="details bg-[#e5e7eb]">
          <div className="px-6 py-3 pb-1">
            <div className="font-bold text-base mb-1 text-center capitalize">
              {data.name}
            </div>
          </div>
        </div>
        {/* <div className="pb-3 bg-[#e5e7eb] flex items-start justify-start w-[100%] px-4 m-auto flex-col">
          <span className="bg-gray-200 rounded-full px-3 py-1 text-xs scale-105 font-semibold text-gray-700 mr-2 mb-2 flex gap-2 tracking-wider items-center justify-center shadow">
            <FaGraduationCap className="text-sm scale-110" />
            {data.qualification}
          </span>
          <span className="bg-gray-200 rounded-full px-3 py-1 text-xs scale-105 font-semibold text-gray-700 mr-2 mb-2 flex gap-2 tracking-wider items-center justify-center shadow">
            <AiFillMail className="text-sm scale-100" />
            {data.email.substring(0, 25)}
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default Cards;
