import { useContext, useRef } from "react";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useState } from "react";
import { ImCross } from "react-icons/im";
import { useEffect } from "react";
import "../css/modal.css";
import NewCategoryContext from "../../../context/NewCategory/NewCategoryContext";
import { AiOutlineCaretDown } from "react-icons/ai";
import { toast } from "react-toastify";

export default function Modal(props) {
  const {
    getNewCategory,
    newCatdata,
    postNewCategories,
    postNewsubCategories,
    postNewsubsubCategories,
    renderData,
    updateNewCategory,
  } = useContext(NewCategoryContext);
  const { setAddBtnClk, btnNameID, filteredData, addBtnClk } = props;
  const { btnName, id } = btnNameID;
  const [inpfld, setInpfld] = useState({
    name: btnName === "UpdateBtn" ? filteredData[0].category_name : "",
    display_order: "",
    active: true,
  });
  const [checkedBox, setCheckedBox] = useState(true);
  const [num, setNum] = useState(
    btnName === "UpdateBtn" ? filteredData[0].display_order : ""
  );
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });
  const ref = useRef();
  const [noneCat, setNoneCat] = useState(
    filteredData[0]
      ? { notation: -1, name: filteredData[0].category_name }
      : { notation: "", name: "" }
  );
  const [category, setCategory] = useState({ notation: "", name: "" });
  const [subCategory, setSubCategory] = useState({ notation: "", name: "" });

  const [showName, setShowName] = useState("");

  useEffect(
    (e) => {
      getNewCategory();
    },
    // eslint-disable-next-line
    [renderData]
  );

  useEffect(() => {
    if (addBtnClk) {
      ref.current.click();
    }
    // eslint-disable-next-line
  }, []);

  // make checkbox workable
  const ChecKBoxChange = (e) => {
    let isChecked = e.target.checked;
    setCheckedBox(isChecked);
  };
  const inpfldFill = (e) => {
    setInpfld({ ...inpfld, [e.target.name]: e.target.value });
  };
  const handleChange1 = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setNum(e.target.value);
    }
  };
  const addCategoryBtnClk = (e) => {
    e.preventDefault();
    if (btnName === "UpdateBtn" && newCatdata.length >= 11) {
      toast.error("You can only enter 11 category");
      return;
    }
    if (
      noneCat.name.length < 1 &&
      category.name.length < 1 &&
      subCategory.name.length < 1
    ) {
      setErrorMsg({
        status: true,
        msg: "Please Check Category First",
        position: "category",
      });
    } else if (inpfld.name.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Category Name",
        position: "name",
      });
    } else if (num === "") {
      setErrorMsg({
        status: true,
        msg: "Please Enter Display Order",
        position: "dOrder",
      });
    } else if (num > 10) {
      setErrorMsg({
        status: true,
        msg: "Number should be Less Than 11",
        position: "dOrder",
      });
    } else if (num < 1) {
      setErrorMsg({
        status: true,
        msg: "Number should be Greater Than 0",
        position: "dOrder",
      });
    } else {
      setErrorMsg({ status: false, msg: "", position: "" });
      if (btnName === "UpdateBtn") {
        if (noneCat.notation === -1) {
          const postData = {
            ...inpfld,
            active: checkedBox,
            display_order: num,
            category_key: category.notation,
            category: category.notation,
            category_name: inpfld.name,
          };
          updateNewCategory(postData, id);
        } else {
          const postData = {
            ...inpfld,
            active: checkedBox,
            display_order: num,
            category_key: category.notation,
            category: category.notation,
            category_key_name: inpfld.name,
          };
          postNewsubCategories(postData);
        }
      } else {
        // check lowest first
        if (subCategory.notation !== "") {
          const postData = {
            ...inpfld,
            active: checkedBox,
            display_order: num,
            category_key: category.notation,
            category: category.notation,
            sub_category_key: subCategory.notation,
            category_key_id: subCategory.notation,
            sub_category_key_name: inpfld.name,
          };
          postNewsubsubCategories(postData);
        } else {
          if (category.notation !== "") {
            const postData = {
              ...inpfld,
              active: checkedBox,
              display_order: num,
              category_key: category.notation,
              category: category.notation,
              category_key_name: inpfld.name,
            };
            postNewsubCategories(postData);
          } else {
            if (noneCat.notation === -1) {
              const postData = {
                ...inpfld,
                category_name: inpfld.name,
                display_order: num,
              };
              postNewCategories(postData);
            }
          }
        }
      }
      setInpfld({ name: "", display_order: "", active: true });
      setNum("");
      setAddBtnClk(false);
      ref.current.click();
    }
  };

  const nonebtnclick = (e) => {
    e.stopPropagation();
    const notation = e.target.value;
    const name = e.target.getAttribute("name");
    setNoneCat({ notation, name });
    setShowName(name);
    setSubCategory({ notation: "", name: "" });
    setCategory({ notation: "", name: "" });
    btnRef.current.click();
  };
  const categoryCLk = (e) => {
    e.stopPropagation();
    const notation = e.target.value;
    const name = e.target.getAttribute("name");
    setCategory({ notation, name });
    setSubCategory({ notation: "", name: "" });
    setShowName(name);
    btnRef.current.click();
  };
  const subCategoryClk = (e) => {
    e.stopPropagation();
    const notation = e.target.value;
    const name = e.target.getAttribute("name");
    setSubCategory({ notation, name });
    setShowName(name);
    btnRef.current.click();
  };

  const btnRef = useRef();

  return (
    <>
      <button
        type="button"
        className="btn btn-primary d-none "
        ref={ref}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      ></button>
      <div
        className="modal fade mt-[80px]"
        id="exampleModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form className="modal-content pl-7">
            <div className="modal-header relative py-4">
              <h5
                className="modal-title tracking-wider absolute top-5 text-lg text-black font-semibold"
                id="exampleModalLabel"
              >
                Create Category
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setAddBtnClk(false)}
              />
            </div>
            <div className="dropdown dropdownbtn">
              <div
                className="btn btn-secondary dropdownbtn relative !pt-[10px]"
                id="dropdownMenuButton1"
                ref={btnRef}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {btnName === "UpdateBtn"
                  ? showName || "None"
                  : showName === ""
                  ? "--Select Category--"
                  : showName}
                <AiOutlineCaretDown className="absolute right-3 top-5" />
              </div>

              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li
                  className="category"
                  onClick={nonebtnclick}
                  value={"-1"}
                  name="None"
                >
                  None
                </li>
                {btnName !== "UpdateBtn" ? (
                  newCatdata.map((e) => {
                    return (
                      <li
                        className="category"
                        key={e.id}
                        value={e.id}
                        name={e.category_name}
                        onClick={categoryCLk}
                      >
                        {e.category_name}
                        <ul className="subcategory shadow">
                          {e.category_key.map((e) => {
                            return (
                              <li
                                className="bg-[#fff]"
                                key={e.id}
                                name={e.category_key_name}
                                onClick={subCategoryClk}
                                value={e.id}
                              >
                                {e.category_key_name}
                                <ul className="subsubcategory">
                                  {e.sub_category_key.map((e) => {
                                    return (
                                      <li
                                        className="bg-[#fff]"
                                        key={e.id}
                                        name={e.sub_category_key_name}
                                        value={e.id}
                                      >
                                        {e.sub_category_key_name}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })
                ) : (
                  <></>
                )}
              </ul>
            </div>
            {errorMsg.position === "category" ? (
              <p className="text-red-500 text-xs ml-6 -mt-1">
                **{errorMsg.msg}**
              </p>
            ) : (
              ""
            )}

            <div className="modal-body flex items-center">
              <TextField
                label="Category Name*"
                name="name"
                value={inpfld.name}
                onChange={inpfldFill}
                variant="outlined"
                className=" compulsory w-[400px]"
              />
            </div>
            {errorMsg.position === "name" ? (
              <p className="text-red-500 text-xs ml-6 -mt-4">
                **{errorMsg.msg}**
              </p>
            ) : (
              ""
            )}
            <div className="modal-body flex items-center">
              <TextField
                label="Display-Order*"
                name="display_order"
                value={num}
                onChange={handleChange1}
                variant="outlined"
                className="w-[400px]"
              />
            </div>
            {errorMsg.position === "dOrder" ? (
              <p className="text-red-500 text-xs ml-6 -mt-4">
                **{errorMsg.msg}**
              </p>
            ) : (
              ""
            )}
            <div className="modal-body flex items-center -mt-4">
              {btnName === "UpdateBtn" ? (
                <>
                  {filteredData[0].active ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => ChecKBoxChange(e)}
                        />
                      }
                      label="Category Active"
                      className="text-gray-500 tracking-wider"
                    />
                  ) : (
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => ChecKBoxChange(e)} />}
                      label="Category Active"
                      className="text-gray-500 tracking-wider"
                    />
                  )}
                </>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(e) => ChecKBoxChange(e)}
                    />
                  }
                  label="Category Active"
                  className="text-gray-500 tracking-wider"
                />
              )}
            </div>
            <div className="modal-footer mr-[32px]">
              <Button
                type="submit"
                variant="contained"
                onClick={addCategoryBtnClk}
                className="!capitalize"
              >
                {id === -1 ? "Create" : `Update Category`}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
