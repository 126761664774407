import axios from "axios";
import React from "react";
import FooterContext from "./FooterContext";
import Domain from "../Domain";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";


const FooterState = (props) => {
  const DomainUrl = Domain();
  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState(false);
  // for category get request
  const getFooter = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}api/v1/footer/organization-setup`,
        { headers }
      );
      const data = await response.data;
      setData(data);
    } catch (e) {}
  };
  // for footer post request
  const postSub_Categories = async (data) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}api/v1/footer/organization-setup`,
        data,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Footer successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.name) {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.address) {
        toast.error(
          <div className="text-[14px]">{e.response.data.address[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.slogan) {
        toast.error(
          <div className="text-[14px]">{e.response.data.slogan[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  //   for delete footer
  const deleteFooter = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}api/v1/footer/organization-setup/${id}`,
        { headers }
      );
      if (response.status) {
        setRenderData(!renderData);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Footer successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      toast.error(<div className="text-[14px]">An error occured!</div>, {
        icon: <BiErrorCircle className="text-xl " />,
      });
    }
  };

  //for update footer
  const UpdateFooter = async (data, id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}api/v1/footer/organization-setup/${id}`,
        data,
        { headers }
      );
      if (res.status) {
        setRenderData(!renderData);
        toast.success(
          <div className="deleteToast text-[15px]">
            Footer successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.name) {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.address) {
        toast.error(
          <div className="text-[14px]">{e.response.data.address[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.slogan) {
        toast.error(
          <div className="text-[14px]">{e.response.data.slogan[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
      else{
        toast.error(
          <div className="text-[14px]">An error accuired!</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  return (
    <>
      <FooterContext.Provider
        value={{
          getFooter,
          data,
          postSub_Categories,
          deleteFooter,
          UpdateFooter,
          renderData,
        }}
      >
        {props.children}
      </FooterContext.Provider>
    </>
  );
};

export default FooterState;
