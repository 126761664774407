import React, { useContext, useEffect, useState } from "react";
import "../Components/CSS/Navigation.css";
import { Link, useLocation } from "react-router-dom";
import Logo from "../Components/Images/navlogo.png";
import {
  AiFillInstagram,
  AiFillUpSquare,
  AiOutlineDown,
  AiTwotoneLock,
} from "react-icons/ai";
import { BsFacebook, BsTwitter, BsLinkedin } from "react-icons/bs";
import { IoIosArrowForward, IoMdCall } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { useRef } from "react";
import "aos/dist/aos.css";
import PublicApiContext from "../../context/PublicAPI/PublicApiContext";
import ImgLogo from "../Common/Image/gncLogo.png";

const Navigation = () => {
  const { newCategoryPublicApi, newCategorydata } =
    useContext(PublicApiContext);
  const newCatdata = newCategorydata;

  const Location = useLocation().pathname;
  useEffect(() => {
    newCategoryPublicApi();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const ref = useRef();
  const replaceLink = (link) => {
    let Link = link.replace(/ /g, "-");
    let exactLink = Link.toLowerCase();
    return exactLink;
  };
  const Finaldata =
    newCatdata.length > 1 ? newCatdata.filter((e) => e.active) : [];
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [smallNavclose, setSmallNavClose] = useState(false);

  // to get window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    function onScroll() {
      setScrollY(window.scrollY);
    }

    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (Math.ceil(windowWidth) < 1020) {
      if (smallNavclose) {
        ref.current.click();
      }
    }
  }, [smallNavclose, windowWidth]);

  const hamberclk = () => {
    setShowMediaIcons((p) => !p);
    setSmallNavClose(false);
  };

  const mouseHover = (id, dat) => {
    const elment = document.querySelectorAll(`.dropdown${id} ul .subCat`);
    const elm = Array.from(elment);
    for (let i = 0; i < elm.length; i++) {
      if (dat > 0) {
        const element = elm[dat - 1];
        element.style.cssText = "padding-left: 18px !important;";
        element.style.cssText = "padding: 7px 18px !important;";
        element.style.width = "100%";
      }
    }
  };
  const mouseOut = (id) => {
    const elment = document.querySelectorAll(".dropdown-content ul a");
    const elm = Array.from(elment);
    for (let i = 0; i < elm.length; i++) {
      if (id > 0) {
        const element = elm[i];
        element.style.cssText = "padding-left: 10px !important;";
        element.style.width = "90%";
      }
    }
  };

  const mouseHoverSubSubCat = (id, ind) => {
    const elment = document.querySelectorAll(`.dropdownSub${id} .subsubCat`);
    const elm = Array.from(elment);
    for (let i = 0; i < elm.length; i++) {
      if (ind > 0) {
        const element = elm[ind - 1];
        element.style.cssText = "padding-left: 18px !important;";
        element.style.width = "100%";
        const elem = elm[ind];
        elem.style.cssText = "padding-left: 18px !important;";
        elem.style.width = "100%";
      }
    }
  };
  const mouseHoversubnav = (id, dat) => {
    const elment = document.querySelectorAll(`.dropdown${id} ul .subCat`);
    const elm = Array.from(elment);
    for (let i = 0; i < elm.length; i++) {
      if (dat > 0) {
        const element = elm[dat - 1];
        element.style.cssText = "padding-left: 16px !important;";
        element.style.width = "100%";
      }
    }
  };

  return (
    <>
      <div id="uppNav" style={showMediaIcons ? { position: "absolute" } : {}}>
        <div
          className={`w-[100%] relative flex justify-around items-start text-slate-500`}
        >
          <div></div>
          <div className="TNavClzLink flex items-center ml-[80px] justify-center md:pl-4 relative ">
            <div className="absolute right-2 top-[8px] lg:top-[5px]">
              <a
                href="https://mail.google.com/mail/u/0/#inbox?compose=new"
                target="blank"
                className="flex hover:text-black justify-center items-center h-full text-sm lg:text-base"
              >
                <MdEmail />
                <p className="ml-1">info@gnc.edu.np</p>
              </a>
            </div>
            <div className="w-[1px] h-[40px] bg-slate-400 "></div>
          </div>
          <div className=" py-2 px-1 hover:text-black md:px-5 hidden md:block">
            <div className="flex justify-center items-center">
              <MdLocationPin className="text-lg hidden md:block" />
              <a
                href="https://www.google.com/maps/place/Greenfield+National+College/@27.7044563,85.2832174,15z/data=!4m5!3m4!1s0x0:0x56d62869f1ee2301!8m2!3d27.7044563!4d85.2832174"
                target="blank"
                className="hover:text-black text-base"
              >
                <p className="hidden md:block"> Find us on Google map</p>
                {/* <p className='block md:hidden lg:hidden'> Map</p> */}
              </a>
            </div>
          </div>
          <div className="w-[1px] h-[40px] bg-slate-400 hidden md:block "></div>
          <div className="contact px-1 hover:text-black md:px-5 py-2 hidden md:block">
            <div className="flex items-center justify-center">
              <IoMdCall className="text-[20px] mr-1 hidden md:block mb-1" />
              <p className="hidden md:block text-[15px] ">
                {" "}
                Call: 01-5237317, 01-5237352, (+977) 9851078391{" "}
              </p>
            </div>
          </div>
          <div className="w-[1px] h-[40px] bg-slate-400 hidden md:block "></div>
          <div className="flex flex-col w-[20px] lg:w-auto hover:text-black items-center justify-center gap-1 pt-2 md:px-4 py-2 my-auto">
            {/* <p>Follow us @:</p> */}
            <div className="logos flex gap-2 ml-0 lg:-ml-4">
              <a
                href="https://www.facebook.com/greenfieldcollegenepal"
                target="blank"
                className="scale-110"
              >
                <BsFacebook className="text-slate-500 hover:text-black" />
              </a>
              <a
                href="https://twitter.com/"
                target="blank"
                className="scale-125"
              >
                {/* <AiFillInstagram className='text-[#bc1888]'/> */}
                <AiFillInstagram className="text-slate-500 hover:text-black" />
              </a>
              <a
                href="https://www.instagram.com/"
                target="blank"
                className="scale-105"
              >
                <BsLinkedin className="text-slate-500 hover:text-black" />
              </a>
              <a
                href="https://www.facebook.com/messages/t/1866373626778452"
                target="blank"
                className="scale-110"
              >
                <BsTwitter className="text-slate-500 hover:text-black" />
              </a>
            </div>
          </div>
          <div className="hidden lg:block">
            <div className="flex items-center justify-center mr-[50px] md:px-4 px-1 relative">
              <div className="w-[1px] h-[40px] bg-slate-400"></div>
              <div className="absolute hover:text-black left-2 top-[8px]">
                <Link
                  to="/admin/login"
                  className="hover:text-gray-600"
                  target="blank"
                >
                  <div className="flex">
                    <AiTwotoneLock className="mt-[3px]" />
                    Login
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center lg:hidden mr-[50px] md:px-4 px-1 relative">
            <div className="w-[1px] h-[40px] bg-slate-400"></div>
            <div className="absolute hover:text-black flex left-2 top-[10px]">
              <IoMdCall className="mt-[1px]" />
              <div className="flex text-sm">015237317</div>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      {!showMediaIcons ? <hr /> : ""}
      {Location === "/" ? (
        <div className=" contact hidden flex-col z-10 lg:block py-1">
          <Link to="/">
            <img
              src={Logo}
              alt=""
              className="h-[100px] w-[120px] capitalize hidden md:block m-auto"
              loading="lazy"
            />
          </Link>
        </div>
      ) : (
        ""
      )}

      <div id="navigation" style={{ position: "sticky" }}>
        <nav className="relative">
          <img
            src={ImgLogo}
            className="w-[130px] imgHid left-3 h-[30px] absolute lg:hidden"
            alt="Logo"
          />
          <div
            id="navcontent"
            className={`flex items-center justify-center h-full shadow lg:relative text-slate-300 ${
              showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
            }`}
          >
            <ul className="responsiveNavigation flex justify-around lg:relative z-50 ">
              <div
                className="flex w-[100%] md:items-center lg:top-0 justify-center z-50 gap-5 pl-[20px] absolute lg:relative !lg:h-[0px]"
                style={
                  !showMediaIcons
                    ? { top: "-150%", height: "0" }
                    : { height: "101vh", zIndex: "20000" }
                }
              >
                {Finaldata.sort((a, b) => a.display_order - b.display_order)
                  .slice(0, 11)
                  .map((e, index) => {
                    return (
                      <li
                        className="nav-item dropdown w-[150px] lg:w-auto"
                        key={e.id}
                      >
                        {e.category_key.length >= 1 &&
                        e.category_name !== "Home" ? (
                          <Link
                            className="flex items-center capitalize gap-1 py-[10px] hover:text-slate-200 text-base"
                            onClick={
                              e.category_key.length >= 1 &&
                              e.category_name !== "Home"
                                ? () => setSmallNavClose("")
                                : () => setSmallNavClose(true)
                            }
                          >
                            {e.category_name}
                            {e.category_key.length >= 1 ? (
                              <>
                                {e.category_name !== "Home" ? (
                                  <AiOutlineDown className="text-[12px]" />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Link>
                        ) : (
                          <Link
                            onClick={
                              e.category_key.length >= 1 &&
                              e.category_name !== "Home"
                                ? () => setSmallNavClose("")
                                : () => setSmallNavClose(true)
                            }
                            to={
                              e.category_name.toLowerCase() === "home"
                                ? `/`
                                : `${replaceLink(e.category_name)}`
                            }
                            className="flex items-center gap-1 py-[10px] capitalize hover:text-slate-200 text-base"
                          >
                            {e.category_name}
                            {e.category_key.length >= 1 ? (
                              <>
                                {e.category_name !== "Home" ? (
                                  <AiOutlineDown className="text-[12px]" />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Link>
                        )}
                        {e.category_key.length >= 1 &&
                        e.category_name !== "Home" ? (
                          <div
                            className={`dropdown-content bg-[#e5e7eb] lg:text-black !w-[150px] lg:!w-[160px] flex pb-1 dropdown${index}`}
                            id="dropdowncontent"
                          >
                            {e.category_key.map((e, dat) => {
                              return (
                                <ul
                                  className={`nav-item subdropdown lg:w-[160px] hover:bg-[#454444] hover:!text-white w-full`}
                                  key={e.id}
                                >
                                  {e.sub_category_key.length >= 1 ? (
                                    <Link
                                      onClick={
                                        e.sub_category_key.length >= 1
                                          ? () => setSmallNavClose("")
                                          : () => setSmallNavClose(true)
                                      }
                                      key={e.id}
                                      onMouseEnter={() =>
                                        mouseHover(index, dat)
                                      }
                                      onMouseOut={() => mouseOut(index, dat)}
                                      className={`hover:bg-[#454444] subCat capitalize hover:text-white py-[6px] pl-2 hover:pl-4 text-left w-[90%] hover:w-[100%] mt-1 m-auto text-sm lg:text-[14px] relative border-b border-[#455444]`}
                                      // id={`dropdown${index}`}
                                    >
                                      {e.category_key_name.slice(0, 30)}
                                      {e.sub_category_key.length >= 1 ? (
                                        <IoIosArrowForward className="absolute right-0 top-[7px] lg:top-[8px]" />
                                      ) : (
                                        <></>
                                      )}
                                    </Link>
                                  ) : (
                                    <Link
                                      onClick={
                                        e.sub_category_key.length >= 1
                                          ? () => setSmallNavClose("")
                                          : () => setSmallNavClose(true)
                                      }
                                      onMouseEnter={() =>
                                        mouseHover(index, dat)
                                      }
                                      onMouseOut={() => mouseOut(index, dat)}
                                      key={e.id}
                                      to={`/${replaceLink(
                                        e.category_name
                                      )}/${replaceLink(e.category_key_name)}`}
                                      className="lg:text-black hover:bg-[#454444] capitalize subCat pl-2 hover:pl-4 text-sm lg:text-[14px] hover:text-white py-[6px] w-[90%]  hover:w-[100%] mt-1 text-left m-auto relative border-b border-[#455444]"
                                      // id={`dropdown${index}`}
                                    >
                                      {e.category_key_name.slice(0, 30)}
                                      {e.sub_category_key.length >= 1 ? (
                                        <IoIosArrowForward className="absolute right-3 top-[10px]" />
                                      ) : (
                                        <></>
                                      )}
                                    </Link>
                                  )}
                                  {e.sub_category_key.length >= 1 ? (
                                    <div
                                      className={`Subdropdown-content bg-[#e5e7eb] !left-[150px] lg:!left-[160px] pb-1 !lg:w-full !w-[100px] text-sm lg:text-base dropdownSub${dat}`}
                                      id="dropdowncontent"
                                    >
                                      {e.sub_category_key.map((e, ind) => {
                                        return (
                                          <li
                                            className="nav-item text-sm lg:text-10px subdropdown flex w-[100%] hover:bg-[#454444] hover:text-white"
                                            key={e.id}
                                          >
                                            <Link
                                              onClick={() =>
                                                setSmallNavClose(true)
                                              }
                                              key={e.id}
                                              to={`/${replaceLink(
                                                e.category_name
                                              )}/${replaceLink(
                                                e.sub_category_name
                                              )}/${replaceLink(
                                                e.sub_category_key_name
                                              )}`}
                                              onMouseEnter={() =>
                                                mouseHoverSubSubCat(dat, ind)
                                              }
                                              onMouseOut={() =>
                                                mouseHoversubnav(dat, ind)
                                              }
                                              className="lg:text-black capitalize subsubCat text-sm lg:text-[14px] py-[6px] px-3 w-[100%] m-auto relative border-b border-black"
                                            >
                                              {e.sub_category_key_name.slice(
                                                0,
                                                20
                                              )}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </ul>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </li>
                    );
                  })}
              </div>
            </ul>
            <img
              src={ImgLogo}
              className="w-[140px] h-[40px] left-3  top-3 z-[10000000000000] imgHid absolute lg:hidden"
              alt="Logo"
            />
          </div>

          <div
            id="hambar"
            className={`iconsrga smallScreen ${
              showMediaIcons ? " top-6" : ""
            } lg:bigscr absolute right-5 z-[10000000000000] cursor-pointer text-xl text-white lg:hidden `}
            onClick={hamberclk}
            ref={ref}
          >
            <GiHamburgerMenu
              className={`${
                !showMediaIcons ? "block !important" : "hidden !important"
              }`}
            />
            <ImCross
              className={`text-base scale-90 ${
                showMediaIcons ? "block !important" : "hidden !important"
              }`}
            />
          </div>
        </nav>
      </div>

      <AiFillUpSquare
        data-aos="fade-left"
        className={`md:h-[30px] activeclsText md:w-[30px] h-[23px] w-[23px] z-[10] fixed bottom-[10%] right-5 cursor-pointer ${
          Math.ceil(scrollY) < 900 ? "hidden" : "block"
        }`}
        onClick={() => window.scrollTo(0, 0)}
      />
    </>
  );
};

export default Navigation;
