import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GoAlert } from "react-icons/go";

export default function DeleteAlert(props) {
  const { open, setOpen, id, deleteFun } = props;

  // for modal open and close provided by MUI
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const agreeBtnClk = () => {
    handleClose();
    deleteFun(id);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen} className="!hidden">
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="px-5"
      >
        <div className="flex justify-center items-center p-3 gap-2">
          <div className="border border-red-600 p-2 h-[40px] w-[40px] mt-3 rounded-full flex items-center justify-center shadow-sm">
            <GoAlert className="text-red-600 pb-[2px]" />
          </div>
          <div className=" flex flex-col justify-start items-start !w-[300px]">
            <DialogTitle
              id="alert-dialog-title"
              className="!font-bold flex justify-center items-center gap-2 mt-1 !px-0 !pb-0"
            >
              <>{"Delete Alert"}</>
            </DialogTitle>
            <DialogContent className="!px-0 !py-1">
              <DialogContentText
                id="alert-dialog-description"
                className=" !-my-1 !px-0 !text-slate-700"
              >
                Are you sure you want to delete this?
              </DialogContentText>
            </DialogContent>
          </div>
        </div>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            className="!capitalize mb-2 !-mt-2 !border-none !bg-slate-200 !font-semibold !text-black"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="!capitalize mb-2 !mr-2 !-mt-2 !text-red-700 !bg-red-200 !font-semibold !border-none"
            onClick={agreeBtnClk}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
