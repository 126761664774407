import React, { useEffect } from "react";
import NoteCatds from "./NoteCatds";

const Notes = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "GNC-Notes";
  }, []);

  return (
    <div>
        <NoteCatds />
    </div>
  );
};

export default Notes;
