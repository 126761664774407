import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ImDownload2 } from "react-icons/im";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const NoteCatds = () => {
  const { notesPublicApi, notesdata, subjectNamePublicApi, subjectNamedata } =
    useContext(PublicApiContext);

  const noteData = notesdata;
  const subjectNameData = subjectNamedata;

  useEffect(() => {
    notesPublicApi();
    subjectNamePublicApi();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.title = "GNC-Contact";
  }, []);

  const dropdownRef = useRef();
  const [streamVal, setStreamVal] = useState({ name: "", id: "" });
  const [semGradeVal, setSemGradeVal] = useState({ name: "", id: "" });
  const [yearGradeVal, setYearGradeVal] = useState({ name: "", id: "" });
  const [searchedValue, setSearchedValue] = useState("");
  const [subjectVal, setSubjectVal] = useState({ name: "", id: "" });

  function getSelectedValue(name, id) {
    setStreamVal({ name, id });
    setYearGradeVal({ name: "", id: "" });
    setSemGradeVal({ name: "", id: "" });
    setSubjectVal({ name: "", id: "" });
  }
  function gradeChangeYear(name, id) {
    setYearGradeVal({ name, id });
    setSemGradeVal({ name: "", id: "" });
    setSubjectVal({ name: "", id: "" });
  }
  function gradeChangeSem(name, id) {
    setSemGradeVal({ name, id });
    setYearGradeVal({ name: "", id: "" });
    setSubjectVal({ name: "", id: "" });
  }
  function getSubName(name, id) {
    setSubjectVal({ name, id });
  }
  const subSubCategorydata = [
    {
      sub_category_key_name: "BCA",
      id: 1,
    },
    {
      sub_category_key_name: "BBM",
      id: 2,
    },
    {
      sub_category_key_name: "BSW",
      id: 3,
    },
    {
      sub_category_key_name: "BBS",
      id: 4,
    },
    {
      sub_category_key_name: "MBS",
      id: 5,
    },
  ];

  // subject Name filter
  // Filter Subject name
  const faculTySub = subjectNameData.filter(
    (e) => e.stream_name === streamVal.id
  );

  // filter in sem or year
  const SYFilter = faculTySub.filter((e) =>
    e.sem_type > 0
      ? e.sem_type === semGradeVal.id
      : e.year_type === yearGradeVal.id
  );

  // for not download function
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  // filtering Notes with stream, year/sem and subject names
  const streamFilter =
    streamVal.id > 0
      ? noteData.filter((e) => e.subject_stream_type === streamVal.id)
      : noteData;

  // filtering data with sem/year
  const SYFilterData =
    yearGradeVal.id > 0 || semGradeVal.id > 0
      ? streamFilter.filter((e) =>
          e.year_type > 0
            ? e.year_type === yearGradeVal.id
            : e.sem_type === semGradeVal.id
        )
      : streamFilter;

  // filtering with subject name
  const SubjectFilter =
    subjectVal.id > 0
      ? SYFilterData.filter((e) => e.subject === subjectVal.id)
      : SYFilterData;

  // search data
  const searchData = noteData.filter(
    (e) =>
      e.name.toLowerCase().includes(searchedValue.toLocaleLowerCase()) ||
      e.subject_name.toLowerCase().includes(searchedValue.toLocaleLowerCase())
  );

  return (
    <>
      <div className="h-[45px] bg-[#EBEBEB] shadowCSS">
        <div
          className="h-full flex justify-around items-center max-w-[1400px] mx-auto"
          id="NoteFilterDropdown"
        >
          <Link
            onClick={() => window.location.reload()}
            to="/notes"
            className="text-base tracking-wide text-black hidden lg:block"
          >
            [Note*: Please select faculty, year and subject]
          </Link>

          <div className="filter flex md:flex-row md:gap-3 gap-1 lg:!gap-[10px] items-center hover:bg-[#EBEBEB]">
            <div className="!bg-[#EBEBEB] hover:!bg-[#EBEBEB] md:block relative dropdownbtn md:w-[200px] !h-[20px] ">
              <button
                className="btn btn-secondary !bg-[#dfdddd] !text-[#5c5c5c] shadow-inner hover:!bg-[#e7e7e7] dropdownbtn  !h-[20px] !border-none"
                id="dropdownMenuButton1"
                ref={dropdownRef}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {streamVal.name === "" ? "Faculty" : streamVal.name}
                <MdKeyboardArrowDown className="absolute right-1 text-base top-[10px]" />
              </button>
              <ul
                className="dropdown-menu text-sm"
                aria-labelledby="dropdownMenuButton1"
              >
                {subSubCategorydata.map((e) => {
                  return (
                    <li
                      className="category"
                      value={e.id}
                      name="None"
                      key={e.id}
                      onClick={() =>
                        getSelectedValue(e.sub_category_key_name, e.id)
                      }
                    >
                      {e.sub_category_key_name}
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="md:block">
              <div className=" relative dropdownbtn !bg-[#EBEBEB] hover:!bg-[#EBEBEB]  !w-[200px] !h-[20px]">
                {streamVal.name.slice(0, 3).toLowerCase() === "bca" ||
                streamVal.name.slice(0, 3).toLowerCase() === "bbm" ||
                streamVal.name.slice(0, 3).toLowerCase() === "mbs" ? (
                  <>
                    <button
                      className="btn btn-secondary !bg-[#dfdddd] hover:!bg-[#EBEBEB] !text-[#5c5c5c] shadow-inner dropdownbtn galleryMidalDropdown !h-[20px] !border-none"
                      id="dropdownMenuButton1"
                      ref={dropdownRef}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {semGradeVal.name === "" ? "Semester" : semGradeVal.name}
                      <MdKeyboardArrowDown className="absolute right-1 text-base top-[10px]" />
                    </button>
                    <ul
                      className="dropdown-menu text-sm"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className="category"
                        value={1}
                        name="None"
                        onClick={() => gradeChangeSem("1st Semester ", 1)}
                      >
                        1st Semester
                      </li>
                      <li
                        className="category"
                        value={2}
                        name="None"
                        onClick={() => gradeChangeSem("2nd Semester", 2)}
                      >
                        2nd Semester
                      </li>
                      <li
                        className="category"
                        value={3}
                        name="None"
                        onClick={() => gradeChangeSem("3rd Semester", 3)}
                      >
                        3rd Semester
                      </li>
                      <li
                        className="category"
                        value={4}
                        name="None"
                        onClick={() => gradeChangeSem("4th Semester", 4)}
                      >
                        4th Semester
                      </li>
                      {streamVal.name.slice(0, 3).toLowerCase() !== "mbs" ? (
                        <>
                          <li
                            className="category"
                            value={5}
                            name="None"
                            onClick={() => gradeChangeSem("5th Semester", 5)}
                          >
                            5th Semester
                          </li>
                          <li
                            className="category"
                            value={6}
                            name="None"
                            onClick={() => gradeChangeSem("6th Semester", 6)}
                          >
                            6th Semester
                          </li>
                          <li
                            className="category"
                            value={7}
                            name="None"
                            onClick={() => gradeChangeSem("7th Semester", 7)}
                          >
                            7th Semester
                          </li>
                          <li
                            className="category"
                            value={8}
                            name="None"
                            onClick={() => gradeChangeSem("8th Semester", 8)}
                          >
                            8th Semester
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-secondary shadow-inner dropdownbtn galleryMidalDropdown !bg-[#dfdddd] !text-[#5c5c5c] !h-[20px] !border-none"
                      id="dropdownMenuButton1"
                      ref={dropdownRef}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {yearGradeVal.name === "" ? "Year" : yearGradeVal.name}
                      <MdKeyboardArrowDown className="absolute right-1 text-base top-[10px]" />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        className="category"
                        value={1}
                        name="None"
                        onClick={() => gradeChangeYear("1st Year ", 1)}
                      >
                        1st Year
                      </li>
                      <li
                        className="category"
                        value={2}
                        name="None"
                        onClick={() => gradeChangeYear("2nd Year", 2)}
                      >
                        2nd Year
                      </li>
                      <li
                        className="category"
                        value={3}
                        name="None"
                        onClick={() => gradeChangeYear("3rd Year", 3)}
                      >
                        3rd Year
                      </li>
                      <li
                        className="category"
                        value={4}
                        name="None"
                        onClick={() => gradeChangeYear("4th Year", 4)}
                      >
                        4th Year
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>

            <div className="!bg-[#EBEBEB] hover:!bg-[#EBEBEB] mr-[15px] md:block relative dropdownbtn !w-[200px] !h-[20px]">
              <button
                className="btn btn-secondary !bg-[#dfdddd] !text-[#5c5c5c] shadow-inner hover:!bg-[#e7e7e7] dropdownbtn  !h-[20px] !border-none"
                id="dropdownMenuButton1"
                ref={dropdownRef}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {subjectVal.name === ""
                  ? "Subject"
                  : subjectVal.name.length > 12
                  ? subjectVal.name.slice(0, 12) + "..."
                  : subjectVal.name}
                <MdKeyboardArrowDown className="absolute right-1 text-base top-[10px]" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {SYFilter.map((e) => {
                  return (
                    <li
                      className="category text-sm"
                      value={e.id}
                      name="None"
                      key={e.id}
                      onClick={() => getSubName(e.subject_name, e.id)}
                    >
                      {e.subject_name.length > 15
                        ? e.subject_name.slice(0, 15) + "..."
                        : e.subject_name}
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* search field  */}
            <div className="relative hidden md:block">
              <input
                type="text"
                name="search"
                id="search"
                value={searchedValue}
                onChange={(e) => setSearchedValue(e.target.value)}
                placeholder="Search..."
                className="h-[33px] md:w-[200px] !bg-[#dfdddd] lg:w-[300px] w-[270px] pr-2 rounded-sm !pl-[40px] outline-none shadow-inner"
              />
              <BiSearch className="absolute left-2 text-base top-[8px] cursor-pointer hover:text-black text-[#5c5c5c]" />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-10 mt-3 w-[100%] !pb-10">
        <div className="w-full lg:w-[75%] m-auto relative my-3">
          <div className="ml-[3%] mb-2 py-2">
            <div className="tracking-wide font-semibold !font-sans !text-[#5c5c5c]">
              Faculty Name: {streamVal.name.length > 1 ? streamVal.name : "-"}
            </div>
            <div className="tracking-wide font-semibold !font-sans !text-[#5c5c5c]">
              Subject: {subjectVal.name.length > 1 ? subjectVal.name : "-"}
            </div>
            <div className="tracking-wide font-semibold !font-sans !text-[#5c5c5c]">
              Year/Semester:
              {semGradeVal.name !== "" || yearGradeVal.name !== "" ? (
                <>
                  {semGradeVal.name === ""
                    ? yearGradeVal.name
                    : semGradeVal.name}
                </>
              ) : (
                " -"
              )}
            </div>
          </div>
          {semGradeVal.name !== "" ||
          yearGradeVal.name !== "" ||
          streamVal.name !== "" ||
          searchedValue !== "" ? (
            <>
              {searchedValue.length < 2 ? (
                <>
                  {SubjectFilter.length >= 1 ? (
                    <>
                      <div>
                        {SubjectFilter.map((data) => {
                          return (
                            <div
                              className="border-b pt-[13px] pb-[5px] border-gray-400 flex justify-around items-center px-3 md:px-0"
                              key={data.id}
                            >
                              <div className="w-[80%] capitalize flex items-center gap-1 lg:text-base text-sm">
                                <BsFileEarmarkPdfFill className="!text-[#5c5c5c]" />
                                {data.name.length > 30
                                  ? data.name.slice(0, 30) + "..."
                                  : data.name}
                              </div>
                              <div className="w-[70px] text-center flex items-center justify-center">
                                <a
                                  href={data.notes}
                                  target="__blank"
                                  download
                                  className="w-1/2"
                                >
                                  <AiFillEye className="text-[#5c5c5c] text-base lg:text-xl hover:text-black scale-105 cursor-pointer text-center m-auto" />
                                </a>
                                <ImDownload2
                                  className="text-base lg:text-xl text-[#5c5c5c] hover:text-black cursor-pointer text-center m-auto w-1/2"
                                  onClick={() => {
                                    handleDownload(
                                      data.notes,
                                      `${data.name}.pdf`
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className="min-h-[250px] text-[#5c5c5c] text-lg lg:text-2xl font-semibold flex items-center justify-center">
                      Sorry Note Not Found!!!
                    </div>
                  )}
                </>
              ) : (
                <>
                  {searchData.length >= 1 ? (
                    <>
                      <div>
                        {searchData.map((data) => {
                          return (
                            <div
                              className="border-b pt-[13px] pb-[5px] border-gray-400 flex justify-around items-center"
                              key={data.id}
                            >
                              <div className="w-[80%] capitalize flex items-center gap-1 text-base">
                                <BsFileEarmarkPdfFill className="!text-[#5c5c5c]" />
                                {data.name.length > 30
                                  ? data.name.slice(0, 30) + "..."
                                  : data.name}
                              </div>
                              <div className="w-[70px] text-center flex items-center justify-center">
                                <a
                                  href={data.notes}
                                  target="__blank"
                                  download
                                  className="w-1/2"
                                >
                                  <AiFillEye className="text-[#5c5c5c] text-base hover:text-black scale-105 cursor-pointer text-center m-auto" />
                                </a>
                                <ImDownload2
                                  className="text-base text-[#5c5c5c] hover:text-black cursor-pointer text-center m-auto w-1/2"
                                  onClick={() => {
                                    handleDownload(
                                      data.notes,
                                      `${data.name}.pdf`
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className="min-h-[250px] text-[#5c5c5c] text-lg lg:text-2xl font-semibold flex items-center justify-center">
                      Sorry Note Not Found!!!
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="min-h-[250px] text-lg lg:text-2xl text-[#5c5c5c] font-semibold flex items-center justify-center text-center md:text-start">
              [Note*: Please select Faculty, Year and Subject]
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NoteCatds;
