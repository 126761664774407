import { Button, TextField } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { ImCross } from "react-icons/im";
import BannerContext from "../../../context/banner/BannerContext";

const BannerModal = (props) => {
  const { AddBtnCk, setAddBtnClk, editableData, btnData } = props;
  const { postBanner, updateBannerData } = useContext(BannerContext);

  // for show or set data
  const [data, setData] = useState({
    name: btnData.name === "updateBtn" ? editableData[0].name : "",
    title: btnData.name === "updateBtn" ? editableData[0].title : "",
    slogan: btnData.name === "updateBtn" ? editableData[0].slogan : "",
  });
  const [banner_image, setBanner_image] = useState(null);
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });
  const [imgErrorMsg, setimgErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const bannerDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // for Modal show or hide
  const ref = useRef();
  useEffect(() => {
    if (AddBtnCk) {
      ref.current.click();
    } // eslint-disable-next-line
  }, []);

  const bannerImageChange = (e) => {
    setBanner_image(e.target.files[0]);
    let a = e.target.files[0].name.split(".").pop().toLowerCase();
    if (a !== "png" && a !== "jpg" && a !== "jpeg") {
      setimgErrorMsg({
        status: true,
        msg: "Image extension can only be in png and jpg",
        position: "img",
      });
    } else {
      setimgErrorMsg({ status: false, msg: "", position: "" });
    }
  };

  // add btn click
  const AddBtnClk = (e) => {
    e.preventDefault();

    if (data.name.length <= 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Banner Name",
        position: "name",
      });
    } else if (data.title.length <= 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Banner Title",
        position: "title",
      });
    } else if (data.slogan.length <= 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Banner Slogan",
        position: "slogan",
      });
    } else if (banner_image === null || typeof banner_image !== "object") {
      setErrorMsg({
        status: true,
        msg: "Please Select Image",
        position: "Image",
      });
    } else {
      setErrorMsg({ status: false, msg: "", position: "" });
      if (imgErrorMsg.status === false) {
        if (btnData.name === "addBtn") {
          postBanner(data, banner_image);
        } else {
          updateBannerData(data, banner_image, btnData.id);
        }
        ref.current.click();
        setAddBtnClk(false);
      }
    }
  };

  return (
    <div>
      <button
        type="button"
        ref={ref}
        className="btn d-none btn-primary text-black"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>
      <div
        className="modal fade modal-lg mt-20 "
        id="exampleModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex items-center justify-center">
          <form className="modal-content w-[650px]">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[30px] absolute top-5 text-xl tracking-wider font-semibold"
                id="exampleModalLabel"
              >
                Create Banner
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setAddBtnClk(false)}
              />
            </div>
            <div className="modal-body flex justify-center items-center flex-col gap-3 text-black">
              <TextField
                id="outlined-basic"
                className="w-[90%] m-auto"
                value={data.name}
                onChange={bannerDataChange}
                name="name"
                label="Organization Name*"
                variant="outlined"
              />
              {errorMsg.position === "name" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                className="w-[90%] m-auto"
                value={data.title}
                onChange={bannerDataChange}
                name="title"
                label="Title*"
                variant="outlined"
              />
              {errorMsg.position === "title" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                className="w-[90%] m-auto"
                value={data.slogan}
                onChange={bannerDataChange}
                name="slogan"
                label="Slogan*"
                variant="outlined"
              />
              {errorMsg.position === "slogan" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <input
                className="form-control form-control-lg text w-[90%] rounded-1 shadow-sm"
                name="banner_image"
                onChange={bannerImageChange}
                id="formFileLg"
                type="file"
                style={{ fontSize: "16px", paddingTop: "10px" }}
              />
              {errorMsg.position === "Image" ? (
                <p className="text-red-500 w-[96%] text-xs ml-16 -mt-4">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {imgErrorMsg.position === "img" ? (
                <p className="text-red-500 text-xs w-[95%] ml-8 -mt-4">
                  **{imgErrorMsg.msg}**
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer pr-[50px]">
              <Button
                variant="contained"
                type="submit"
                onClick={AddBtnClk}
                size="small"
                className="mb-2 mt-1 !capitalize"
              >
                {btnData.name === "updateBtn" ? "Update" : "Create"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BannerModal;
