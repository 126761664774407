import { Button, TextField } from "@mui/material";
// import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { ImCross } from "react-icons/im";
import ClubsContext from "../../../context/Clubs/ClubsContext";
import { CKEditor } from "ckeditor4-react";

const Clubs = (props) => {
  const { modalOpen, setModalOpen, updateData, btnNameID } = props;
  const { btnName, id } = btnNameID;
  const { postClubs, UpdateClubs } = useContext(ClubsContext);
  const [inputfield, setInputfield] = useState({
    clubs_name: btnName === "editBtn" ? updateData[0].title : "",
    clubs_post: btnName === "editBtn" ? updateData[0].post : "",
    display_order: "",
    active: true,
  });
  // const [checkedBox, setCheckedBox] = useState(true);
  // const [num, setNum] = useState(
  //   btnName === "editBtn" ? updateData[0].display_order : ""
  // );
  const [disData, setDisData] = useState(
    btnName === "editBtn" ? updateData[0].post : ""
  );
  const [image, setImage] = useState("");
  const [imgErrorMsg, setimgErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const teacherImageChange = (e) => {
    setImage(e.target.files[0]);
    let a = e.target.files[0].name.split(".").pop().toLowerCase();
    if (a !== "png" && a !== "jpg" && a !== "jpeg") {
      setimgErrorMsg({
        status: true,
        msg: "Image extension can only be in png and jpg",
        position: "img",
      });
    } else {
      setimgErrorMsg({ status: false, msg: "", position: "" });
    }
  };
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const inputfldChange = (e) => {
    setInputfield({ ...inputfield, [e.target.name]: e.target.value });
  };
  const ref = useRef();
  useEffect(() => {
    if (modalOpen) {
      ref.current.click();
    } // eslint-disable-next-line
  }, []);

  const addBtnClk = (e) => {
    e.preventDefault();
    if (inputfield.clubs_name < 5) {
      setErrorMsg({
        status: true,
        msg: "clubs Name Must Be Greater Than 5.",
        position: "name",
      });
    } else if (disData < 5) {
      setErrorMsg({
        status: true,
        msg: "clubs Discription Must Be Greater Than 5.",
        position: "dis",
      });
    }
    else {
      setErrorMsg({ status: false, msg: "", position: "" });
      const finaldata = {
        ...inputfield,
        // active: checkedBox,
        clubs_post: disData,
      };
      if (btnName === "editBtn") {
        UpdateClubs(finaldata, image, id);
      } else {
        postClubs(finaldata, image);
      }
      setModalOpen(false);
      ref.current.click();
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary d-none"
        ref={ref}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      ></button>
      <div
        className="modal fade"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <form className="modal-content m-auto clubs">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[75px] absolute top-4 text-lg text-black font-semibold tracking-wider"
                id="exampleModalLabel"
              >
                Create clubs
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setModalOpen(false)}
              />
            </div>
            <div className="modal-body flex justify-center items-center flex-col gap-2 clubsModal">
              <TextField
                id="outlined-basic"
                className="w-[80%] m-auto"
                name="clubs_name"
                value={inputfield.clubs_name}
                onChange={inputfldChange}
                label="clubs Name*"
                variant="outlined"
              />
              {errorMsg.position === "name" ? (
                <p className="text-red-500 text-xs ml-6 -mt-2 w-[80%]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <CKEditor
                className="bg-red-500 text-red-500 !w-[80%]"
                initData={btnName === "editBtn" ? updateData[0].post : ""}
                onChange={(event) => {
                  const data = event.editor.getData();
                  setDisData(data);
                }}
              />
              {errorMsg.position === "dis" ? (
                <p className="text-red-500 text-xs ml-6 -mt-2 w-[80%]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <input
                className="form-control form-control-lg text w-[80%] rounded-1 shadow-sm"
                name="image"
                onChange={teacherImageChange}
                id="formFileLg"
                type="file"
                style={{ fontSize: "16px", paddingTop: "10px" }}
              />
              {imgErrorMsg.position === "img" ? (
                <p className="text-red-500 text-xs w-[80%] ml-8 -mt-2">
                  **{imgErrorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              {/* <TextField
                label="Display-Order*"
                name="display_order"
                value={num}
                onChange={handleChange1}
                variant="outlined"
                className="w-[80%] m-auto"
              />
              {errorMsg.position === "dOrder" ? (
                <p className="text-red-500 text-xs ml-6 -mt-2 w-[80%]">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )} */}
              {/* {btnName === "editBtn" ? (
                <>
                  {updateData[0].active ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => ChecKBoxChange(e)}
                        />
                      }
                      label="clubs Active"
                      className="text-gray-500 tracking-wider w-[80%] -mt-2"
                    />
                  ) : (
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => ChecKBoxChange(e)} />}
                      label="clubs Active"
                      className="text-gray-500 tracking-wider w-[80%] -mt-2"
                    />
                  )}
                </>
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(e) => ChecKBoxChange(e)}
                    />
                  }
                  label="clubs Active"
                  className="text-gray-500 tracking-wider w-[80%] -mt-2"
                />
              )} */}
            </div>
            <div className="modal-footer flex mr-[80px]">
              <Button
                type="submit"
                variant="contained"
                className=" mt-1 !capitalize"
                onClick={addBtnClk}
              >
                Create
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Clubs;
