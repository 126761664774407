import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
const AboutSectionCatds = () => {
  const { getblogPublicApi, blogdata, subCategoryPublicApi, subCategorydata } =
    useContext(PublicApiContext);
  const data = blogdata;
  const SubCategoryData = subCategorydata;
  useEffect(() => {
    getblogPublicApi();
    subCategoryPublicApi();
    // eslint-disable-next-line
  }, []);

  const catid = SubCategoryData.filter(
    (e) =>
      e.category_name === "About Us" && e.category_key_name === "Introduction"
  );
  const FinalData = catid[0]
    ? data.filter((e) => e.category_key === catid[0].id)
    : [];
  return (
    <div className="sapDiv">
      {FinalData.length > 1 ? (
        <>
          {FinalData.map((e, index) => {
            return (
              <div
                // data-aos={
                //   (index + 1) % 2 === 0 ? `zoom-in-left` : `zoom-in-right`
                // }
                className="AboutsectionCards upgradedAboutsectionCards shadowCSS lg:w-[80%] xl:w-[65%] m-8 h-[500px] bg-[#ffffff59] lg:px-5 px-3 rounded relative flex items-center justify-center mb-[20px] lg:gap-5 gap-3"
                key={e.id}
              >
                <div className="hidden lg:block">
                  <div className="headMod py-1 inline-block  cursor-pointer font-semibold   tracking-wider text-xl  -left-[23px] top-7 absolute headerHov heading">
                    <h3 className="text-[#0d662c] text-xl bg-white inline-block shadow py-1 px-4 rounded-md">
                      {e.blog_title}
                    </h3>
                  </div>
                </div>
                <div className="lg:hidden block">
                  <div className=" !bg-white z-10 inline-block w-auto absolute -left-3 top-[15px] lg:-left-10  shadowCSS px-4 rounded-md">
                    <h3 className="text-sm md:p-3 p-2 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
                      {e.blog_title}
                    </h3>
                  </div>
                </div>
                <img
                  src={e.image}
                  className="h-[90%] w-[45%] hover:scale-100 lg:hover:scale-[102%] rounded opacity-95 duration-500 object-cover"
                  alt=""
                  loading="lazy"
                />
                <div className="imageContainer h-[80%] mb-4 lg:mt-[100px] lg:w-[50%] w-full left-16 flex justify-start items-center flex-col">
                  <div className="text-justify ">
                    {e.blog_post.length > 520 ? (
                      <p className="text-base text-justify inline !mt-[100px]">
                        <span
                          className="AboutGNCCONTENT"
                          dangerouslySetInnerHTML={{
                            __html: e.blog_post.substring(0, 800) + "...",
                          }}
                        />
                        <Link
                          to={`/blogs/filter/${e.id}`}
                          className="ml-1 underline text-blue-800 text-[12px] lg:text-base"
                        >
                          Learn more
                        </Link>
                      </p>
                    ) : (
                      <p className="text-base text-justify inline !mt-[100px]">
                        <span
                          className="AboutGNCCONTENT"
                          dangerouslySetInnerHTML={{
                            __html: e.blog_post.substring(0, 800),
                          }}
                        />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {FinalData.map((e) => {
            return (
              <div
                data-aos="zoom-in-up"
                className="singlesontent shadowCSS lg:w-[80%] xl:w-[65%] m-10 h-[520px] bg-[#ffffff59] lg:px-5 px-3  rounded relative flex items-center justify-between mb-[10px] lg:mb-[20px]"
                key={e.id}
              >
                <img
                  src={e.image}
                  className="h-[90%] w-[45%] m-auto hover:scale-100 lg:hover:scale-[102%] !mt-[80px] lg:!mt-auto rounded opacity-95 duration-500 object-cover"
                  alt=""
                  loading="lazy"
                />
                <div className="imageContainer h-[90%] w-full lg:w-[48%] left-16 flex items-center justify-start lg:mt-[95px] flex-col">
                  <div className="hidden lg:block">
                    <div className=" inline-block w-auto cursor-pointer font-semibold bg-white shadow tracking-wider text-xl px-5 py-1 -right-[23px] top-7 rounded absolute headerHov heading">
                      <h3 className="text-[#0d662c] text-xl">{e.blog_title}</h3>
                    </div>
                  </div>
                  <div className="lg:hidden block">
                    <div className=" !bg-white z-10 inline-block w-auto absolute -left-3 top-[15px] lg:-left-10  shadowCSS px-4 rounded">
                      <h3 className="text-sm md:p-3 p-2 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
                        {e.blog_title}
                      </h3>
                    </div>
                  </div>
                  <div className=" pt-4 text-justify">
                    {e.blog_post.length > 520 ? (
                      <p className="text-base text-justify inline">
                        <span
                          className="AboutGNCCONTENT"
                          dangerouslySetInnerHTML={{
                            __html: e.blog_post.substring(0, 800) + "...",
                          }}
                        />
                        <Link
                          to={`/blogs/filter/${e.id}`}
                          className="ml-1 underline text-blue-800 text-[12px] lg:text-base"
                        >
                          {/* <Link
                          to={`/about-us/introduction/?id=${e.id}&section=about`}
                          className="ml-1 underline text-blue-800 text-[12px] lg:text-base"
                        > */}
                          Learn more
                        </Link>
                      </p>
                    ) : (
                      <p className="text-base text-justify inline">
                        <span
                          className="AboutGNCCONTENT"
                          dangerouslySetInnerHTML={{
                            __html: e.blog_post.substring(0, 800),
                          }}
                        />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AboutSectionCatds;
