import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import BodyDiv from "./BodyDiv";
import HeadDiv from "./HeadDiv";

const Clubs = () => {
  const location = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  return (
    <div>
      <HeadDiv />
      <BodyDiv />
    </div>
  );
};

export default Clubs;
