import { useState } from "react";
import NotesContext from "./NotesContext";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Domain from "../Domain";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const NotesState = (props) => {
  let formData = new FormData();
  const DomainUrl = Domain();
  const [render, setRender] = useState(false);
  const [noteData, setNoteData] = useState([]);

  // post notes data
  const postNotes = async (data) => {
    formData.append("name", data.title);
    formData.append("notes", data.note);
    formData.append("subject_name", data.subName);
    formData.append("sem_type", data.className);
    formData.append("year_type", data.yearcls);
    formData.append("sub_sub_category", data.category);
    formData.append("subject", data.subject);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(`${DomainUrl}api/v1/notes/notes`, formData, {
        headers,
      });
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Notes successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.name) {
        toast.error(<div className="text-[14px]">{e.response.data.name}</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      } else if (e.response.data.subject) {
        toast.error(
          <div className="text-[14px]">{e.response.data.subject}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  // gets Notes data
  const getNotes = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(`${DomainUrl}api/v1/notes/notes`, {
        headers,
      });
      const data = await response.data;
      setNoteData(data);
    } catch (e) {}
  };

  //for update Notes
  const updateNotes = async (data, id) => {
    formData.append("name", data.title);
    formData.append("file", data.note);
    formData.append("sub_sub_category_id", data.category);
    formData.append("subject_name", data.subName);
    formData.append("sem_type", data.className);
    formData.append("year_type", data.yearcls);
    formData.append("sub_sub_category", data.category);
    formData.append("subject", data.subject);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}api/v1/notes/notes/${id}`,
        formData,
        { headers }
      );
      if (res.status) {
        setRender(!render);
        toast.success(
          <div className="deleteToast text-[15px]">
            Notes successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      toast.error(<div className="text-[14px]">An error occured!</div>, {
        icon: <BiErrorCircle className="text-xl " />,
      });
    }
  };

  //   for delete Notes
  const deleteNotes = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}api/v1/notes/notes/${id}`,
        { headers }
      );
      if (response.status) {
        setRender(!render);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Notes successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      toast.error(<div className="text-[14px]">An error occured!</div>, {
        icon: <BiErrorCircle className="text-xl " />,
      });
    }
  };

  return (
    <NotesContext.Provider
      value={{
        postNotes,
        getNotes,
        deleteNotes,
        updateNotes,
        noteData,
        render,
      }}
    >
      {props.children}
    </NotesContext.Provider>
  );
};

export default NotesState;
