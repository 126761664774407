import React, { useContext, useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import FooterContext from "../../context/footer/FooterContext";
import FooterModal from "../Components/Modals/FooterModal";
import "../Components/css/Login.css";
import { toast } from "react-toastify";
import DeleteAlert from "../common/DeleteAlert";

const Footer = () => {
  const { getFooter, data, renderData, deleteFooter } =
    useContext(FooterContext);
  const [editableData, setEditableData] = useState([]);
  const [btnData, setBtnData] = useState({ id: "", name: "" });
  useEffect(() => {
    getFooter(); // eslint-disable-next-line
  }, [renderData]);

  //To find Add btn is clicked or not
  const [isAddClk, setIsAddClk] = useState(false);

  // for add Modal Button
  const buttonClkFModal = () => {
    if (data.length < 1) {
      setIsAddClk(true);
    } else {
      toast.error("Footer already exist.");
    }
  };

  //for edit Btn
  const editbtnclk = (id) => {
    const eData = data.filter((e) => e.id === id);
    setEditableData(eData);
    setBtnData({ id: id, name: "update" });
    setIsAddClk(!isAddClk);
  };

   // for delete alert logic
   const [open, setOpen] = useState(false);
   const deletebtnclk = (id) => {
     setOpen(true);
     setBtnData({ id });
   };

  return (
    <>
      <div className="h-full overflow-y-auto mt-3 md:ml-[260px] relative">
        <button
          onClick={() => buttonClkFModal()}
          className=" addbutton absolute top-5 right-3 rounded-[5px] border px-3 py-2 flex items-center justify-center"
        >
          Add Footer
          <BsPlusLg className="mt-0 ml-1 text-[11px]" />
        </button>
        <table
          border="1"
          className="border  mt-[70px] border-b-0"
          style={{ borderCollapse: "collapse", width: "99%" }}
        >
          <thead>
            <tr className="border text-center" style={{ height: "40px" }}>
              <th
                className="border p-1 text-sm max-w-[100px]"
                style={{ width: "50px" }}
              >
                S.N
              </th>
              <th
                className="border p-1 text-sm max-w-[100px]"
                style={{ width: "320px" }}
              >
                Name
              </th>
              <th
                className="border p-1 text-sm max-w-[100px]"
                style={{ width: "310px" }}
              >
                Address
              </th>
              <th
                className="border p-1 text-sm max-w-[100px]"
                style={{ width: "300px" }}
              >
                Phone no.
              </th>
              <th
                className="border p-1 text-sm max-w-[100px]"
                style={{ width: "330px" }}
              >
                Mobile no.
              </th>
              <th
                className="border p-1 text-sm max-w-[100px]"
                style={{ width: "300px" }}
              >
                Email
              </th>
              <th
                className="border p-1 text-sm max-w-[100px]"
                style={{ width: "300px" }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((e, index) => {
              return (
                <tr
                  style={{ height: "40px" }}
                  className="border text-center border-b-0"
                  key={e.id}
                >
                  <td className="border overflow-hidden text-sm p-1 max-w-[10px] w-[5%]">
                    {index + 1}
                  </td>
                  <td className="border overflow-hidden text-sm p-1 max-w-[100px] w-[23%]">
                    {e.name}
                  </td>
                  <td className="border overflow-hidden text-sm p-1 max-w-[100px] w-[16%]">
                    {e.address}
                  </td>
                  <td className="border overflow-hidden text-sm p-1 max-w-[100px] w-[15%]">
                    {e.phone_no_1}
                  </td>
                  <td className="border overflow-hidden text-sm p-1 max-w-[100px] w-[15%]">
                    {e.mobile_no}
                  </td>
                  <td className="border overflow-hidden text-sm p-1 max-w-[100px] w-[15%]">
                    {e.email}
                  </td>
                  <td className="flex items-center justify-center h-[40px]">
                    <button
                      className="edit actionIcons "
                      onClick={() => editbtnclk(e.id)}
                    >
                      <MdEdit className="text-sm text-white icons" />
                    </button>
                    <button
                      className="delete actionIcons "
                      onClick={() => deletebtnclk(e.id)}
                    >
                      <MdDelete className="text-sm text-white icons" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btnData.id}
              deleteFun={deleteFooter}
            />
          ) : (
            <></>
          )}
      </div>

      {isAddClk ? (
        <FooterModal
          isAddClk={isAddClk}
          setIsAddClk={setIsAddClk}
          editableData={editableData}
          btnData={btnData}
          setBtnData={setBtnData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Footer;
