import React, { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const CategorySlug = () => {
  const params = useParams();
  const location = useLocation().pathname;

  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const makeUrl = (name) => {
    if (name) {
      return name.replace(/\s+/g, "-").toLowerCase();
    }
  };

  const data = blogdata.filter((e) => {
    return makeUrl(e.category_name) === params.category.toLowerCase();
  });

  return (
    <div className="w-[95%] m-auto lg:overflow-hidden">
      <div className="saperatedDiv sapDiv">
        {data.length > 1 ? (
          <>
            {data.map((e, index) => {
              return (
                <div
                  data-aos={
                    (index + 1) % 2 === 0 ? `zoom-in-left` : `zoom-in-right`
                  }
                  className="AboutsectionCards shadowCSS lg:w-[80%] xl:w-[65%] m-8 h-[500px] bg-[#ffffff59] lg:px-5 px-3 rounded relative flex items-center justify-center mb-[20px] lg:gap-5 gap-3"
                  key={e.id}
                >
                  <div className="hidden lg:block">
                    <div className="headMod py-1 inline-block  cursor-pointer font-semibold   tracking-wider text-xl  -left-[23px] top-7 absolute headerHov heading">
                      <h3 className="text-[#0d662c] text-xl bg-white inline-block shadow py-1 px-4 rounded-md">
                        {e.blog_title}
                      </h3>
                    </div>
                  </div>
                  <div className="lg:hidden block">
                    <div className=" !bg-white z-10 inline-block w-auto absolute -left-3 top-[15px] lg:-left-10  shadowCSS px-4 rounded-md">
                      <h3 className="text-sm md:p-3 p-2 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
                        {e.blog_title}
                      </h3>
                    </div>
                  </div>
                  <img
                    src={e.image}
                    className=" lg:max-h-[90%] !w-full !h-full lg:max-w-[50%] mx-auto lg:min-w-[300px] lg:w-[40%] hover:scale-100 lg:hover:scale-[102%] rounded opacity-95 duration-500 object-contain max-w-[500px] max-h-[600px]"
                    alt=""
                    loading="lazy"
                  />
                  <div className="imageContainer h-[90%] lg:max-w-[48%] left-16 flex justify-start lg:mt-[95px] items-center flex-col">
                    <div className=" lg:pt-4 text-justify">
                      {e.blog_post.length > 520 ? (
                        <p className="text-base text-justify !pb-5 inline">
                          <span
                            className="AboutGNCCONTENT"
                            dangerouslySetInnerHTML={{
                              __html: e.blog_post.substring(0, 800) + "...",
                            }}
                          />
                          <Link
                            to={`/blogs/filter/${e.id}`}
                            className="ml-1 underline text-blue-800 !text-sm lg:!text-base"
                          >
                            Learn more
                          </Link>
                        </p>
                      ) : (
                        <p className="text-base text-justify inline">
                          <span
                            className="AboutGNCCONTENT"
                            dangerouslySetInnerHTML={{
                              __html: e.blog_post.substring(0, 800),
                            }}
                          />
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {data.map((e) => {
              return (
                <div
                  data-aos="zoom-in-up"
                  className="singlesontent z-10 shadowCSS lg:w-[80%] xl:w-[65%] lg:min-w-[50%] m-10 h-[520px] bg-[#ffffff59] lg:px-5 px-3 rounded relative flex items-center justify-between mb-[20px]"
                  key={e.id}
                >
                  <div className="hidden lg:block">
                    <div className=" inline-block w-auto cursor-pointer font-semibold bg-white shadow tracking-wider text-xl px-5 py-1 -right-[23px] top-7 rounded absolute headerHov heading">
                      <h3 className="text-[#0d662c] text-xl">{e.blog_title}</h3>
                    </div>
                  </div>
                  <div className="lg:hidden block">
                    <div className=" !bg-white z-10 inline-block w-auto absolute -left-3 top-[15px] lg:-left-10  shadowCSS px-4 rounded">
                      <h3 className="text-sm md:p-3 p-2 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
                        {e.blog_title}
                      </h3>
                    </div>
                  </div>
                  <img
                    src={e.image}
                    className="lg:max-h-[90%] !w-full !h-full lg:max-w-[50%] mx-auto lg:min-w-[300px] lg:w-[40%] hover:scale-100 lg:hover:scale-[102%] !rounded opacity-95 duration-500 object-contain max-w-[500px] max-h-[600px]"
                    alt=""
                    loading="lazy"
                  />
                  <div className="imageContainer lg:h-[90%] lg:max-w-[48%] float-right left-16 flex justify-start lg:mt-[95px] items-center flex-col">
                    <div className=" pt-4 text-justify">
                      {e.blog_post.length > 510 ? (
                        <p className="text-base text-justify inline">
                          <span
                            className="AboutGNCCONTENT"
                            dangerouslySetInnerHTML={{
                              __html: e.blog_post.substring(0, 800) + "...",
                            }}
                          />
                          <Link
                            to={`/blogs/filter/${e.id}`}
                            className="ml-1 underline text-blue-800 text-xs lg:text-base"
                          >
                            Learn more
                          </Link>
                        </p>
                      ) : (
                        <p className="text-base text-justify inline">
                          <span
                            className="AboutGNCCONTENT"
                            dangerouslySetInnerHTML={{
                              __html: e.blog_post,
                            }}
                          />
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default CategorySlug;
