import { Route, Routes, useLocation } from "react-router-dom";
import "../App.css";
import Cards from "./Components/pages/Cards";
import Dashboard from "./Components/pages/Dashboard";
import Categories from "./Components/pages/Categories";
import Navigation from "./common/Navigation";
import Login from "./Components/pages/Login";
import SubCategory from "./Components/pages/SubCategory";
import Footer from "./common/Footer";
import Banner from "./Components/pages/Banner";
import SubSubCategory from "./Components/pages/SubSubCategory";
import Theachers from "./Components/pages/Teachers";
import PreRegistration from "./Components/pages/preregistration";
import Posts from "./Components/pages/Posts";
import Adds from "./Components/pages/Adds";
import Notice from "./Components/pages/Notice";
import Gallery from "./Components/pages/Gallery";
import Videos from "./Components/pages/Videos";
import Notes from "./Components/pages/Notes";
import Contact from "./Components/pages/Contact";
import SubjectName from "./Components/pages/SubjectName";
import Clubs from "./Components/pages/Clubs";

const Index = () => {
  const Location = useLocation().pathname;
  return (
    <>
      {Location.includes("/admin") ? (
        <>
          {Location.includes("/admin/login") ? "" : <Navigation />}
          <Routes>
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/categories" element={<Categories />} />
            <Route path="/admin/sub-category" element={<SubCategory />} />
            <Route path="/admin/subsubcategory" element={<SubSubCategory />} />
            <Route path="/admin/footer" element={<Footer />} />
            <Route path="/admin/banner" element={<Banner />} />
            <Route path="/admin/teachers" element={<Theachers />} />
            <Route path="/admin/cards" element={<Cards />} />
            <Route path="/admin/preregistration" element={<PreRegistration />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/blogs" element={<Posts />} />
            <Route path="/admin/advertisement" element={<Adds />} />
            <Route path="/admin/notice" element={<Notice />} />
            <Route path="/admin/gallery" element={<Gallery />} />
            <Route path="/admin/videos" element={<Videos />} />
            <Route path="/admin/notes" element={<Notes />} />
            <Route path="/admin/clubs" element={<Clubs />} />
            <Route path="/admin/subject-name" element={<SubjectName />} />
            <Route path="/admin/contact" element={<Contact />} />
          </Routes>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Index;
