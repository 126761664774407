import { Button, TextField } from "@mui/material";
import React, { useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import CategoryContext from "../../../context/category/CategoryContext";

const SubsubCategoryModal = (props) => {
  const { setModalOpen, SubCategoryData, btnclktype, subSubCategory } = props;
  const { name, id } = btnclktype;
  const { postSubSubCategories, updateSubSubCategories } =
    useContext(CategoryContext);
  const EditData = subSubCategory.filter((e) => e.id === id);

  const [PostData, setPostData] = useState({
    name: name === "update" ? EditData[0].sub_category_key_name : "",
    category: name === "update" ? EditData[0].category : "",
    display_order: name === "update" ? EditData[0].display_order : "",
    active: true,
  });
  const [num, setNum] = useState(
    name === "update" ? EditData[0].display_order : ""
  );
  const ModalBtnRef = useRef();
  const [categoryId, setCategoryId] = useState(
    EditData[0] ? EditData[0].id : ""
  );
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const click = () => {
    ModalBtnRef.current.click();
  };
  useEffect(() => {
    click();
  }, []);
  useEffect((e) => {}, [ModalBtnRef]);

  const inputFldChange = (e) => {
    setPostData({ ...PostData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (categoryId === "") {
      if (subSubCategory[0]) {
        const categoryIdMaked = subSubCategory[0].id;
        setPostData({ ...PostData, category: categoryIdMaked });
        setPostData({ ...PostData, display_order: num });
      }
    } else {
      setPostData({ ...PostData, category: categoryId });
      setPostData({ ...PostData, display_order: num });
    }
    // eslint-disable-next-line
  }, [num]);

  const AddBtnClk = (e) => {
    e.preventDefault();
    if (categoryId === "" || categoryId === "SELECT") {
      setErrorMsg({
        status: true,
        msg: "Please select Sub-Category",
        position: "category",
      });
    } else if (PostData.name.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Subsub-Category Name",
        position: "name",
      });
    } else {
      setErrorMsg({ status: false, msg: "", position: "" });
      if (name !== "update") {
        const postFinalData = {
          ...PostData,
          sub_category_key_name: PostData.name,
          category_key_id: PostData.category,
          sub_category_key: PostData.category,
        };
        postSubSubCategories(postFinalData);
      } else {
        const UpdateFinaldata = {
          ...PostData,
          display_order: num,
          category_key_id: PostData.category,
          sub_category_key: PostData.category,
          sub_category_key_name: PostData.name,
        };
        updateSubSubCategories(UpdateFinaldata, EditData[0].id);
      }
      setPostData({ name: "", category: "", display_order: "", active: true });
      setNum("");
      setModalOpen(false);
      ModalBtnRef.current.click();
    }
  };
  const [dajsn, setdajsn] = useState(false);
  window.addEventListener("click", () => {
    setdajsn(true);
  });

  // eslint-disable-next-line
  const topfilterData = SubCategoryData.filter((e) => {
    if (EditData[0]) {
      return e.id === EditData[0].category_key_id;
    }
  });
  // eslint-disable-next-line
  const FilterData = SubCategoryData.filter((e) => {
    if (EditData[0]) {
      return e.category_key_name !== topfilterData[0].category_key_name;
    }
  });

  const [categoryState, setCategoryState] = useState(
    EditData[0]
      ? { name: EditData[0].sub_category_name, id: EditData[0].category_key_id }
      : { name: "", id: "" }
  );
  const dropdownClkcat = (name, id) => {
    setCategoryState({ name, id });
    setCategoryId(id);
    setPostData({ ...PostData, category: id });
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary hidden"
        data-bs-toggle="modal"
        data-keyboard={dajsn ? `false` : "true"}
        data-bs-target="#exampleModal"
        ref={ModalBtnRef}
      ></button>
      <div
        className="modal fade text-black mt-[100px]"
        id="exampleModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <form className="modal-content">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[45px] absolute top-5 text-lg font-semibold text-black"
                id="exampleModalLabel"
              >
                Create Sub-Sub-Category
              </h5>
              <ImCross
                className="text-gray-500 mr-5 absolute right-0 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setModalOpen(false)}
              />
            </div>
            <div className="modal-body flex items-center justify-center m-auto !p-0 !mx-[32px] !mb-[10px] !mt-[20px] !w-[85%]">
              <div className="dropdown dropdownbtn galleryDropdown ">
                <div
                  className="btn btn-secondary dropdownbtn relative galleryMidalDropdown !pt-[10px]"
                  id="dropdownMenuButton1"
                  // ref={btnRef}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {categoryState.name === ""
                    ? "--Select Stream--"
                    : categoryState.name}
                  <AiOutlineCaretDown className="absolute right-3 top-5" />
                </div>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {SubCategoryData.map((e) => {
                    return (
                      <li
                        className="category"
                        value={e.id}
                        name="None"
                        key={e.id}
                        onClick={() =>
                          dropdownClkcat(e.category_key_name, e.id)
                        }
                      >
                        {e.category_key_name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {errorMsg.position === "category" ? (
              <p className="text-red-500 text-xs ml-16 -mt-3">
                **{errorMsg.msg}**
              </p>
            ) : (
              ""
            )}
            <div className="modal-body flex items-center justify-center w-full">
              <TextField
                id="outlined-basic"
                className="w-[80%]"
                label="Subsub-category"
                variant="outlined"
                name="name"
                value={PostData.name}
                onChange={inputFldChange}
              />
            </div>
            {errorMsg.position === "name" ? (
              <p className="text-red-500 text-xs ml-16 -mt-4">
                **{errorMsg.msg}**
              </p>
            ) : (
              ""
            )}
            <div className="modal-footer pr-14">
              <Button
                type="submit"
                variant="contained"
                onClick={AddBtnClk}
                className="!capitalize"
              >
                {name === "update" ? "Update" : "Create"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubsubCategoryModal;
