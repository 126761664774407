import React, { useContext, useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { MdEdit, MdDelete } from "react-icons/md";
import CategoryContext from "../../../context/category/CategoryContext";
import SubCategoryModal from "../Modals/SubCategoryModal";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const SubCategory = () => {
  const {
    getSubCategories,
    SubCategoryData,
    deleteSubCategory,
    getCategories,
    data,
    rendersubCategoryData,
  } = useContext(CategoryContext);
  const [btnclktype, setbtnclktype] = useState({ name: "", id: "" });
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getSubCategories(); // eslint-disable-next-line
  }, [rendersubCategoryData]);

  useEffect(() => {
    getCategories(); // eslint-disable-next-line
  }, [rendersubCategoryData]);

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteBtnClk = (id, name) => {
    setOpen(true);
    setbtnclktype({ id });
  };
  const SubCategoryAddBtnCLk = () => {
    setbtnclktype({ name: "Addbtn", id: -1 });
    setModalOpen(true);
  };
  const updateBtnClk = (name, id) => {
    setbtnclktype({ name: "update", id });
    setModalOpen(true);
  };

  const paginationValue = 10;
  const [pagValue, setPagValue] = useState(1);
  const totalData = SubCategoryData.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = SubCategoryData.filter((e) =>
    e.category_key_name.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );
  return (
    <>
      <div
        className="h-full overflow-y-auto ml-0 md:ml-[265px] mt-10"
        id="category"
      >
        <div id="table" className="w-[95%] pt-[45px] relative m-auto">
          <button
            className=" addbutton absolute top-0 right-0 rounded-[5px] border px-3 py-2 flex items-center justify-center"
            onClick={SubCategoryAddBtnCLk}
          >
            Add Sub-Category
            <BsPlusLg className="mt-1 ml-1 text-[11px]" />
          </button>
          <table
            border="1"
            className="border mt-[10px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr
                className="border text-center"
                style={{ height: "50px", fontSize: "14px" }}
              >
                <th className="border p-1" style={{ width: "300px" }}>
                  S.N
                </th>
                <th className="border p-1" style={{ width: "320px" }}>
                  Category Name
                </th>
                <th className="border p-1" style={{ width: "320px" }}>
                  Sub-category Name
                </th>
                <th className="border p-1" style={{ width: "310px" }}>
                  Active
                </th>
                <th className="border p-1" style={{ width: "10px" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {SubCategoryData.slice(
                    (pagValue - 1) * paginationValue,
                    pagValue * paginationValue
                  ).map((e, index) => {
                    return (
                      <tr
                        style={{ fontSize: "14px", height: "20px" }}
                        className="border text-center border-b-0"
                        key={e.id}
                      >
                        <td className="border px-1 min-w-[100px] w-[5%]">
                          {index + 1 + (pagValue - 1) * paginationValue}
                        </td>
                        <td className="border px-1 min-w-[100px] w-[30%]">
                          {e.category_name}
                        </td>
                        <td className="border px-1 min-w-[100px] w-[30%]">
                          {e.category_key_name}
                        </td>
                        <td className="border px-1 min-w-[130px] w-[20%]">
                          {e.active ? (
                            <AiFillCheckCircle
                              className={`text-green-600  cursor-pointer m-auto text-xl`}
                            />
                          ) : (
                            <AiFillCloseCircle
                              className={`text-red-600  cursor-pointer m-auto text-xl`}
                            />
                          )}
                        </td>
                        <td className="flex items-center justify-center p-1 h-[40px]">
                          <button
                            className="edit actionIcons "
                            onClick={() => updateBtnClk("UpdateBtn", e.id)}
                          >
                            <MdEdit className="text-xl text-white icons" />
                          </button>
                          <button className="delete actionIcons ">
                            <MdDelete
                              className="text-xl text-white icons"
                              onClick={() =>
                                deleteBtnClk(e.id, e.category_key_name)
                              }
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  {searchData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "20px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[100px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[30%]">
                            {e.category_name}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[30%]">
                            {e.category_key_name}
                          </td>
                          <td className="border px-1 min-w-[130px] w-[20%]">
                            {e.active ? (
                              <AiFillCheckCircle
                                className={`text-green-600  cursor-pointer m-auto text-xl`}
                              />
                            ) : (
                              <AiFillCloseCircle
                                className={`text-red-600  cursor-pointer m-auto text-xl`}
                              />
                            )}
                          </td>
                          <td className="flex items-center justify-center p-1 h-[40px]">
                            <button
                              className="edit actionIcons "
                              onClick={() => updateBtnClk("UpdateBtn", e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() =>
                                  deleteBtnClk(e.id, e.category_key_name)
                                }
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btnclktype.id}
              deleteFun={deleteSubCategory}
            />
          ) : (
            <></>
          )}
          {SubCategoryData.length > 4 ? (
            <Pagination
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
        {modalOpen ? (
          <SubCategoryModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            data={data}
            btnclktype={btnclktype}
            SubCategoryData={SubCategoryData}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SubCategory;
