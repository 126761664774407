import React, { useContext, useEffect, useState } from "react";
import AboutGNC from "./AboutGNC";
import Academics from "./Academics";
import GncLife from "./GncLife";
import HeroSection from "./HeroSection";
import Motive from "./Motive";
import Notice from "./Notice";
import Ads from "../../../Common/Ads";
import Slider from "./Slider";
import AOS from "aos";
import "aos/dist/aos.css";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
AOS.init();

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "GNC-Greenfield National College";
  }, []);

  const { getAdvertisementPublicApi, advertisementData } =
    useContext(PublicApiContext);

  useEffect(() => {
    getAdvertisementPublicApi();
    // eslint-disable-next-line
  }, []);

  const len = advertisementData.length;
  const [revAdData, setRevAdData] = useState([]);
  useEffect(() => {
    setRevAdData(advertisementData.reverse());
  }, [advertisementData]);
  const [int, setInt] = useState(0);

  return (
    <>
      {revAdData[0] && int === 0 && (
        <Ads data={revAdData[0]} index={len} setInt={setInt} />
      )}
      {revAdData[1] && int === 1 && (
        <Ads data={revAdData[1]} index={len} setInt={setInt} />
      )}
      {revAdData[2] && int === 2 && (
        <Ads data={revAdData[2]} index={len} setInt={setInt} />
      )}

      <div className="h-full relative scroll-smooth !overflow-x-hidden">
        <HeroSection />
        <AboutGNC />
        <Notice />
      </div>
      <div className="w-full !overflow-x-hidden">
        <Slider />
      </div>
      <div className="overflow-x-hidden">
        <Academics />
        <GncLife />
        <Motive />
      </div>
    </>
  );
};

export default Home;
