import React from "react";
import NoticeContext from "./NoticeContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useState } from "react";
import Domain from "../Domain";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const NoticeState = (props) => {
  const DomainUrl = Domain();
  const [dataNotice, setData] = useState([]);
  const [renderData, setRenderData] = useState(false);
  let formData = new FormData();

  // for category get request
  const getNotice = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(`${DomainUrl}api/v1/notice/notice`, {
        headers,
      });
      const data = await response.data;
      setData(data);
    } catch (e) {}
  };

  // for category post request
  const postNotice = async (data, image) => {
    formData.append("image", image);
    formData.append("notice_name", data.notice_name);
    formData.append("notice_post", data.notice_post);
    formData.append("display_order", data.display_order);
    formData.append("active", data.active);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}api/v1/notice/notice`,
        formData,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Notice successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.designation) {
        toast.error(
          <div className="text-[14px]">{e.response.data.designation[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.mobile_no) {
        toast.error(
          <div className="text-[14px]">{e.response.data.mobile_no[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.qualification) {
        toast.error(
          <div className="text-[14px]">{e.response.data.qualification[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  //   for delete notice
  const deleteNotice = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}api/v1/notice/notice/${id}`,
        { headers }
      );
      if (response.status) {
        toast.warning(
          <div className="deleteToast text-[15px]">
            Notice successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.name) {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else if (e.response.data.display_order) {
        toast.error(
          <div className="text-[14px]">{e.response.data.display_order[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else if (e.response.data.image) {
        toast.error(
          <div className="text-[14px]">{e.response.data.image[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.notice_name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  //for update category
  const UpdateNotice = async (data, image, id) => {
    formData.append("image", image);
    formData.append("notice_name", data.notice_name);
    formData.append("notice_post", data.notice_post);
    formData.append("display_order", data.display_order);
    formData.append("active", data.active);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}api/v1/notice/notice/${id}`,
        formData,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Notice successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.name) {
        toast.error(
          <div className="text-[14px]"> {e.response.data.name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else if (e.response.data.display_order) {
        toast.error(
          <div className="text-[14px]">
            {" "}
            {e.response.data.display_order[0]}
          </div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else if (e.response.data.image) {
        toast.error(
          <div className="text-[14px]"> {e.response.data.image[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]"> {e.response.data.notice_name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  return (
    <NoticeContext.Provider
      value={{
        dataNotice,
        renderData,
        getNotice,
        postNotice,
        deleteNotice,
        UpdateNotice,
      }}
    >
      {props.children}
    </NoticeContext.Provider>
  );
};

export default NoticeState;
