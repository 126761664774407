import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { ImCross } from "react-icons/im";
import { CKEditor } from "ckeditor4-react";
import { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useContext } from "react";
import NewCategoryContext from "../../../context/NewCategory/NewCategoryContext";
import { AiOutlineCaretDown } from "react-icons/ai";
import BlogContext from "../../../context/Blog/BlogContext";
import CategoryContext from "../../../context/category/CategoryContext";

const BlogsModal = (props) => {
  const { openModal, setOpenModal, btndata, editData, setBtnData } = props;
  const ref = useRef();
  useEffect(() => {
    if (openModal) {
      ref.current.click();
    } else {
      setOpenModal(false);
    }
    // eslint-disable-next-line
  }, []);
  const { postblog, Updateblog } = useContext(BlogContext);
  const { newCatdata, getNewCategory } = useContext(NewCategoryContext);
  const {
    SubCategoryData,
    getSubCategories,
    getSubSubCategories,
    subSubCategory,
  } = useContext(CategoryContext);
  useEffect(() => {
    getSubCategories();
    getNewCategory();
    getSubSubCategories();
    // eslint-disable-next-line
  }, []);
  const [disData, setDisData] = useState(
    editData[0] ? editData[0].blog_post : ""
  );
  const btnRef = useRef();
  const [postData, setPostData] = useState({
    title: editData[0] ? editData[0].blog_title : "",
    category: "",
    dis: editData[0] ? editData[0].blog_post : "",
    image: "",
    author_name: editData[0] ? editData[0].author_name : "",
  });
  const [image, setimage] = useState("");

  const [category, setCategory] = useState(
    editData[0]
      ? { notation: editData[0].category, name: editData[0].category_name }
      : { notation: "", name: "" }
  );

  const [subCategory, setSubCategory] = useState(
    editData[0]
      ? { notation: editData[0].category_key, name: editData[0].sub_category }
      : { notation: "", name: "" }
  );
  const [subsubcategory, setsubsubcategory] = useState(
    editData[0]
      ? {
          notation: editData[0].sub_category_key,
          name: editData[0].sub_sub_category,
        }
      : { notation: "", name: "" }
  );

  const [showName, setShowName] = useState("");
  const [errorMsg, setErrorMsg] = useState({
    status: true,
    msg: "",
    position: "",
  });
  const [imgErrorMsg, setimgErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  const categoryCLk = (e) => {
    e.stopPropagation();
    const notation = e.target.value;
    const name = e.target.getAttribute("name");
    setCategory({ notation, name });
    setSubCategory({ notation: "", name: "" });
    setShowName(name);
    btnRef.current.click();
  };
  const subCategoryClk = (e) => {
    e.stopPropagation();
    const notation = e.target.value;
    const name = e.target.getAttribute("name");
    setSubCategory({ notation, name });
    setShowName(name);
    btnRef.current.click();
  };
  const subsubcategoryClk = (e) => {
    e.stopPropagation();
    const notation = e.target.value;
    const name = e.target.getAttribute("name");
    setsubsubcategory({ notation, name });
    setShowName(name);
    btnRef.current.click();
  };

  const DashboardimageChange = (e) => {
    setimage(e.target.files[0]);
    for (let i = 0; i < e.target.files.length; i++) {
      let a = e.target.files[i].name.split(".").pop().toLowerCase();
      if (a !== "png" && a !== "jpg" && a !== "jpeg") {
        setimgErrorMsg({
          status: true,
          msg: "File Extension Must be IMG, JPG and JPEG",
          position: "img",
        });
      } else {
        setimgErrorMsg({ status: false, msg: "", position: "" });
      }
    }
  };

  const valChange = (e) => {
    setPostData({ ...postData, [e.target.name]: e.target.value });
  };

  const btnName = (datab) => {
    if (datab !== undefined) {
      if (datab.sub_category_key !== null) {
        const subcategoryData = subSubCategory.filter(
          (e) => e.id === datab.sub_category_key
        );
        if (subcategoryData[0]) {
          setShowName(subcategoryData[0].sub_category_key_name);
        }
      } else {
        if (datab.category_key !== null) {
          const categorydata = SubCategoryData.filter(
            (e) => e.id === datab.category_key
          );
          if (categorydata[0]) {
            setShowName(categorydata[0].category_key_name);
          }
        } else {
          if (datab.category !== null) {
            // setShowName('Cat')
            const categoryName = newCatdata.filter(
              (e) => e.id === datab.category
            );
            if (categoryName[0]) {
              setShowName(categoryName[0].category_name);
            }
          } else {
            setShowName("Not");
          }
        }
      }
    }
  };
  useEffect(() => {
    if (btndata.name === "update") {
      btnName(editData[0]);
    } // eslint-disable-next-line
  }, []);

  function postBtnClkFun(e) {
    e.preventDefault();

    if (postData.title.length < 1 || postData.title.length > 100) {
      setErrorMsg({
        status: true,
        msg: "Blog Title Must Be greater than 1 and lesser than 100",
        position: "title",
      });
    } else if (
      category.name.length < 1 &&
      subCategory.name.length < 1 &&
      subsubcategory.name.length < 1 &&
      editData[0] === undefined
    ) {
      setErrorMsg({
        status: true,
        msg: "Please Select Category",
        position: "category",
      });
    } else if (disData.length <= 5 && editData[0] === undefined) {
      setErrorMsg({
        status: true,
        msg: "Discription Must Be Greater Than 5 Character",
        position: "dis",
      });
    } else {
      setErrorMsg({ status: false, msg: "", position: "" });
      if (imgErrorMsg.status === false) {
        if (btndata.name === "update") {
          if (
            subsubcategory.notation !== "" &&
            subsubcategory.notation != null
          ) {
            const subcategoryData = subSubCategory.filter(
              (e) => e.id === subsubcategory.notation
            );
            const categorydata = SubCategoryData.filter(
              (e) => e.id === subcategoryData[0].category_key_id
            );
            const FinalData = {
              ...postData,
              dis: disData,
              image: image,
              category: categorydata[0].category,
              category_key: subcategoryData[0].category_key_id,
              sub_category_key: subsubcategory.notation,
            };
            Updateblog(FinalData, btndata.id);
          } else {
            if (subCategory.notation !== "" && subCategory.notation != null) {
              //to find parent category
              const categorydata = SubCategoryData.filter(
                (e) => e.id === subCategory.notation
              );
              const FinalData = {
                ...postData,
                dis: disData,
                image: image,
                category: categorydata[0].category,
                category_key: subCategory.notation,
                sub_category_key: "",
              };
              Updateblog(FinalData, btndata.id);
            } else {
              if (category.notation !== "") {
                const FinalData = {
                  ...postData,
                  dis: disData,
                  image: image,
                  category: category.notation,
                  category_key: "",
                  sub_category_key: "",
                };
                Updateblog(FinalData, btndata.id);
              } else {
              }
            }
          }
          if (errorMsg.status === false) {
            setOpenModal(false);
          }
        } else {
          // check lowest first
          if (subsubcategory.notation !== "") {
            const subcategoryData = subSubCategory.filter(
              (e) => e.id === subsubcategory.notation
            );
            const categorydata = SubCategoryData.filter(
              (e) => e.id === subcategoryData[0].category_key_id
            );
            const FinalData = {
              ...postData,
              dis: disData,
              image: image,
              category: categorydata[0].category,
              category_key: categorydata[0].id,
              sub_category_key: subsubcategory.notation,
            };
            postblog(FinalData);
          } else {
            if (subCategory.notation !== "") {
              //to find parent category
              const categorydata = SubCategoryData.filter(
                (e) => e.id === subCategory.notation
              );
              const FinalData = {
                ...postData,
                dis: disData,
                image: image,
                category: categorydata[0].category,
                category_key: subCategory.notation,
                sub_category_key: "",
              };
              postblog(FinalData);
            } else {
              if (category.notation !== "") {
                const FinalData = {
                  ...postData,
                  dis: disData,
                  image: image,
                  category: category.notation,
                  category_key: "",
                  sub_category_key: "",
                };
                postblog(FinalData);
              } else {
              }
            }
          }
          if (errorMsg.status === false) {
            btnRef.current.click();
            setOpenModal(false);
          }
        }
      }
      ref.current.click();
      setOpenModal(false);
    }
  }

  // for update blogs
  const ud =
    editData[0] &&
    (editData[0].sub_category_key !== null
      ? "SSC"
      : editData[0].category_key !== null
      ? "SC"
      : "C");

  const dropdownData =
    ud === "SSC" ? subSubCategory : ud === "SC" ? SubCategoryData : newCatdata;

  const modalClose = () => {
    setOpenModal(false);
    setBtnData({ name: "", id: "" });
  };

  return (
    <div>
      <button
        type="button"
        ref={ref}
        className="btn btn-primary text-black hidden"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      ></button>
      <div
        className="modal fade modal-lg"
        id="exampleModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex items-center justify-center">
          <div className="modal-content">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title mb-5 absolute top-5 text-xl tracking-wider font-semibold"
                id="exampleModalLabel"
              >
                Create Blog
              </h5>
              <ImCross
                className="text-gray-500 absolute right-5 top-4 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={modalClose}
              />
            </div>
            <form className="App312 mt-3 px-3 flex flex-col gap-2">
              <TextField
                id="outlined-basic"
                className="w-[full]"
                name="title"
                label="Enter Title*"
                variant="outlined"
                value={postData.title}
                onChange={valChange}
              />
              {errorMsg.position === "title" ? (
                <p className="text-red-500 text-xs w-[99%] text-start ml-3 -mt-1 -mb-1">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <TextField
                id="outlined-basic"
                className="w-[full]"
                name="author_name"
                label="Enter Name"
                variant="outlined"
                value={postData.author_name}
                onChange={valChange}
              />
              {/* {<p className="text-red-500 text-xs w-[99%] text-start ml-3 -mt-1 -mb-3">**gaurab**</p>} */}
              <div className="fileandDis flex items-center justify-between relative">
                {btndata.name !== "update" ? (
                  <div className="dropdown dropdownbtn -ml-5">
                    <div
                      className=" btn btn-secondary dropdownbtn w-[50%] relative MyCkeditorDDbtn !pt-[10px]"
                      id="dropdownMenuButton1"
                      ref={btnRef}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ height: "43px !important" }}
                    >
                      {btndata.name === "update"
                        ? showName
                        : showName === ""
                        ? "--Select Category--"
                        : showName}
                      <AiOutlineCaretDown className="absolute right-3 top-5" />
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {newCatdata.map((e) => {
                        return (
                          <li
                            className="category"
                            key={e.id}
                            value={e.id}
                            name={e.category_name}
                            onClick={categoryCLk}
                          >
                            {e.category_name}
                            <ul className="subcategory shadow">
                              {e.category_key.map((e) => {
                                return (
                                  <li
                                    className="bg-[#fff]"
                                    key={e.id}
                                    name={e.category_key_name}
                                    onClick={subCategoryClk}
                                    value={e.id}
                                  >
                                    {e.category_key_name}
                                    <ul className="subsubcategory">
                                      {e.sub_category_key.map((e) => {
                                        return (
                                          <li
                                            className="bg-[#fff]"
                                            key={e.id}
                                            name={e.sub_category_key_name}
                                            onClick={subsubcategoryClk}
                                            value={e.id}
                                          >
                                            {e.sub_category_key_name}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                    {errorMsg.position === "category" ? (
                      <p className="text-red-500 text-xs w-[99%] text-start ml-3 -mt-4 -mb-3">
                        **{errorMsg.msg}**
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="dropdown dropdownbtn -ml-5">
                    <div
                      className=" btn btn-secondary dropdownbtn w-[50%] relative MyCkeditorDDbtn"
                      id="dropdownMenuButton1"
                      ref={btnRef}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{ height: "43px !important" }}
                    >
                      {/* {
                          showName === '' ? '--Select Category--' : showName
                      } */}
                      {btndata.name === "update"
                        ? showName
                        : showName === ""
                        ? "--Select Category--"
                        : showName}
                      <AiOutlineCaretDown className="absolute right-3 top-5" />
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {dropdownData.map((e) => {
                        return (
                          <li
                            className="category"
                            key={e.id}
                            value={e.id}
                            name={
                              ud === "SSC"
                                ? e.sub_category_key_name
                                : ud === "SC"
                                ? e.category_key_name
                                : e.category_name
                            }
                            onClick={
                              ud === "SSC"
                                ? subsubcategoryClk
                                : ud === "SC"
                                ? subCategoryClk
                                : categoryCLk
                            }
                          >
                            {ud === "SSC"
                              ? e.sub_category_key_name
                              : ud === "SC"
                              ? e.category_key_name
                              : e.category_name}
                          </li>
                        );
                      })}
                    </ul>
                    {errorMsg.position === "category" ? (
                      <p className="text-red-500 text-xs w-[99%] text-start ml-3 -mt-4 -mb-3">
                        **{errorMsg.msg}**
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <input
                  className="form-control form-control-lg w-[50%] text rounded-1 shadow-2xl bg-slate-200"
                  name="image"
                  id="formFileLg"
                  type="file"
                  onChange={DashboardimageChange}
                  style={{ fontSize: "16px", paddingTop: "10px" }}
                />
                {errorMsg.msg.length >= 1 && errorMsg.position === "image" ? (
                  <p className="text-red-500 text-xs w-[99%] text-start ml-3 -mt-2 -mb-3 absolute -right-[400px] bottom-3">
                    **{errorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}
                {imgErrorMsg.position === "img" ? (
                  <p className="text-red-500 text-xs w-[99%] text-start ml-3 -mt-2 -mb-3 absolute -right-[400px] bottom-3">
                    **{imgErrorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="mb-[10px]">
                <CKEditor
                  initData={postData.dis}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    setDisData(data);
                  }}
                />
              </div>
              {errorMsg.position === "dis" ? (
                <p className="text-red-500 text-xs w-[99%] text-start ml-3 absolute -right-[400px] bottom-[70px] left-5">
                  **{errorMsg.msg}**
                </p>
              ) : (
                ""
              )}
              <div className="modal-footer pr-[50px]">
                <Button
                  type="submit"
                  variant="contained"
                  className=" mt-2 px-4 w-[150px] float-right !capitalize"
                  onClick={postBtnClkFun}
                >
                  {btndata.name === "update" ? "update" : `Create`}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsModal;
