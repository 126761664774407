import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
const BodyDivCards = () => {
  const { clubsdata, clubsPublicApi, setDataLength } =
    useContext(PublicApiContext);
  useEffect(() => {
    clubsPublicApi();
    // eslint-disable-next-line
  }, []);
  setDataLength(clubsdata.length);
  return (
    <div className=" lg:w-[90%] m-auto contentclue">
      {clubsdata.length > 1 ? (
        <>
          {clubsdata.map((e, index) => {
            return (
              <div
                data-aos={
                  (index + 1) % 2 === 0 ? `zoom-in-left` : `zoom-in-right`
                }
                className="AboutsectionCards cardsClub shadowCSS m-8 lg:w-[50%] lg:h-[300px] bg-[#ffffff59] lg:px-5 px-3 rounded relative flex items-center left-0 md:flex-row-reverse flex-col mb-[20px] lg:gap-5 gap-3"
                key={e.id}
              >
                <div className="hidden lg:block">
                  <div className="py-1 inline-block max-w-[50%] cursor-pointer font-semibold   tracking-wider text-xl  -right-[23px] top-7 absolute headerHov heading">
                    <h3 className="text-[#0d662c] text-xl bg-white inline-block shadow py-1 px-4 rounded-md">
                      {e.title}
                    </h3>
                  </div>
                </div>
                <div className="lg:hidden block">
                  <div className=" !bg-white z-10 inline-block w-auto absolute -left-3 top-[15px] lg:-left-10  shadowCSS px-4 rounded-md">
                    <h3 className="text-sm md:p-3 p-2 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
                      {e.title}
                    </h3>
                  </div>
                </div>
                <img
                  src={e.image}
                  className="h-[90%] w-[45%] hover:scale-100 lg:hover:scale-[102%] rounded opacity-95 duration-500 object-cover"
                  alt=""
                  loading="lazy"
                />
                <div className="imageContainer h-[90%] lg:w-[50%] w-full left-16 flex justify-start lg:mt-[95px] items-center flex-col">
                  <div className=" lg:pt-7 text-justify ">
                    {e.post.length > 520 ? (
                      <p className="text-base text-justify inline !mt-3">
                        <span
                          className="AboutGNCCONTENT"
                          dangerouslySetInnerHTML={{
                            __html: e.post.substring(0, 520) + "...",
                          }}
                        />
                        <Link
                          className="ml-1 underline text-blue-800 text-[12px] lg:text-base"
                          to={`/blogs/filter/${e.id}`}
                        >
                          Learn more
                        </Link>
                      </p>
                    ) : (
                      <p className="text-base text-justify inline !mt-5">
                        <span
                          className="AboutGNCCONTENT"
                          dangerouslySetInnerHTML={{
                            __html: e.post.substring(0, 520),
                          }}
                        />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {clubsdata.map((e) => {
            return (
              <div
                data-aos="zoom-in-up"
                className="singlesontent shadowCSS lg:w-[65%] m-10 h-[520px] bg-[#ffffff59] lg:px-5 px-3  rounded relative flex items-center justify-between mb-[10px] lg:mb-[20px]"
                key={e.id}
              >
                <div className="hidden lg:block">
                  <div className="py-1 inline-block max-w-[50%] cursor-pointer font-semibold   tracking-wider text-xl  -right-[23px] top-7 absolute headerHov heading">
                    <h3 className="text-[#0d662c] text-xl bg-white inline-block shadow py-1 px-4 rounded-md">
                      {e.title}
                    </h3>
                  </div>
                </div>
                <div className="lg:hidden block">
                  <div className=" !bg-white z-10 inline-block w-auto absolute -left-3 top-[15px] lg:-left-10  shadowCSS px-4 rounded-md">
                    <h3 className="text-sm md:p-3 p-2 md:px-3 px-2 md:text-xl text-[#0d662c] font-bold">
                      {e.title}
                    </h3>
                  </div>
                </div>
                <img
                  src={e.image}
                  className="h-[90%] w-[45%] m-auto hover:scale-100 lg:hover:scale-[102%] !mt-[80px] lg:!mt-auto rounded opacity-95 duration-500 object-cover"
                  alt=""
                  loading="lazy"
                />
                <div className="imageContainer h-[90%] w-[48%] left-16 flex items-center justify-start lg:mt-[95px] flex-col">
                  <div className=" pt-4 text-justify ">
                    {e.post.length > 520 ? (
                      <p className="text-base text-justify inline">
                        <span
                          className="AboutGNCCONTENT"
                          dangerouslySetInnerHTML={{
                            __html: e.post.substring(0, 520) + "...",
                          }}
                        />
                        <Link
                          to={`/blogs/filter/${e.id}`}
                          className="ml-1 underline text-blue-800 text-[12px] lg:text-base"
                        >
                          {/* <Link
                          to={`/about-us/introduction/?id=${e.id}&section=about`}
                          className="ml-1 underline text-blue-800 text-[12px] lg:text-base"
                        > */}
                          Learn more
                        </Link>
                      </p>
                    ) : (
                      <p className="text-base text-justify inline">
                        <span
                          className="AboutGNCCONTENT"
                          dangerouslySetInnerHTML={{
                            __html: e.post.substring(0, 520),
                          }}
                        />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default BodyDivCards;
