import axios from "axios";
import React from "react";
import TeacherCOntext from "./TeacherCOntext";
import Domain from "../Domain";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const TeacherState = (props) => {
  let formData = new FormData();

  const DomainUrl = Domain();
  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState(false);
  // for teacher get request
  const getTeachers = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(`${DomainUrl}api/v1/teachers/teachers`, {
        headers,
      });
      const data = await response.data;
      setData(data);
    } catch (e) {}
  };
  // for teacher post request
  const postTeacher = async (data, file) => {
    formData.append("image", file);
    formData.append("name", data.name);
    formData.append("mobile_no", data.mobile_no);
    formData.append("address", data.address);
    formData.append("email", data.email);
    formData.append("designation", data.designation);
    formData.append("qualification", data.qualification);
    formData.append("teams", data.teams);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}api/v1/teachers/teachers`,
        formData,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Teacher successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.designation) {
        toast.error(
          <div className="text-[14px]">{e.response.data.designation[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.mobile_no) {
        toast.error(
          <div className="text-[14px]">{e.response.data.mobile_no[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.qualification) {
        toast.error(
          <div className="text-[14px]">{e.response.data.qualification[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  //   for delete teacher
  const deleteTeacher = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}api/v1/teachers/teachers/${id}`,
        { headers }
      );
      if (response.status) {
        setRenderData(!renderData);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Teacher successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      toast.error(<div className="text-[14px]">An error occured!</div>, {
        icon: <BiErrorCircle className="text-xl " />,
      });
    }
  };

  //for update teacher
  const UpdateTeacher = async (data, file, id) => {
    formData.append("image", file);
    formData.append("name", data.name);
    formData.append("mobile_no", data.mobile_no);
    formData.append("address", data.address);
    formData.append("email", data.email);
    formData.append("designation", data.designation);
    formData.append("qualification", data.qualification);
    formData.append("teams", data.teams);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}api/v1/teachers/teachers/${id}`,
        formData,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Teacher successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.designation) {
        toast.error(
          <div className="text-[14px]">{e.response.data.designation[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.mobile_no) {
        toast.error(
          <div className="text-[14px]">{e.response.data.mobile_no[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      } else if (e.response.data.qualification) {
        toast.error(
          <div className="text-[14px]">{e.response.data.qualification[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  return (
    <>
      <TeacherCOntext.Provider
        value={{
          getTeachers,
          data,
          postTeacher,
          deleteTeacher,
          UpdateTeacher,
          renderData,
        }}
      >
        {props.children}
      </TeacherCOntext.Provider>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default TeacherState;
